import { useEffect, useState } from "react";
import ButtonCustom from "../../../../../../components/ui/ButtonCustom/ButtonCustom";
import { IProject } from "../../../../../../services/Projects";
import content, { onlyEmployee } from "../../../../../../settings/content";
import styles from "./DetailCardProjectsData.module.scss";
import { Skeleton, message } from "antd";
import GalleryCardDetailed from "../../../../../Gallery/GalleryCardDetailed/GalleryCardDetailed";
import GalleryCardItems from "../../../../../Gallery/GalleryCardItems/GalleryCardItems";
import DealCardCreate from "../../../DealCardCreate/DealCardCreate";
import { NoticeType } from "antd/es/message/interface";
import axios from "axios";
import ModalCustom from "../../../../../../components/ModalCustom/ModalCustom";
import { yandexCloudUrl } from "../../../../../../env";
import ValidateComponentByRole from "../../../../../../components/ValidateComponentByRole/ValidateComponentByRole";

interface IProps {
  data: IProject;
  dealStatus: string;
  onCopyProject: ({ isCreating, isCopying }: { isCreating: boolean; isCopying: string }) => void;
}

const DetailCardProjectsData: React.FC<IProps> = ({ data, dealStatus, onCopyProject }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [previewSrc, setPreviewSrc] = useState(`${yandexCloudUrl}${data.id}/small.png`);
  const [isPreview, setIsPreview] = useState<boolean>();
  const [isPanoramaOpened, setIsPanoramaOpened] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [dealId, setDealId] = useState("");
  const [isPanoramaError, setIsPanoramaError] = useState(false);

  const [isReportDisabled, setIsReportDisabled] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(`${yandexCloudUrl}${data.id}/report.pdf`)
        .then((response) => {
          if (response.status === 200) {
            setIsReportDisabled(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setIsReportDisabled(true);
          }
        });
    }, 10000);

    return () => {
      clearInterval(interval); // Очистка интервала при размонтировании компонента
    };
  }, []);

  useEffect(() => {
    axios.get(`${yandexCloudUrl}${data.id}/report.pdf`).catch((err) => {
      if (err.response.status === 404) {
        setIsReportDisabled(true);
      }
      if (err.response.status === 200) {
        setIsReportDisabled(false);
      }
    });
  }, []);

  const projectPreview = new Image();

  useEffect(() => {
    setPreviewSrc(`${yandexCloudUrl}${data.id}/small.png`);
    projectPreview.src = previewSrc;
    projectPreview.onload = function () {
      setIsPreview(true);
    };
    projectPreview.onerror = function () {
      setIsPreview(false);
    };
    const interval = setInterval(() => {
      setPreviewSrc(`${yandexCloudUrl}${data.id}/small.png`);
      projectPreview.src = previewSrc;
      projectPreview.onload = function () {
        setIsPreview(true);
      };
      projectPreview.onerror = function () {
        setIsPreview(false);
      };
    }, 10000);

    return () => clearInterval(interval); // Очистка интервала при размонтировании компонента
  }, []);

  const handleOpenProject = (isViewOnly: boolean) => {
    const link = document.createElement("a");
    link.href = `ceramic3d:?project_id=${data.id}${isViewOnly ? "&viewOnly" : ""}`;
    link.target = "_blank";
    link.click();
  };

  const handleOpenReportPdf = (id: string) => {
    window.open(`${yandexCloudUrl}${id}/report.pdf`, "_blank");
  };

  const handleOpenPanorama = () => setIsPanoramaOpened(true);
  const handleSuccess = (data: string) => setDealId(data);

  useEffect(() => {
    if (!dealId) {
      return;
    }
    if (dealId) {
      alert("success", content.alert_messages.projects.create.success);
      setModalCreate(false);
      setIsPanoramaOpened(false);
    }
  }, [dealId]);

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const handlePanoramaError = (isError: boolean) => setIsPanoramaError(isError);

  return (
    <div className={styles.collapse__box}>
      {contextHolder}
      <>
        <div className={styles.collapse__box_preview}>
          {!isPreview ? (
            <Skeleton.Image className={styles.collapse__box_preview_image} style={{ height: "120px", width: "120px" }} />
          ) : (
            <img src={previewSrc} alt={data.name} className={styles.collapse__box_preview_image} />
          )}
        </div>
        <div className={styles.collapse__box_data}>
          <div className={styles.collapse__box_data_title}>{data.name}</div>
          <div className={styles.collapse__box_data_button_block}>
            <div className={styles.collapse__box_data_button_block_row}>
              <ValidateComponentByRole requiredRoles={onlyEmployee}>
                <ButtonCustom className={styles.collapse__box_data_button_block_custom_button} onClick={() => handleOpenProject(false)}>
                  <span>{content.deals.detail_deal_card.tabs.projects.button_block.edit}</span>
                </ButtonCustom>
              </ValidateComponentByRole>
              <ButtonCustom className={styles.collapse__box_data_button_block_custom_button} onClick={() => handleOpenProject(true)}>
                <span>{content.deals.detail_deal_card.tabs.projects.button_block.show}</span>
              </ButtonCustom>
              <ButtonCustom
                className={styles.collapse__box_data_button_block_custom_button}
                disabled={isReportDisabled}
                onClick={() => handleOpenReportPdf(data.id)}
              >
                <span>{content.deals.detail_deal_card.tabs.projects.button_block.estimate}</span>
              </ButtonCustom>
              <ButtonCustom className={styles.collapse__box_data_button_block_custom_button} onClick={() => handleOpenPanorama()}>
                <span>{content.deals.detail_deal_card.tabs.projects.button_block.panorama}</span>
              </ButtonCustom>
              <ValidateComponentByRole requiredRoles={onlyEmployee}>
                <ButtonCustom
                  className={styles.collapse__box_data_button_block_custom_button}
                  onClick={() => onCopyProject({ isCopying: data.id, isCreating: true })}
                >
                  <span>Копировать проект</span>
                </ButtonCustom>
              </ValidateComponentByRole>
            </div>
          </div>
        </div>
        <ModalCustom
          title={data?.name}
          handleCloseModal={() => {
            setIsPanoramaOpened(false);
          }}
          destroyOnClose={true}
          centered
          width={900}
          isOpen={isPanoramaOpened}
          onCancel={() => {
            setIsPanoramaOpened(false);
          }}
          footer={
            <div className={styles.collapse__modal_block}>
              {/* <ButtonCustom
                key={data.id}
                className={styles.custom_button}
                onClick={() => setModalCreate(true)}
                maxWidth="fit-content"
                bgColor="darkGrey"
                disabled={isPanoramaError}
              >
                <span>{content.gallery.detail_card.create_projec_button_title}</span>
              </ButtonCustom> */}
              <GalleryCardItems key={data.id + "_items"} selectedProjectId={data.id} dealStatus={dealStatus} />
            </div>
          }
        >
          <GalleryCardDetailed selectedProjectId={data.id} isErrorHandler={handlePanoramaError} />
        </ModalCustom>
        {modalCreate && (
          <DealCardCreate
            onCancel={() => setModalCreate(false)}
            handleSuccess={handleSuccess}
            open={modalCreate}
            updateDealsList={() => {}}
          />
        )}
      </>
    </div>
  );
};

export default DetailCardProjectsData;
