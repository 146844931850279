import SearchBar from "../../../components/SearchBar/SearchBar";
import styles from "./OrganizationHeader.module.scss";

export interface IProps {
  title: string;
  placeholder?: string;
  search?: string;
  setSearch?: (e: string) => void;
  isSearch?: boolean;
}

const OrganizationHeader: React.FC<IProps> = ({ title, placeholder, search, setSearch, isSearch = true }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setSearch) {
      setSearch(e.target.value);
    }
  };
  return (
    <div className={styles.shopsHeader}>
      <p>{title}</p>
      {isSearch && (
        <div className={styles.shopsHeader__header_search}>
          <SearchBar placeholder={placeholder} className={styles.shopsHeader__search_input} onChange={handleChange} value={search} />
        </div>
      )}
    </div>
  );
};

export default OrganizationHeader;
