import { useEffect, useState } from "react";
import styles from "./EmailDeleteModal.module.scss";
import {
  IEmailBinding,
  deleteEmailBindingById,
  deleteEmailConnectionById,
  getEmailBinding,
  getEmailConnection,
  getEmailConnections,
} from "../../../../services/Shops";
import { useMutation, useQuery } from "react-query";
import { Checkbox, message } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useShops } from "../../../../context/useShops";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import { NoticeType } from "antd/es/message/interface";

type Props = {
  emailConnectionId: string;
  onCancel: () => void;
  onDeleteSuccess: () => void;
  refetchShops: () => void;
  isCheckboxes?: boolean;
  emailMailingModalId: string;
};

const EmailDeleteModal = ({
  emailConnectionId,
  onCancel,
  refetchShops,
  isCheckboxes = true,
  onDeleteSuccess,
  emailMailingModalId,
}: Props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const { shops } = useShops();
  const [emailBinding, setEmailBinding] = useState<IEmailBinding[]>([]);

  const [selectedIds, setSelectedIds] = useState<CheckboxValueType[]>([]);

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const { data: emailBindingData, refetch } = useQuery({
    queryFn: () => getEmailBinding({ connection_id: emailConnectionId }),
    queryKey: [`shop_email_${emailMailingModalId}${emailConnectionId}`],
    refetchInterval: 10000,
  });

  const { data: emailConnectionData } = useQuery({
    queryFn: () => getEmailConnection(emailConnectionId),
    queryKey: [`email_connection${emailConnectionId}`],
  });

  const { refetch: refetchConnections } = useQuery({
    queryFn: () => getEmailConnections(),
    queryKey: [`email_connections${emailMailingModalId}`],
  });

  const { mutateAsync: onDeleteEmailBindingById } = useMutation({
    mutationFn: deleteEmailBindingById,
  });

  const { mutateAsync: onDeleteEmailConnectionById } = useMutation({
    mutationFn: deleteEmailConnectionById,
  });

  useEffect(() => {
    if (emailBindingData?.data) {
      setEmailBinding(emailBindingData.data);
    }
  }, [emailBindingData]);

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedIds(checkedValues);
  };

  const onSubmit = async () => {
    for (const id of selectedIds) {
      await onDeleteEmailBindingById(id.toString());
    }
    refetch();
    refetchShops();
    onCancel();
  };

  const onDeleteEmail = async () => {
    const bindingDeleteRequests = emailBinding.map(async (item) => {
      const request = await onDeleteEmailBindingById(item.id);
      return request.data;
    });
    await Promise.all(bindingDeleteRequests);

    await onDeleteEmailConnectionById(emailConnectionId).then((response) => {
      if (response.status === 200) {
        refetch();
        refetchConnections();
        refetchShops();
        onDeleteSuccess();
      } else {
        alert("success", response.data.toString());
      }
    });
  };

  return (
    <div className={styles.EmailDeleteModal}>
      {contextHolder}
      {!!emailBinding.length && <span className={styles.EmailDeleteModal_title}>Магазины, которые останутся без рассылки:</span>}
      {!emailBinding.length && !isCheckboxes && (
        <span className={styles.EmailDeleteModal_title}>Вы действительно хотите удалить почту {emailConnectionData?.data.email}?</span>
      )}
      <div className={styles.EmailDeleteModal__content}>
        {isCheckboxes && (
          <Checkbox.Group
            options={emailBinding.map((bind, index) => {
              const currentShop = shops.find((shop) => shop.id === bind.shop_id);
              return {
                value: bind.id,
                label: `${currentShop?.name} ${currentShop?.address}`,
              };
            })}
            onChange={onChange}
            rootClassName={styles.EmailDeleteModal__checkboxes}
          />
        )}
        {!isCheckboxes &&
          emailBinding.map((bind, index) => {
            const currentShop = shops.find((shop) => shop.id === bind.shop_id);

            return (
              <span key={bind.connection_id + index + "delete_title"}>{`${index + 1}. ${currentShop?.name} ${currentShop?.address}`}</span>
            );
          })}
      </div>
      <div className={styles.EmailDeleteModal__footer_btns}>
        <ButtonCustom className={styles.EmailDeleteModal__footer_btn} onClick={onCancel} maxWidth="150px" bgColor="gray">
          <span>Отмена</span>
        </ButtonCustom>
        <ButtonCustom
          className={styles.EmailDeleteModal__footer_btn}
          onClick={isCheckboxes ? onSubmit : onDeleteEmail}
          maxWidth="150px"
          bgColor="blue"
        >
          <span>Подтвердить</span>
        </ButtonCustom>
      </div>
    </div>
  );
};

export default EmailDeleteModal;
