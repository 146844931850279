import { Tabs, TabsProps } from "antd";
import styles from "./GalleryCardDetailed.module.scss";
import { IProjectJson } from "../../../services/Projects";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useMemo, useState } from "react";
import ModalCustom from "../../../components/ModalCustom/ModalCustom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";

interface Props {
  id: string;
  item: IProjectJson;
  qrModal: boolean;
  handleCloseModal: () => void;
  projectId: string;
  organizationId: string;
  isOpenedFromGallery: boolean;
}

const GalleryCode: React.FC<Props> = ({ id, item, handleCloseModal, qrModal, projectId, organizationId, isOpenedFromGallery }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const codeWithItems = useMemo(
    () => `
<iframe
    src="${window.location.origin}/panorama-iframe/${projectId}"
    width="900px"
    height="400px"
    frameBorder="0"
    title="Project-${projectId}"
    allowFullScreen
></iframe>
<div></div>
<iframe
    src="${window.location.origin}/project-items-iframe/${projectId}"
    width="900px"
    height="400px"
    frameBorder="0"
    title="Projectitems-${projectId}"
    allowFullScreen
>
</iframe>
          `,
    [projectId],
  );

  const codeWithoutItems = useMemo(
    () => `
<iframe
    src="${window.location.origin}/panorama-iframe/${projectId}"
    width="900px"
    height="400px"
    frameBorder="0"
    title="Project-${projectId}"
    allowFullScreen
>
</iframe>
          `,
    [projectId],
  );

  const onClose = () => {
    handleCloseModal();
    setIsCopied(false);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Панорама без состава проекта",
      children: (
        <SyntaxHighlighter language="html" style={materialDark} showInlineLineNumbers customStyle={{ fontSize: "10px" }}>
          {codeWithoutItems}
        </SyntaxHighlighter>
      ),
    },
    {
      key: "2",
      label: "Панорама с составом проекта",
      children: (
        <SyntaxHighlighter language="html" style={materialDark} showInlineLineNumbers customStyle={{ fontSize: "10px" }}>
          {codeWithItems}
        </SyntaxHighlighter>
      ),
    },
  ];

  return (
    <ModalCustom
      width={680}
      footer={null}
      onCancel={onClose}
      isOpen={qrModal}
      title={"Код для интеграции на свой сайт"}
      handleCloseModal={onClose}
      centered
      destroyOnClose
    >
      <>
        <Tabs activeKey={activeTab} items={items} onChange={setActiveTab} />
        <div className={styles.gallery_card_detailed__qr_container}>
          <CopyToClipboard text={activeTab === "1" ? codeWithoutItems : codeWithItems} onCopy={() => setIsCopied(true)}>
            <button className={styles.gallery_card_detailed_copy}>Скопировать код</button>
          </CopyToClipboard>
          {isCopied && <span style={{ color: "green" }}>Код скопирован</span>}
        </div>
      </>
    </ModalCustom>
  );
};

export default GalleryCode;
