import { Select } from "antd";
import styles from "./SelectCustom.module.scss";
import "./SelectCustom_override.scss";
import NotFoundContent from "./NotFoundContent";

interface IProps {
  name?: string;
  className?: string;
  options: IOptionsProps[];
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
  onDeselect?: (value: string | number) => void;
  defaultValue?: string;
  value?: string;
  width?: string;
  height?: string;
  placeholder?: string;
  mode?: "multiple" | "tags";
  disabled?: boolean;
  allowClear?: boolean;
  showSearch?: boolean;
  isNoBorder?: boolean;
  optionLabelProp?: string;
  filterOption?: (input: any, option: any) => boolean;
  open?: boolean;
  onDropdownVisibleChange?: (open: boolean) => void;
  isError?: boolean;
  minWidth?: string;
}

export interface IOptionsProps {
  value: string | number;
  disabled?: boolean;
  label?: string | JSX.Element;
}

const SelectCustom: React.FC<IProps> = ({
  placeholder,
  name,
  className,
  options,
  onChange,
  onSearch,
  onDeselect,
  defaultValue,
  value,
  width = "auto",
  height = "auto",
  mode,
  disabled,
  allowClear = false,
  showSearch = false,
  isNoBorder = false,
  optionLabelProp,
  filterOption,
  open,
  onDropdownVisibleChange,
  isError,
  minWidth,
}) => (
  <Select
    mode={mode}
    placeholder={placeholder}
    defaultValue={defaultValue ? defaultValue : name}
    className={`${styles.select} ${className}`}
    options={options}
    onChange={onChange}
    onSearch={onSearch}
    value={value}
    style={{ width: width, height: height, minWidth: minWidth }}
    disabled={disabled || false}
    onDeselect={onDeselect}
    allowClear={allowClear}
    showSearch={showSearch}
    optionFilterProp="label"
    maxTagCount="responsive"
    bordered={!isNoBorder}
    notFoundContent={<NotFoundContent />}
    optionLabelProp={optionLabelProp}
    filterOption={filterOption}
    open={open}
    onDropdownVisibleChange={onDropdownVisibleChange}
    status={isError ? "error" : ""}
  />
);

export default SelectCustom;
