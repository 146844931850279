import Leads from "../Leads/Leads";
import styles from "./ControlCardPopUp.module.scss";
import Clients from "../AnalyticsClients/AnalyticsClients";
import Projects from "../AnalyticsProjects/AnalyticsProjects";
import TrainingStatisticWithCoach from "../TrainingStatisticWithCoach/TrainingStatisticWithTrainer";
import AnalyticsByActiveDays from "../AnalyticsByActiveDays/AnalyticsByActiveDays";

interface IProps {
  header: {
    name: string;
    title: string;
  };
  updateDraftDealsCount: () => void;
}

const ControlCardPopUp: React.FC<IProps> = ({ header, updateDraftDealsCount }) => {
  return (
    <div className={styles.controlPanelPopUp}>
      <div className={styles.controlPanelPopUp__wrapper}>
        {header.name === "leads" ? <Leads updateDraftDealsCount={updateDraftDealsCount} /> : null}
        {header.name === "analytic_projects" ? <Projects /> : null}
        {header.name === "statistics_platform" ? (
          <>
            <div className={styles.controlPanelPopUp__wrapper_description}>{header.title}</div>
            <div className={styles.controlPanelPopUp__wrapper_description}>Раздел в разработке </div>
          </>
        ) : null}
        {header.name === "statistics_coach" ? <TrainingStatisticWithCoach /> : null}
        {header.name === "invoices" ? (
          <>
            <div className={styles.controlPanelPopUp__wrapper_description}>{header.title}</div>
            <div className={styles.controlPanelPopUp__wrapper_description}>Раздел в разработке</div>
          </>
        ) : null}
        {header.name === "register" ? (
          // <div className={styles.controlPanelPopUp__wrapper_description}>
          <Clients />
        ) : // </div>
        null}
        {header.name === "analytic_products" ? (
          <>
            <div className={styles.controlPanelPopUp__wrapper_description}>{header.title} </div>
            <div className={styles.controlPanelPopUp__wrapper_description}>Раздел в разработке</div>
          </>
        ) : null}
        {header.name === "analytic_active_days" ? <AnalyticsByActiveDays /> : null}
      </div>
    </div>
  );
};

export default ControlCardPopUp;
