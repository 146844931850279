import styles from "./NotificationsList.module.scss";
import { INotification, markNotificationAsRead } from "../../services/Reminders";
import { Popover } from "antd";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import "./NotificationsList_override.scss";

type Props = {
  notifications: INotification[];
  onClick: (id: string) => void;
};

const NotificationsList = ({ notifications, onClick }: Props) => {
  const history = useHistory();

  const { mutateAsync: onReadNotification } = useMutation({
    mutationFn: markNotificationAsRead,
  });

  const onNotificationClick = async (notification: INotification) => {
    const request = await onReadNotification(notification.id);
    if (request) {
      onClick(notification.id);
      if (notification.deal_id && !notification.lead_id) {
        history.push(`deals?deal_id=${notification.deal_id}`);
      }
      if (notification.lead_id && !notification.deal_id) {
        history.push(`leads?lead_id=${notification.lead_id}`);
      }
    }
  };

  return (
    <div className={styles.NotificationsList}>
      {notifications.map((notification) => {
        const label = notification.lead_id ? "лиду" : notification.deal_id ? "сделке" : "";
        return (
          <Popover
            placement="topLeft"
            title={
              <div className={styles.popoverStyle} onClick={() => onNotificationClick(notification)}>
                Перейти к {label}
              </div>
            }
            key={notification.id + "_popover"}
          >
            <div className={styles.NotificationsList__item} key={notification.id}>
              {!notification.processed && <span className={styles.NotificationsList__item_circle} />}
              <span className={styles.NotificationsList__item_label} key={notification.id + "_label"}>
                Напоминание на {dayjs(notification.notify_at).format("DD.MM.YYYY HH:mm")} "{notification.comment}"
              </span>
            </div>
          </Popover>
        );
      })}
    </div>
  );
};

export default NotificationsList;
