// Метод для получения всех доступных ролей
import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl5050 } from "../env";

export interface IReminder {
  notify_at: string;
  deal_id: string | null;
  lead_id: string | null;
  created_at: string;
  created_by: string;
  comment: string;
  id: string;
  user_id: string;
  was_read: boolean;
}

export interface INotification {
  id: string;
  created_at: string;
  deal_id: string | null;
  lead_id: string | null;
  created_by: string;
  comment: string;
  notify_at: string;
  postponed_until: string | null;
  processed: boolean;
  was_read: boolean;
  isSuccess?: boolean;
}

export interface IReminderResponse {
  count: number;
  items: IReminder[];
  status: number;
}

export interface IReminderNotificationsResponse {
  data: INotification[];
  status: number;
}

export interface IReminderRequest {
  deal_id?: string;
  lead_id?: string;
  // example - trigger_datetime: "2023-10-31T17:11:22.756Z"
  notify_at: string;
  comment: string;
}

export interface IAddReminderResponse {
  id: string;
  status: number;
}

export async function getReminders(): Promise<IReminderNotificationsResponse> {
  return axios
    .get(baseUrl5050 + `reminder/active`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getReminders:", err.response);
      return err.response;
    });
}

export async function getRemindersNotifications(): Promise<IReminderNotificationsResponse> {
  return axios
    .get(baseUrl5050 + `reminder/notification`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } }) ///// поменять на me
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getRemindersNotifications:", err.response);
      return { data: [], status: err?.status ?? 401 };
    });
}

export async function markNotificationAsRead(notificationId: string): Promise<INotification> {
  return axios
    .post(
      baseUrl5050 + `reminder/${notificationId}/processed`,
      { id: notificationId },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> markNotificationAsRead:", err.response);
      return err.response;
    });
}

export async function markNotificationAsNotified(notificationId: string): Promise<INotification> {
  return axios
    .post(
      baseUrl5050 + `reminder/${notificationId}/notified`,
      { id: notificationId },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> markNotificationAsNotified:", err.response);
      return err.response;
    });
}

export async function delayNotification({ notificationId, minutes }: { notificationId: string; minutes: number }): Promise<INotification> {
  return axios
    .put(
      baseUrl5050 + `reminder/${notificationId}/delay`,
      { minutes },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> delayNotification:", err.response);
      return err.response;
    });
}

export async function getRemindersByDealId(deal_id: string): Promise<IReminderResponse> {
  return axios
    .get(baseUrl5050 + `reminder/deal/${deal_id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        count: response.data.count,
        items: response.data.items,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getRemindersByDealId:", err.response);
      return err.response;
    });
}

export async function addReminder(reminder_request: IReminderRequest): Promise<IAddReminderResponse> {
  return axios
    .post(baseUrl5050 + `reminder/deal`, reminder_request, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        id: response.data.id,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> addReminder:", err.response);
      return err.response;
    });
}
