import { Modal } from "antd";
import React, { useState } from "react";
import styles from "../GalleryCards.module.scss";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import GalleryCardItems from "../../GalleryCardItems/GalleryCardItems";
import content, { onlyEmployee } from "../../../../settings/content";
import GalleryCardDetailed from "../../GalleryCardDetailed/GalleryCardDetailed";
import ValidateComponentByRole from "../../../../components/ValidateComponentByRole/ValidateComponentByRole";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cross.svg";
import { OpenDealButton } from "../../../../features/OpenDeal";
import { IProject } from "../../../../services/Projects";

type Props = {
  projectID: string | null;
  isPublicGallery: boolean | undefined;
  isPanoramaError: boolean;
  isOpenedFromDeal: string | undefined;
  handleCancelModal: () => void;
  handlePanoramaError: (isError: boolean) => void;
  handleModalSelect: (select: boolean) => void;
  handleDealSelect: (dealId: string) => void;
  refetchProjects: () => void;
  isMyProject?: boolean;
  sourceProject: IProject | null;
};

const GalleryCardsModalMain: React.FC<Props> = ({
  projectID,
  isPublicGallery,
  isPanoramaError,
  handleCancelModal,
  handlePanoramaError,
  handleModalSelect,
  isOpenedFromDeal,
  handleDealSelect,
  refetchProjects,
  isMyProject,
  sourceProject,
}) => {
  const [cardTitle, setCardTitle] = useState("");

  const handleCardInfo = (title: string) => {
    setCardTitle(title);
  };

  return (
    <Modal
      className={"custom_shop_modal"}
      key={projectID}
      centered
      width={800}
      open={!!projectID}
      onCancel={handleCancelModal}
      closeIcon={false}
      maskClosable={false}
      footer={
        projectID && [
          <div className={styles.gallery_cards_container__block}>
            <ValidateComponentByRole requiredRoles={onlyEmployee}>
              <div className={styles.gallery_cards_container__buttons_block}>
                <ButtonCustom
                  key={projectID}
                  className={styles.custom_button}
                  onClick={() => (!isOpenedFromDeal ? handleModalSelect(true) : handleDealSelect(isOpenedFromDeal))}
                  maxWidth="fit-content"
                  bgColor="darkGrey"
                  disabled={isPanoramaError}
                >
                  <span>{content.gallery.detail_card.create_projec_button_title}</span>
                </ButtonCustom>
                {isMyProject && sourceProject?.deal_id && <OpenDealButton dealId={sourceProject?.deal_id} />}
              </div>
            </ValidateComponentByRole>
            <GalleryCardItems selectedProjectId={projectID} isPublicGallery={isPublicGallery} />
          </div>,
        ]
      }
    >
      <div className={styles.gallery_cards_container__modal_header}>
        <span>{cardTitle}</span>
        <ButtonCustom isTransparent maxWidth="20px" className={styles.gallery_cards_container__modal_close} onClick={handleCancelModal}>
          <CloseIcon />
        </ButtonCustom>
      </div>
      {projectID && (
        <GalleryCardDetailed
          selectedProjectId={projectID}
          isPublicGallery={isPublicGallery}
          isErrorHandler={handlePanoramaError}
          handleCardInfo={handleCardInfo}
          isOpenedFromGallery
          refetchProjects={refetchProjects}
          isMyProject={isMyProject}
        />
      )}
    </Modal>
  );
};
export default GalleryCardsModalMain;
