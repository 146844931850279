import { Table, TableProps } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useUser } from "../../../context/useUser";
import { IClient, getAllClients } from "../../../services/Clients";
import { Role } from "../../../settings/content";
import "./AnalyticsClients_override.scss";

interface DataType {
  id?: string;
  fullname: string;
  phone: string | null;
  email: string | null;
}

type Props = {
  search: string;
  setSelectedClient: (client: IClient | undefined) => void;
};

const AnalyticsClientsTable = ({ search, setSelectedClient }: Props) => {
  const { user } = useUser();

  const [data, setData] = React.useState<IClient[]>([]);

  const { data: clientsData } = useQuery({
    queryFn: () =>
      getAllClients({
        search: search,
        //  limit: LIMIT, offset: offset
      }),
    queryKey: [`clientss`],
    onSuccess: (data) => {
      if (!data.data) {
        return;
      }
      setData(data.data.items);
    },
  });

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "ФИО клиента",
      dataIndex: "fullname",
      key: "fullname",
      width: "30%",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      width: "30%",
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
      width: "30%",
    },
  ];

  return (
    <Table
      id="analytics-by-active-days-table"
      onRow={(record) => {
        return {
          onClick: () => {
            if (user.role !== Role.ORGANIZATION_ADMIN) {
              const client = data.find((c) => c.id === record.id) as IClient | undefined;
              setSelectedClient(client);
            }
          },
        };
      }}
      columns={columns}
      dataSource={data.map((item) => ({ email: item.persons[0].email, id: item.id, fullname: item.title, phone: item.persons[0].phone }))}
      pagination={{
        defaultPageSize: 10,
        position: ["bottomCenter"],
        showSizeChanger: false,
      }}
      scroll={{ y: 500, x: 400 }}
      locale={{
        filterSearchPlaceholder: "Поиск",
        emptyText: "Данные по клиентам не найдены",
        filterConfirm: "Применить",
        filterReset: "Сбросить",
        filterTitle: "Фильтр",
        filterEmptyText: "Нет данных",
        selectAll: "Выбрать все",
        selectInvert: "Отменить выбор",
        selectionAll: "Выбрать все",
        sortTitle: "Сортировка",
        expand: "Развернуть строку",
        collapse: "Свернуть строку",
        triggerDesc: "Сортировать по убыванию",
        triggerAsc: "Сортировать по возрастанию",
        cancelSort: "Отменить сортировку",
      }}
      key={"analytics-by-clients-table"}
    />
  );
};

export default AnalyticsClientsTable;
