import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { checkAuthenticated } from "../helpers/checkAuth";
import { getAllCities } from "../services/Cities";

interface ICitiesProps {
  children: ReactElement;
}

export interface ICity {
  id: string;
  name: string;
  federal_subject: string;
  gmt_delta: string;
  country: string;
}

interface ICitiesContext {
  cities: ICity[];
}

const initialContext: ICitiesContext = {
  cities: [],
};

const CitiesContext = React.createContext<ICitiesContext>(initialContext);

export const useCities = () => {
  return useContext(CitiesContext);
};

export const CitiesProvider: React.FC<ICitiesProps> = ({ children }) => {
  const authenticated = checkAuthenticated();

  const [cities, setCities] = useState<ICity[]>([]);
  const { data: citiesData } = useQuery({
    queryFn: () => getAllCities(),
    queryKey: ["cities"],
    enabled: authenticated,
  });

  useEffect(() => {
    if (!citiesData?.data) {
      return;
    }
    setCities(citiesData.data);
  }, [citiesData]);

  return <CitiesContext.Provider value={{ cities }}>{children}</CitiesContext.Provider>;
};
