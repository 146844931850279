import React, { useEffect, useState } from "react";
import styles from "./ListBlanks.module.scss";
import "./ListBlanks_override.scss";
import content, { Role } from "../../../settings/content";
import ButtonCustom from "../../ui/ButtonCustom/ButtonCustom";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import Pagination from "../../Pagination/Pagination";
import { Image, Modal, message } from "antd";
import { useMutation } from "react-query";
import { IBlank, IBlanks, deleteBlank } from "../../../services/Blanks";
import moment from "moment";
import { NoticeType } from "antd/es/message/interface";
import { useUser } from "../../../context/useUser";
import ValidateComponentByRole from "../../ValidateComponentByRole/ValidateComponentByRole";
import { yandexCloudUrl } from "../../../env";
import { BlankTypes } from "../Blanks";

type Props = {
  LIMIT: number;
  offset: number;
  handleModal: (isOpen: boolean, type: string) => void;
  blanksData: IBlanks | undefined;
  refetch: () => void;
  isLoading: boolean;
  handlePageChange: (e: { selected: number }) => void;
};

const ListBlanks: React.FC<Props> = ({ handleModal, blanksData, refetch, isLoading, handlePageChange, LIMIT, offset }) => {
  const { mutateAsync: onDeleteBlank } = useMutation({
    mutationFn: deleteBlank,
  });
  const { user } = useUser();
  const [messageApi, contextHolder] = message.useMessage();
  const [blanks, setBlanks] = useState<IBlanks>();
  const [blanksItems, setBlanksItems] = useState<IBlank[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [blankId, setBlankId] = useState("");

  useEffect(() => {
    if (!blanksData) {
      return;
    }
    setBlanks(blanksData);
  }, [blanksData]);

  useEffect(() => {
    if (!blanks) {
      return;
    }
    if (blanks.data.items) {
      setBlanksItems(blanks.data.items);
      setPageCount(Math.ceil(blanks.data.count / LIMIT));
    }
  }, [blanks]);

  useEffect(() => {
    if (!blanks) {
      return;
    }
    setTimeout(() => {
      updateBlanks();
    }, 300);
  }, [offset]);

  /**
   * 1. Опубликован (Проверим диапазон дат, включая текущую)
   * 2. Истек (проверим только дату окончания публикации)
   * 3. Ожидает публикации (Дефолтное значение)
   * @param start - дата начала публикации
   * @param end   - дата оканчания публикации
   * @returns
   */
  const statusHandler = ({ end, start, status }: { start: string; end: string; status?: string }): string => {
    if (status === "published") {
      return content.blanks.list_modal.statuses.published;
    }
    if (status === "pending") {
      return content.blanks.list_modal.statuses.await;
    }
    if (status === "finished") {
      return content.blanks.list_modal.statuses.expired;
    }

    return "";
  };

  const updateBlanks = () => {
    refetch();
  };

  const deleteBlankHandler = async (blank_id: string) => {
    onDeleteBlank(blank_id).then((response) => {
      if (response.status === 200) {
        alert("success", content.alert_messages.list_blanks.success_blank);
        updateBlanks();
        setBlankId("");
      } else {
        alert("error", content.alert_messages.list_blanks.error_blank);
      }
    });
  };

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  return (
    <div className={styles.listBlanks}>
      <div className={styles.blanks__header}>
        <span className={styles.blanks__title}>Бланки</span>
        <div className={styles.blanks__btns}>
          <ValidateComponentByRole requiredRoles={[...content.blanks.requiredRoleForLPR, Role.ORGANIZATION_ADMIN]}>
            <ButtonCustom
              maxWidth="fit-content"
              onClick={() => {
                window.open("https://b24-3medbt.bitrix24.site/crm_form_yopab/", "_blank");
              }}
            >
              <span>{content.blanks.buttons.order}</span>
            </ButtonCustom>
          </ValidateComponentByRole>
          <ValidateComponentByRole requiredRoles={content.blanks.requiredRoleForLPR}>
            <ButtonCustom onClick={() => handleModal(true, BlankTypes.ADD)} maxWidth="fit-content">
              <span>{content.blanks.buttons.add}</span>
            </ButtonCustom>
          </ValidateComponentByRole>
        </div>
      </div>

      {contextHolder}
      {blanks?.status !== 200 && !isLoading ? (
        <span className={styles.listBlanks__error}>{content.blanks.list_modal.error}</span>
      ) : (
        <div className={styles.listBlanks__container}>
          {blanksItems.length && !isLoading ? (
            <>
              <div className={styles.listBlanks__header}>
                {content.blanks.list_modal.header_titles.map((title, index) => {
                  return (
                    <p key={`${title}_${index}`} className={styles.listBlanks__header_title}>
                      {title}
                    </p>
                  );
                })}
              </div>
              <div className={styles.listBlanks__cards}>
                {blanksItems.map((blank, index) => {
                  return (
                    <div key={blank.id} className={styles.listBlanks__card}>
                      <div className={styles.listBlanks__card_block}>
                        {/* <span className={styles.listBlanks__card_index}>{index + 1}.</span> */}
                        <Image
                          style={{ maxWidth: "60px" }}
                          width="100%"
                          height="auto"
                          loading="lazy"
                          className="custom-image"
                          fallback={`${yandexCloudUrl}blanks/organizations/${user.organization_id}/${blank.file_id}`}
                        />
                      </div>
                      <div className={styles.listBlanks__card_block}>
                        <span>
                          {moment(blank.start).format("DD.MM.YYYY")} - {moment(blank.end).format("DD.MM.YYYY")}
                        </span>
                      </div>
                      <div className={styles.listBlanks__card_block}>
                        <span>{blank.sold_count}</span>
                      </div>
                      <div className={styles.listBlanks__card_block}>
                        <span className={styles.listBlanks__status_text}>
                          {statusHandler({ start: blank.start, end: blank.end, status: blank.status })}
                        </span>
                        <ValidateComponentByRole requiredRoles={content.blanks.requiredRoleForLPR}>
                          {blank.status === "pending" ? (
                            <ButtonCustom
                              onClick={() => setBlankId(blank.id as string)}
                              isTransparent
                              maxWidth="30px"
                              className={styles.listBlanks__delete_btn}
                            >
                              <DeleteIcon />
                            </ButtonCustom>
                          ) : null}
                        </ValidateComponentByRole>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : !blanksItems.length && !isLoading ? (
            <span className={styles.listBlanks__empty}>{content.blanks.list_modal.empty}</span>
          ) : null}

          {pageCount > 1 && (
            <div className={styles.listBlanks__pagination}>
              <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
            </div>
          )}
        </div>
      )}
      <Modal
        className={"custom_shop_modal"}
        open={blankId !== ""}
        zIndex={9999999}
        centered
        mask={true}
        closeIcon={false}
        footer={false}
        width={340}
        maskClosable={false}
      >
        <div className={styles.listBlanks__warning}>
          <p>{content.blanks.delete_warning.title}</p>
          <div className={styles.listBlanks__warning_btns}>
            <ButtonCustom onClick={() => deleteBlankHandler(blankId)} className={styles.listBlanks__close}>
              <span>{content.blanks.delete_warning.delete}</span>
            </ButtonCustom>
            <ButtonCustom onClick={() => setBlankId("")} isTransparent>
              <span>{content.blanks.delete_warning.cancel}</span>
            </ButtonCustom>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ListBlanks;
