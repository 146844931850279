import React, { useEffect, useState } from "react";
import styles from "./LoginPage.module.scss";
import { Avatar, Form, Radio, RadioChangeEvent, message } from "antd";
import InputCustom from "../../components/ui/InputCustom/InputCustom";
import { isEmailValid, isPasswordValid } from "../../helpers/validation";
import { formatPhone, getInitials } from "../../helpers/formatString";
import ButtonCustom from "../../components/ui/ButtonCustom/ButtonCustom";
import "./LoginPage_override.scss";
import { useMutation, useQuery } from "react-query";
import { login } from "../../services/Authorization";
import Cookies from "js-cookie";
import content from "../../settings/content";
import { NoticeType } from "antd/es/message/interface";
import { useLocation } from "react-router-dom";
import { IResponseRecentUsers, getRecentUsers } from "../../services/User";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { PHONE_COUNTRIES } from "../../settings/countries";

type Props = {};

const LoginPage = (props: Props) => {
  const { mutateAsync: authRequest } = useMutation({
    mutationFn: login,
  });

  const { data: recentUsersData, isLoading } = useQuery({
    queryFn: () => getRecentUsers(getKeyId ? getKeyId : (localStorage.getItem("license_key") ?? "")),
    queryKey: ["recent_users"],
  });

  const getKeyId = new URLSearchParams(useLocation().search).get("key_id");

  const [loginType, setLoginType] = useState<"email" | "login" | "phone">("email");
  const [messageApi, contextHolder] = message.useMessage();
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [loginValue, setLoginValue] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [recentUsers, setRecentUsers] = useState<IResponseRecentUsers>();
  const [passwordHint, setPasswordHint] = useState(false);

  const onLoginTypeChange = (e: RadioChangeEvent) => {
    setPassword("");
    setLoginType(e.target.value);
  };

  const onPhoneChange = (phone: string) => {
    const formatedPhone = formatPhone(phone);
    setPhone(formatedPhone);
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginValue(e.target.value);
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (isPasswordValid(password)) {
      setPasswordHint(false);
      return;
    }
    if (password === "") {
      setPasswordHint(false);
      return;
    }
    setPasswordHint(true);
  }, [password]);

  useEffect(() => {
    if (getKeyId) {
      localStorage.setItem("license_key", getKeyId);
    }
  }, []);

  useEffect(() => {
    if (!recentUsersData) {
      return;
    }
    setRecentUsers(recentUsersData);
  }, [recentUsersData]);

  const onLogin = async () => {
    const data = {
      login: "",
      password: password,
      key: localStorage.getItem("license_key") ?? null,
    };
    if (loginType === "email") {
      data.login = email;
    }
    if (loginType === "phone") {
      data.login = phone;
    }
    if (loginType === "login") {
      data.login = loginValue;
    }
    authRequest(data).then((response) => {
      if (response?.access_token && response?.refresh_token) {
        Cookies.set("access_token", response.access_token);
        Cookies.set("refresh_token", response.refresh_token);
        window.location.href = "/";
      } else {
        alert("error", content.alert_messages.login.error);
      }
    });
  };

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  return (
    <div className={styles.loginPage__container}>
      {contextHolder}
      {recentUsers && recentUsers?.data && Array.isArray(recentUsers.data.sessions) && recentUsers.data.sessions.length ? (
        <div className={styles.loginPage__recent}>
          <h2 className={styles.loginPage__recent_header}>{content.login.resent_users}</h2>
          <div className={styles.loginPage__recent_wrapper}>
            {recentUsers?.data.sessions?.map((user) => {
              return (
                <div
                  className={styles.loginPage__recent_block}
                  onClick={() => {
                    setEmail(user.user_email);
                    setLoginValue(user.user_login);
                  }}
                >
                  <div className={styles.loginPage__recent_avatar}>
                    <Avatar style={{ backgroundColor: user.user_email === email ? "#FF8900" : "#3F95FF", color: "#fff" }}>
                      {getInitials(user.user_name)}
                    </Avatar>
                  </div>
                  <div className={styles.loginPage__recent_info}>
                    <span className={styles.loginPage__recent_name}>{user.user_name}</span>
                    <p className={styles.loginPage__recent_email}>{user.user_email}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      <h1 className={styles.loginPage__title}>{content.login.title}</h1>
      <Radio.Group onChange={onLoginTypeChange} value={loginType}>
        {content.login.tabs.map((tab, index) => {
          return (
            <Radio.Button className={styles.loginPage__typeBtn} value={tab.value}>
              {tab.title}
            </Radio.Button>
          );
        })}
      </Radio.Group>
      <Form className={styles.loginPage__form}>
        <div className={styles.loginPage__form_inputs}>
          {loginType === "phone" && (
            <div>
              <PhoneInput
                defaultCountry="ru"
                value={phone}
                onChange={onPhoneChange}
                placeholder={content.login.form.phone.placeholder}
                countries={PHONE_COUNTRIES}
                forceDialCode
              />
            </div>
          )}
          {loginType === "email" && (
            <>
              <InputCustom
                type="text"
                isError={!!email && !isEmailValid(email)}
                value={email}
                onChange={onEmailChange}
                placeholder={content.login.form.email.placeholder}
              />
            </>
          )}
          {loginType === "login" && (
            <>
              <InputCustom
                type="text"
                isError={!!loginValue && loginValue.length <= 1}
                value={loginValue}
                onChange={onLoginChange}
                placeholder={content.login.form.login.placeholder}
              />
            </>
          )}
          <InputCustom
            type="password"
            isError={!!password && !isPasswordValid(password)}
            value={password}
            onChange={onPasswordChange}
            placeholder={content.login.form.password.placeholder}
          />
          {password && (
            <span className={styles.loginPage__passwordHint} style={{ color: passwordHint ? "#f00" : "#000" }}>
              {content.login.form.password.password_hint}
            </span>
          )}
        </div>
        <div className={styles.loginPage__form_footer}>
          <ButtonCustom
            htmlType="submit"
            type="primary"
            children={<span>{content.login.footer.button_title}</span>}
            onClick={onLogin}
            className={styles.loginPage__form_submit}
          />
        </div>
      </Form>
    </div>
  );
};

export default LoginPage;
