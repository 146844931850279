import { useState } from "react";
import styles from "./AddEmailModal.module.scss";
import SelectCustom from "../../../../components/ui/SelectCustom/SelectCustom";
import InputCustom from "../../../../components/ui/InputCustom/InputCustom";
import { useMutation, useQuery } from "react-query";
import { getPhoneConnections, postEmailConnection } from "../../../../services/Shops";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import ModalCustom from "../../../../components/ModalCustom/ModalCustom";
import EmailFaq from "./EmailFaq";

type Props = {
  closeModal: () => void;
  emailMailingModalId: string;
  onSuccessEmailAdding: (email: string) => void;
};

const AddEmailModal = ({ closeModal, emailMailingModalId, onSuccessEmailAdding }: Props) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [provider, setProvider] = useState<string>("gmail");
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const { mutateAsync: onCreateEmailConnection } = useMutation({
    mutationFn: postEmailConnection,
  });

  const { refetch } = useQuery({
    queryFn: () => getPhoneConnections(),
    queryKey: [`phone_connections${emailMailingModalId}`],
  });

  const onSubmit = async () => {
    setError("");

    const request = await onCreateEmailConnection({ email, password, provider_name: provider });
    if (request.status === 200) {
      onSuccessEmailAdding(email);
      closeModal();
      refetch();
    }
    if (request.status !== 200) {
      setError(request.data);
    }
  };

  return (
    <div className={styles.AddEmailModal}>
      <span className={styles.AddEmailModal__faq}>
        Перед вводом информации необходимо провести подготовительные работы для корректной работы рассылки (см.
        <span className={styles.AddEmailModal__faq_link} onClick={() => setIsFaqOpen(true)}>
          {" "}
          инструкция по подготовке почты к рассылке
        </span>
        )
      </span>
      <div className={styles.AddEmailModal__select_block}>
        <SelectCustom
          placeholder="Выберите провайдера"
          width="100%"
          value={provider}
          onChange={(value) => setProvider(value)}
          options={[
            { value: "gmail", label: "Gmail" },
            { value: "yandex", label: "Yandex" },
            { value: "mail-ru", label: "Mail.ru" },
          ]}
        />
      </div>
      <div className={styles.AddEmailModal__input_block}>
        <span>Введите E-mail</span>
        <InputCustom onChange={(e) => setEmail(e.target.value)} value={email} />
      </div>
      <div className={styles.AddEmailModal__input_block}>
        <span>Введите пароль</span>
        <InputCustom onChange={(e) => setPassword(e.target.value)} value={password} type="password" />
      </div>
      {(!email.length || !password.length) && <span className={styles.AddEmailModal__errortext}>Все поля должны быть заполнены</span>}
      {error !== "" && <span className={styles.AddEmailModal__errortext}>{error}</span>}
      <div className={styles.AddEmailModal__footer_btns}>
        <ButtonCustom className={styles.AddEmailModal__footer_btn} onClick={closeModal} maxWidth="100px" bgColor="gray">
          <span>Отмена</span>
        </ButtonCustom>
        <ButtonCustom
          disabled={!email.length || !password.length}
          className={styles.AddEmailModal__footer_btn}
          onClick={onSubmit}
          maxWidth="100px"
          bgColor="blue"
        >
          <span>Подключить</span>
        </ButtonCustom>
      </div>
      <ModalCustom
        title={"Подготовка почты к рассылке"}
        isOpen={isFaqOpen}
        handleCloseModal={() => setIsFaqOpen(false)}
        centered
        width={800}
        footer={null}
        onCancel={() => setIsFaqOpen(false)}
        destroyOnClose
      >
        <EmailFaq />
      </ModalCustom>
    </div>
  );
};

export default AddEmailModal;
