import React, { useEffect, useState } from "react";
import styles from "./WorkstationsList.module.scss";
import { useWorkstations } from "../../../../context/useWorkstations";
import { Table, TableProps } from "antd";
import { tableLocale } from "../../../../helpers/tableLocale";
import { useShops } from "../../../../context/useShops";
import ModalCustom from "../../../../components/ModalCustom/ModalCustom";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import content, { onlyAdmin } from "../../../../settings/content";
import { useMutation } from "react-query";
import { deleteWorkstation } from "../../../../services/Workstation";
import ValidateComponentByRole from "../../../../components/ValidateComponentByRole/ValidateComponentByRole";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";

type Props = {
  workstationId: string;
  setEditWorkstationId: React.Dispatch<React.SetStateAction<string>>;
};

type DataType = {
  id: string;
  key: string;
  name: string;
  shop_id: string;
  shop_name: string;
  department_name: string;
  department_id: string;
  controle_buttons: JSX.Element;
  license_id: string | null;
};

const WorkstationsList: React.FC<Props> = ({ workstationId, setEditWorkstationId }) => {
  const [deleteId, setDeleteId] = useState("");
  const [currentShopIdFilter, setCurrentShopIdFilter] = useState<string[]>([]);

  const { refetch, workstations, licences, refetchLicences } = useWorkstations();
  const { allDepartments, shops } = useShops();

  useEffect(() => {
    refetch();
  }, [workstationId]);

  const handleChange = (pagination: any, filters: any) => {
    setCurrentShopIdFilter(filters.shop_name ?? []); // Обновляем состояние с выбранными фильтрами
  };

  const { mutateAsync: onDeleteWorkstation } = useMutation({
    mutationFn: deleteWorkstation,
  });

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Наименование ключа",
      dataIndex: "name",
      key: "name",
      width: "16%",
    },
    {
      title: "Магазин",
      dataIndex: "shop_name",
      key: "shop_name",
      width: "20%",
      filters: shops.map((item) => ({ text: item.name, value: item.id })),
      onFilter: (value, record) => {
        return record.shop_id?.indexOf(value as string) === 0;
      },
      filterSearch: true,
    },
    {
      title: "Отдел",
      dataIndex: "department_name",
      key: "department_name",
      width: "20%",
      filters:
        currentShopIdFilter.length > 0
          ? allDepartments
              .filter((item) => currentShopIdFilter.includes(item.shop_id))
              .map((item) => ({ text: `${item?.name} ${shops.find((shop) => shop.id === item.shop_id)?.address}`, value: item.id }))
          : allDepartments.map((item) => ({
              text: `${item?.name} ${shops.find((shop) => shop.id === item.shop_id)?.address}`,
              value: item.id,
            })),
      onFilter: (value, record) => record.department_id?.indexOf(value as string) === 0,
      filterSearch: true,
    },
    {
      title: "Ключ",
      dataIndex: "license_id",
      key: "license_id",
      width: "20%",
    },
    {
      title: "",
      dataIndex: "controle_buttons",
      key: "controle_buttons",
      width: "7%",
    },
  ];

  return (
    <div className={styles.workstationsList}>
      <Table
        id="workstations-list-table"
        columns={columns}
        dataSource={workstations.map((workstation) => {
          const shopId = allDepartments.find((department) => department.id === workstation.department_id)?.shop_id;
          return {
            id: workstation.id,
            key: workstation.id,
            name: workstation.name ?? "",
            shop_name: shops.find((shop) => shop.id === shopId)?.name ?? "-",
            shop_id: shopId ?? "",
            department_name: allDepartments.find((department) => department.id === workstation.department_id)?.name ?? "-",
            department_id: workstation.department_id ?? "",
            controle_buttons: (
              <ValidateComponentByRole requiredRoles={[...onlyAdmin]}>
                <div className={styles.workstation__btns}>
                  <ButtonCustom
                    isTransparent
                    className={styles.workstation__btn}
                    onClick={() => {
                      setEditWorkstationId(workstation.id);
                    }}
                    maxWidth="32px"
                  >
                    <EditIcon />
                  </ButtonCustom>
                  <ButtonCustom
                    isTransparent
                    className={styles.workstation__btn}
                    onClick={() => setDeleteId(workstation.id)}
                    maxWidth="32px"
                  >
                    <DeleteIcon />
                  </ButtonCustom>
                </div>
              </ValidateComponentByRole>
            ),
            license_id: licences.find((licence) => licence.id === workstation.license_id)?.key ?? "",
          };
        })}
        pagination={{ defaultPageSize: 15, position: ["bottomCenter"], showSizeChanger: false }}
        locale={tableLocale}
        key={"workstations-list-table"}
        onChange={handleChange}
      />
      <ModalCustom
        title="Удалить рабочее место"
        isOpen={deleteId !== ""}
        centered
        onCancel={() => setDeleteId("")}
        width={340}
        destroyOnClose
        handleCloseModal={() => setDeleteId("")}
        footer={null}
      >
        <div className={styles.workstation__warning}>
          <p style={{ textAlign: "center", marginBottom: "15px" }}>Вы действительно хотите удалить рабочее место? </p>
          <div className={styles.workstation__warning_btns}>
            <ButtonCustom
              onClick={() => setDeleteId("")}
              className={`${styles.workstation__warning_close} ${styles.workstation__waringn_btn}`}
            >
              <span>{content.organizations.shops.warning.cancel}</span>
            </ButtonCustom>
            <ButtonCustom
              onClick={() => {
                onDeleteWorkstation(deleteId).then((response) => {
                  if (response?.status === 200) {
                    setDeleteId("");
                    refetch();
                    refetchLicences();
                  }
                });
              }}
              isTransparent
              className={`${styles.workstation__waringn_btn}`}
            >
              <span>{content.organizations.shops.warning.delete}</span>
            </ButtonCustom>
          </div>
        </div>
      </ModalCustom>
    </div>
  );
};
export default WorkstationsList;
