import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styles from "./AnalyticsClients.module.scss";
import "./AnalyticsClients_override.scss";
import { message } from "antd";
import { useDebounce } from "@uidotdev/usehooks";
import { IClient, getAllClients } from "../../../services/Clients";
import SearchBar from "../../SearchBar/SearchBar";
import ClientCard from "../../../pages/DealsPage/components/DealCardCreate/ClientCard/ClientCard";
import Loader from "../../ui/Loader/Loader";
import ComponentError from "../../ui/ComponentError/ComponentError";
import AnalyticsClientsTable from "./AnalyticsClientsTable";
import axios from "axios";
import { baseUrl5050 } from "../../../env";
import Cookies from "js-cookie";
import ButtonCustom from "../../ui/ButtonCustom/ButtonCustom";
import { NoticeType } from "antd/es/message/interface";

type Props = {};

export interface ISelect {
  label: string;
  value: string;
}

const Clients: React.FC<Props> = ({}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [search, setSearch] = useState<string>("");
  const [selectedClient, setSelectedClient] = useState<IClient>();

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const {
    data: clientsData,
    refetch,
    isLoading,
    error,
  } = useQuery({
    queryFn: () => getAllClients({ search: search }),
    queryKey: [`clientss`],
  });

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    refetch();
  }, [debouncedSearch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const refetchClient = (updatedClient: IClient) => {
    refetch();
  };

  const handleDownload = async () => {
    try {
      const response = await axios.get(baseUrl5050 + `client/export`, {
        headers: { Authorization: "Bearer " + Cookies.get("access_token") },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "clients-table.xlsx"); // Укажите имя файла
      link.click();
      link.remove();
    } catch (error) {
      alert("error", "Не удалось экспортировать таблицу");
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ComponentError />;
  }

  return (
    <div className={styles.clients}>
      {contextHolder}
      <div className={styles.clients__table_wrapper}>
        <SearchBar className={styles.clients__header_search} placeholder={"Поиск по клиентам"} onChange={handleChange} value={search} />
        <ButtonCustom maxWidth="120px" onClick={() => handleDownload()} className={styles.clients__header_exportbtn}>
          <span>Скачать Excel</span>
        </ButtonCustom>
      </div>
      <AnalyticsClientsTable search={debouncedSearch} setSelectedClient={setSelectedClient} />

      <ClientCard
        isOpen={!!selectedClient}
        client={selectedClient}
        onCancel={() => setSelectedClient(undefined)}
        refetchClient={refetchClient}
      />
    </div>
  );
};
export default Clients;
