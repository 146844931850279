import React from "react";
import Layout from "../../components/Layout/Layout";
import ControlPanel from "../../components/ControlPanel/ControlPanel";
import Blanks from "../../components/Blanks/Blanks";

export const BlanksPage: React.FC = () => {
  return (
    <Layout>
      <ControlPanel />
      <Blanks />
    </Layout>
  );
};
