import { useEffect, useState } from "react";
import { Avatar } from "antd";
import { getInitials } from "../../../../../../helpers/formatString";
import content, { onlyEmployee, Role } from "../../../../../../settings/content";
import { IProject, IProjectSaleStatuses, changeProjectSaleStatus } from "../../../../../../services/Projects";
import styles from "./DetailCardProjectsLabel.module.scss";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { getSelectedUserInfo } from "../../../../../../services/User";
import SelectCustom from "../../../../../../components/ui/SelectCustom/SelectCustom";
import { useUser } from "../../../../../../context/useUser";

interface IProps {
  data: IProject;
  refetchProjects: () => void;
  userInfo: {
    name: string;
  };
  isDealClosed: boolean;
}

export const ProjectSaleStatusOptions = [
  { value: IProjectSaleStatuses.ready_for_sale, label: "К продаже" },
  { value: IProjectSaleStatuses.rejected, label: "Отказ" },
  { value: IProjectSaleStatuses.sold, label: "Продано" },
];

const DetailCardProjectsLabel: React.FC<IProps> = ({ data, userInfo, refetchProjects, isDealClosed }) => {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [createdBy, setCreatedBy] = useState("");

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const { data: userData } = useQuery({
    queryFn: () => getSelectedUserInfo(data.created_by),
    queryKey: [`users_created_${data.created_by}`],
  });

  const { mutateAsync: onChangeSaleStatus } = useMutation({
    mutationFn: changeProjectSaleStatus,
  });

  useEffect(() => {
    if (!userData) {
      return;
    }
    setCreatedBy(userData.name);
  }, [userData]);

  function handleStatusChange(value: string): void {
    onChangeSaleStatus({ id: data.id, sale_status: value as IProjectSaleStatuses }).then((response) => {
      if (response.status === 200) {
        refetchProjects();
      }
    });
  }

  function getStatusColor(sale_status: IProjectSaleStatuses): string {
    if (sale_status === IProjectSaleStatuses.rejected) {
      return "#9D2121";
    }
    if (sale_status === IProjectSaleStatuses.sold) {
      return "#4C8857";
    }
    return "inherit";
  }

  return (
    <div className={styles.collapse__header} onClick={handleClick}>
      <div className={styles.collapse__header_project}>
        {isOpen ? (
          <>
            <Avatar
              size={22}
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                backgroundColor: "#FF8900",
                color: "#fff",
                fontWeight: "400",
              }}
            >
              {getInitials(createdBy)}
            </Avatar>
            <div className={styles.collapse__header_project_user_name}>{createdBy}</div>
          </>
        ) : (
          data.name
        )}
      </div>
      <div className={styles.collapse__header_date}>{moment(data.created_at).format("DD/MM/YYYY, HH:mm")}</div>
      <div className={styles.collapse__header_name}>{isOpen ? content.deals.detail_deal_card.tabs.projects.label_create : createdBy}</div>
      <div
        className={styles.collapse__header_project_select}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ width: isDealClosed ? "fit-content" : "170px" }}
      >
        {!isDealClosed ? (
          <SelectCustom
            defaultValue={data.sale_status}
            options={ProjectSaleStatusOptions.filter((item) => item.value !== IProjectSaleStatuses.sold)}
            onChange={handleStatusChange}
            value={data.sale_status}
            width="165px"
            disabled={!onlyEmployee.includes(user.role as Role)}
          />
        ) : (
          <span style={{ color: getStatusColor(data.sale_status) }}>
            {ProjectSaleStatusOptions.find((item) => item.value === (data.sale_status as unknown))?.label}
          </span>
        )}
      </div>
    </div>
  );
};

export default DetailCardProjectsLabel;
