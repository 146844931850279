import { Table, TableProps } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { IMyLesson, getShopLessons, getOrganizationLessons } from "../../../services/Lessons";
import dayjs from "dayjs";
import { useUser } from "../../../context/useUser";
import { onlyLPR, Role } from "../../../settings/content";

interface DataType {
  user_name: string;
  finished_at: string;
  test: string;
  name: string;
  trainer_name: string;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "ФИО",
    dataIndex: "user_name",
    key: "user_name",
  },
  {
    title: "Дата прохождения урока",
    dataIndex: "finished_at",
    key: "finished_at",
  },
  //   {
  //     title: "Дата назначенного урока",
  //     dataIndex: "test",
  //     key: "test",
  //   },
  {
    title: "Название урока",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Тренер",
    dataIndex: "trainer_name",
    key: "trainer_name",
  },
  {
    title: "Магазин",
    dataIndex: "user_shop_name",
    key: "user_shop_name",
  },
  {
    title: "Отдел",
    dataIndex: "user_shop_address",
    key: "user_shop_address",
  },
];

type Props = {
  selectedInterval: { dateFrom: string; dateTo: string };
};

const DepartmentTrainingTable = ({ selectedInterval }: Props) => {
  const { user } = useUser();

  const [departmentLessons, setDepartmentLessons] = useState<IMyLesson[]>([]);
  const { data: departmentLessonsData } = useQuery({
    queryFn: () => (onlyLPR.includes(user.role as Role) ? getOrganizationLessons(selectedInterval) : getShopLessons(selectedInterval)),
    queryKey: [`departmentLessons_${selectedInterval.dateFrom}/${selectedInterval.dateTo}`],
    onSuccess: (data) => {
      if (!Array.isArray(data.data)) {
        return;
      }
      setDepartmentLessons(data.data);
    },
  });

  return (
    <div>
      <Table
        id="table"
        columns={columns}
        dataSource={departmentLessons.map((item) => ({
          user_name: item.user_name,
          finished_at: dayjs(item.finished_at).format("DD.MM.YYYY HH:MM"),
          test: dayjs(item.finished_at).format("DD.MM.YYYY HH:MM"),
          name: item.name,
          trainer_name: item.trainer_name,
          user_shop_name: item.user_shop_name,
          user_shop_address: item.user_shop_address,
        }))}
        pagination={false}
        scroll={{ y: 500, x: 600 }}
        locale={{ emptyText: "Данные по проектам не найдены" }}
        key={"all-lessons-table"}
      />
    </div>
  );
};

export default DepartmentTrainingTable;
