import React, { useEffect, useRef, useState } from "react";
import { IDealCommentsHistoryResponse, IDealHistoryStatusResponse, IDealStatusResponse } from "../../../../../services/Deals";
import styles from "./DetailCardHistoryStatuses.module.scss";
import moment from "moment";
import content from "../../../../../settings/content";
import NotFound from "../../../../../components/NotFound/NotFound";
import { useQuery } from "react-query";
import { IStuffUser, getAllStuff } from "../../../../../services/User";
import CommentComponent from "./CommentComponent";
import { IReminderResponse } from "../../../../../services/Reminders";
import { ReactComponent as NotificationIcon } from "../../../../../assets/icons/notification.svg";

interface IProps {
  statusesHistory?: IDealHistoryStatusResponse[];
  statusesData?: IDealStatusResponse[];
  commentsHistory: IDealCommentsHistoryResponse[];
  remindersData: IReminderResponse | undefined;
  updateHistory: () => void;
}

export interface IHistoryItem {
  text: string;
  created_by?: string;
  created_at?: string;
  name?: string;
  type: "comment" | "status" | "reminder";
  id?: string;
}

interface IHistoryListItem {
  date: string;
  items: IHistoryItem[];
}

const DetailCardHistoryStatuses: React.FC<IProps> = ({ statusesHistory, statusesData, commentsHistory, remindersData, updateHistory }) => {
  const { data: users } = useQuery({
    queryFn: () => getAllStuff(),
    queryKey: ["users"],
  });

  const statusesHistoryRef = useRef<HTMLDivElement>(null);
  const [usersData, setUsersData] = useState<IStuffUser[]>([]);
  const [statusesList, setStatusesList] = useState<IHistoryListItem[]>([]);
  const [commentsList, setCommentsList] = useState<IHistoryListItem[]>([]);
  const [remindersList, setRemindersList] = useState<IHistoryListItem[]>([]);
  const [forbidden, setForbidden] = useState(false);
  const [fullList, setFullList] = useState<
    {
      date: string;
      items: IHistoryItem[];
    }[]
  >([]);

  useEffect(() => {
    if (!users || !users?.data) {
      return;
    }
    if (users?.status === 403) {
      setForbidden(true);
      return;
    }
    setUsersData(users.data);
  }, [users]);

  useEffect(() => {
    const divElement = statusesHistoryRef.current;
    if (divElement) {
      divElement.scrollTop = divElement.scrollHeight;
    }
  });

  useEffect(() => {
    if (!remindersData) {
      return;
    }
    const reducedData = remindersData.items.reduce((acc: any, curr) => {
      const { comment, notify_at, user_id, id, created_at } = curr;
      const userCreatedBy = usersData.find((item) => item.id === user_id);
      const converteDate = moment(created_at).format("DD.MM.YYYY");

      if (!acc[converteDate]) {
        acc[converteDate] = [];
      }
      acc[converteDate].push({
        type: "reminder",
        text: `Напоминание на ${moment(notify_at).format("DD.MM.YYYY HH:mm")} "${comment}"`,
        name: userCreatedBy?.name ? userCreatedBy?.name : user_id,
        created_at: created_at,
        created_by: user_id,
        id: id,
      });
      return acc;
    }, {});

    // Переведем в массив для удобной распечатки
    const convertToArray = Object.entries(reducedData).map((i) => {
      const item: IHistoryListItem = {
        date: i[0],
        items: i[1] as IHistoryItem[],
      };
      return item;
    });

    setRemindersList(convertToArray);
  }, [remindersData]);

  useEffect(() => {
    if (!statusesHistory) {
      return;
    }

    // Указал тип Any явно, так как при первой итерации
    // получаем пустой объект {}
    const reducedData = statusesHistory.reduce((acc: any, curr) => {
      const { created_at, status } = curr;
      const converteDate = moment(created_at).format("DD.MM.YYYY");
      if (!acc[converteDate]) {
        acc[converteDate] = [];
      }
      acc[converteDate].push({ type: "status", text: curr.status, created_at: created_at });
      return acc;
    }, {});

    // Переведем в массив для удобной распечатки
    const convertToArray = Object.entries(reducedData).map((i) => {
      const item: IHistoryListItem = {
        date: i[0],
        items: i[1] as IHistoryItem[],
      };
      return item;
    });

    setStatusesList(convertToArray);
  }, [statusesHistory]);

  useEffect(() => {
    if (!commentsHistory) {
      return;
    }
    const reducedData = commentsHistory.reduce((acc: any, curr) => {
      const { created_at, text, created_by, id } = curr;
      const userCreatedBy = usersData.find((item) => item.id === created_by);
      const converteDate = moment(created_at).format("DD.MM.YYYY");
      if (!acc[converteDate]) {
        acc[converteDate] = [];
      }
      acc[converteDate].push({
        type: "comment",
        text,
        name: userCreatedBy?.name ? userCreatedBy?.name : created_by,
        created_by: created_by,
        created_at,
        id: id,
      });
      return acc;
    }, {});

    // Переведем в массив для удобной распечатки
    const convertToArray = Object.entries(reducedData).map((i) => {
      const item: IHistoryListItem = {
        date: i[0],
        items: i[1] as IHistoryItem[],
      };
      return item;
    });

    setCommentsList(convertToArray);
  }, [commentsHistory, usersData]);

  useEffect(() => {
    const list: { [key: string]: IHistoryItem[] } = {};

    statusesList
      .concat(commentsList)
      .concat(remindersList)
      .forEach((obj) => {
        if (list[obj.date]) {
          list[obj.date] = list[obj.date].concat(obj.items);
        } else {
          list[obj.date] = obj.items;
        }
      });

    const transformedArray = Object.entries(list)
      .map(([date, items]) => ({
        date,
        items: items.sort((a, b) => {
          const dateA = moment(a.created_at);
          const dateB = moment(b.created_at);
          return dateA.diff(dateB);
        }),
      }))
      .sort((a, b) => {
        const dateA = moment(a.date, "DD.MM.YYYY");
        const dateB = moment(b.date, "DD.MM.YYYY");
        return dateA.diff(dateB);
      });

    setFullList(transformedArray);
  }, [statusesList, commentsList, remindersList]);

  return (
    <>
      {forbidden ? <div style={{ color: "#fff", fontWeight: 600, background: "#f00", textAlign: "center" }}>FORBIDDEN </div> : null}
      <div className={styles.detailCardHistoryStatuses} ref={statusesHistoryRef}>
        {fullList.map((item, index) => {
          return (
            <div className={styles.detailCardHistoryStatuses__block} key={item.date + index}>
              <p className={styles.detailCardHistoryStatuses__date}>{item.date}</p>
              {item.items.map((historyItem) => {
                if (historyItem.type === "status") {
                  return (
                    <p className={styles.detailCardHistoryStatuses__status} key={historyItem.text + index + historyItem.created_at}>
                      {content.deals.detail_deal_card.history_statuses.label} "
                      {statusesData?.find((item) => item.value === historyItem.text)?.name ?? historyItem.text}"
                    </p>
                  );
                }
                if (historyItem.type === "comment") {
                  return (
                    <CommentComponent
                      historyItem={historyItem}
                      updateHistory={updateHistory}
                      key={historyItem.text + index + historyItem.created_at}
                    />
                  );
                }
                if (historyItem.type === "reminder") {
                  return (
                    <div style={{ marginBottom: "15px" }} key={historyItem.text + index + historyItem.created_at}>
                      <span className={styles.detailCardHistoryStatuses__reminder_date}>
                        {moment(historyItem.created_at).format("DD MMMM, HH:mm")}
                      </span>
                      <div className={styles.detailCardHistoryStatuses__reminder_content}>
                        <NotificationIcon />
                        <p className={styles.detailCardHistoryStatuses__reminder} key={historyItem.text + index}>
                          <span>{historyItem.text}</span>
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          );
        })}
        {!Array.from(statusesList).length && <NotFound title="История смены статусов отсутствует" />}
      </div>
    </>
  );
};

export default DetailCardHistoryStatuses;
