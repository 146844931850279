import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl5050 } from "../env";

export interface IPerson {
  id?: string | null;
  fullname: string;
  deleted: boolean;
  role: string | null;
  email: string | null;
  phone: string | null;
}

export type ClientType = "ru_individual" | "ru_individual_enterpreneur" | "ru_organization";

export interface IClient {
  id: string;
  type: ClientType | null;
  title: string;
  persons: IPerson[];
}

export interface ICreateClient {
  title: string;
  type: ClientType | null;
  persons: IPerson[];
}

export interface IEditClient {
  id?: string;
  title: string;
  type: ClientType | null;
  persons: IPerson[];
}

export interface IEditClientResponse {
  data: IEditClient;
  status: number;
}

export interface IGetAllClients {
  data: { count: number; items: IClient[] };
  status: number;
}

export interface IGetClientById {
  data: IClient;
  status: number;
}

export interface IClientData {
  search: string;
  offset?: number;
  limit?: number;
}

// Метод для получения всех клиентов
export async function getAllClients(clientData?: IClientData): Promise<IGetAllClients> {
  const search = clientData?.search ? "search=" + clientData.search || "" : "";
  const offset = clientData?.offset ? "&offset=" + clientData.offset : "";
  const limit = clientData?.limit ? "&limit=" + clientData?.limit : "";

  try {
    const request = await axios.get(baseUrl5050 + `client${clientData ? "?" : ""}${search}${offset}${limit}`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    });
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getAllClients:");
    throw new Error(err.request.status);
  }
}

// Метод для получения всех клиентов
export async function getClientById(id: string): Promise<IGetClientById> {
  try {
    const request = await axios.get(baseUrl5050 + `client/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } });
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getClientById:");
    throw new Error(err.request.status);
  }
}

// Метод для создания клиента
export async function createClient(clientData: ICreateClient): Promise<any> {
  return axios
    .post(baseUrl5050 + `client`, { ...clientData }, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((error) => {
      console.log("Ошибка в методе ==> createClient:", error.response);
      return error.response;
    });
}

// Метод для изменения клиента
export async function editClient(clientData: IEditClient): Promise<IEditClientResponse> {
  if (!clientData.id) {
    throw new Error("Не передан ID объекта для изменений");
  }

  const id = clientData.id;

  // удалим из объекта поле id. Для корректного запроса
  delete clientData.id;

  try {
    const request = await axios.put(
      baseUrl5050 + `client/${id}`,
      { ...clientData },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    );
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка в методе ==> editClient:", err);
    throw new Error(err.response.data);
  }
}

// Метод для получения excel таблицы
export async function getExcel(): Promise<IGetClientById> {
  try {
    const request = await axios.get(baseUrl5050 + `client/export`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } });
    console.log(request);
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getExcel:");
    throw new Error(err.request.status);
  }
}
