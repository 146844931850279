import { useEffect, useState } from "react";
import DragDropProvider, { ColumnType } from "./DragDropProvider";
import KanbanBoard from "./KanbanBoard";

type Props = {
  data: ColumnType[];
  onCardClick: (id: string) => void;
  changeStatusRequest: ({ id, value }: { id: string; value: string }) => Promise<any>;
  isDragDisabled: boolean;
  isLeads?: boolean;
};

const KanbadBoardContainer = ({ data, onCardClick, changeStatusRequest, isDragDisabled, isLeads }: Props) => {
  const [kanbanData, setkanbanData] = useState<ColumnType[]>([]);
  useEffect(() => {
    if (!data) {
      return;
    }
    setkanbanData(data);
  }, [data]);

  return (
    <DragDropProvider data={kanbanData} changeStatusRequest={changeStatusRequest} isLeads={isLeads}>
      <KanbanBoard onCardClick={onCardClick} isDragDisabled={isDragDisabled} />
    </DragDropProvider>
  );
};

export default KanbadBoardContainer;
