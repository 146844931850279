import styles from "./FiltersSettings.module.scss";
import FilterItem from "./FilterItem";
import { createDealFilter, getDealFilters, IDealFilter } from "../../../../services/DealFilters";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import { ReactComponent as AddIcon } from "../../../../assets/icons/add.svg";
import { useMutation, useQuery } from "react-query";
import dayjs from "dayjs";

type Props = {
  filtersList: IDealFilter[];
  setSelectedFilter: (item: IDealFilter | undefined) => void;
  selectedFilter?: IDealFilter;
};

const FiltersList = ({ filtersList, setSelectedFilter, selectedFilter }: Props) => {
  const { refetch: refetchDealFilters } = useQuery({
    queryFn: () => getDealFilters(),
    queryKey: ["dealFilters"],
  });
  const { mutateAsync: onCreateDealFilter } = useMutation({
    mutationFn: createDealFilter,
  });
  const onCreateNewDealFilter = () => {
    onCreateDealFilter({
      name: "Новый фильтр " + dayjs().format("DD.MM HH:mm"),
      value: { shop_ids: null, department_ids: null, user_ids: null, period_start: null, period_end: null },
    }).then((response) => {
      if (response.status === 200) {
        refetchDealFilters();
        setSelectedFilter(response.data);
      }
    });
  };
  return (
    <div className={styles.filtersList}>
      <div className={styles.filtersList__title}>Фильтры</div>
      <div className={styles.filtersList__list}>
        {filtersList.map((item) => {
          return <FilterItem key={item.id} item={item} setSelectedFilter={setSelectedFilter} selectedFilter={selectedFilter} />;
        })}
        {filtersList.length === 0 && <div className={styles.filtersSettings__empty}>Нет фильтров</div>}
      </div>
      <ButtonCustom
        onClick={() => onCreateNewDealFilter()}
        isTransparent
        maxWidth="fit-content"
        className={styles.filtersSettings__left_addbtn}
      >
        <span>
          <AddIcon /> Создать фильтр
        </span>
      </ButtonCustom>
    </div>
  );
};

export default FiltersList;
