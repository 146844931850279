import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl5052 } from "../env";

interface IUploadFile {
  id: string;
  status: number;
}

interface IFile {}

export async function uploadFile(file: IFile): Promise<IUploadFile> {
  return axios
    .post(baseUrl5052 + "file", file, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        id: response.data.id,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> uploadFile:", err.response);
      return err.response;
    });
}
