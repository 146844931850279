import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl, baseUrl5050, baseUrl5053, baseUrl5056 } from "../env";

export interface IGetShops {
  data: IShop[];
  status: number;
}

export interface IShop {
  id: string;
  name: string;
  address: string;
  city_id: string;
}

export interface IAddShop {
  name: string;
  address: string;
  city_id: string;
  // department?: string;
}

interface IEditShop {
  shop_id?: string;
  name: string;
  address?: string;
  city_id: string;
}

interface IDeleteShop {
  status: number;
}

export interface IEmailBinding {
  id: string;
  shop_id: string;
  connection_id: string;
  email: string;
}

export interface IGetPhoneBinding {
  id: string;
  shop_id: string;
  connection_id: string;
  connection_provider: string;
  connection_state: string;
  phone: string;
}

export interface IGetEmailBindings {
  data: IEmailBinding[];
  status: number;
}

interface IGetShopTelephoneById {
  data: IGetPhoneBinding[];
  status: number;
}

export interface IPhoneConnection {
  id: string;
  provider: string;
  state: string;
  phone: string;
}

export interface IEmailConnection {
  id: string;
  email: string;
  login: string;
  smtp_server: string;
  smtp_port: number;
  bindings_count: number;
}

export interface IGetPhoneConnection {
  data: IPhoneConnection[];
  status: number;
}

export interface IGetPhoneConnectionById {
  data: IPhoneConnection;
  status: number;
}

export interface IGetEmailConnections {
  data: IEmailConnection[];
  status: number;
}

export interface IGetEmailConnectionById {
  data: IEmailConnection;
  status: number;
}

export interface IPostPhoneBindingReqeust {
  shop_id: string;
  connection_id: string;
}

export interface IQrData {
  id: string;
  provider: string;
  state: string;
  phone: string;
  bindings_count: number;
  auth_qr_code_base64: string;
}

export interface IGetQrAuthResponse {
  data: IQrData;
  status: number;
}

export interface INotificationsSettings {
  allow_override_for_deal: boolean;
  additional_footer_text: string;
  shop_id: string;
}

// Метод для получения всех магазинов
export async function getShops(search?: string): Promise<IGetShops> {
  return axios
    .get(baseUrl + "shop" + `${search ? `?search=${search}` : ""}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getShops:", err.response);
      return err.response;
    });
}

// Метод для получения конкретного магазина
export async function getShop(shop_id: string): Promise<IGetShops> {
  return axios
    .get(baseUrl + `shop/${shop_id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getShops:", err.response);
      return err.response;
    });
}

// Метод для добавления магазина
export async function addShop(requestShop: IAddShop): Promise<IShop> {
  return axios
    .post(baseUrl + "shop", requestShop, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        id: response.data.id,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> addShop:", err.response);
      return err.response;
    });
}

// Метод для изменения магазина
export async function editShop(shopRequest: IEditShop): Promise<IGetShops> {
  const shop_id = shopRequest.shop_id;
  delete shopRequest.shop_id;

  return axios
    .put(baseUrl + `shop/${shop_id}`, shopRequest, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> editShop:", err.response);
      return err.response;
    });
}

export async function deleteShop(shop_id: string): Promise<IDeleteShop> {
  return axios
    .delete(baseUrl + `shop/${shop_id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> deleteShop:", err.response);
      return err.response;
    });
}

// Метод для получения привязанного телефона конкретного магазина
export async function getPhoneBinding({
  shop_id,
  connection_id,
}: {
  shop_id?: string;
  connection_id?: string;
}): Promise<IGetShopTelephoneById> {
  const shopQueryParam = shop_id ? `shop_id=${shop_id}&` : "";
  const connectionQueryParam = connection_id ? `connection_id=${connection_id}` : "";
  const queryParams = shopQueryParam + connectionQueryParam;

  return axios
    .get(baseUrl5053 + `phone-binding?` + queryParams, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getPhoneBinding:", err.response);
      return err.response;
    });
}

export async function getEmailBinding({
  shop_id,
  connection_id,
}: {
  shop_id?: string;
  connection_id?: string;
}): Promise<IGetEmailBindings> {
  const shopQueryParam = shop_id ? `shop_id=${shop_id}&` : "";
  const connectionQueryParam = connection_id ? `connection_id=${connection_id}` : "";
  const queryParams = shopQueryParam + connectionQueryParam;

  return axios
    .get(baseUrl5056 + `email-binding?` + queryParams, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getEmailBinding:", err.response);
      return err.response;
    });
}

export async function getPhoneConnections(): Promise<IGetPhoneConnection> {
  return axios
    .get(baseUrl5053 + `phone-connection`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getPhoneConnections:", err.response);
      return err.response;
    });
}

export async function getPhoneConnection(id: string): Promise<IGetPhoneConnectionById> {
  return axios
    .get(baseUrl5053 + `phone-connection/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getPhoneConnection:", err.response);
      return err.response;
    });
}

export async function getEmailConnections(): Promise<IGetEmailConnections> {
  return axios
    .get(baseUrl5056 + `email-connection`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getEmailConnections:", err.response);
      return err.response;
    });
}

export async function getEmailConnection(id: string): Promise<IGetEmailConnectionById> {
  return axios
    .get(baseUrl5056 + `email-connection/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getEmailConnection:", err.response);
      return err.response;
    });
}

export async function postPhoneBinding({ shop_id, connection_id }: IPostPhoneBindingReqeust): Promise<any> {
  return axios
    .post(
      baseUrl5053 + "phone-binding",
      { shop_id, connection_id },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> postPhoneBinding:", err.response);
      return err.response;
    });
}

export async function postEmailBinding({ shop_id, connection_id }: IPostPhoneBindingReqeust): Promise<any> {
  return axios
    .post(
      baseUrl5056 + "email-binding",
      { shop_id, connection_id },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> postEmailBinding:", err.response);
      return err.response;
    });
}

export async function deletePhoneBindingById(id: string) {
  return axios
    .delete(baseUrl5053 + `phone-binding/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> deletePhoneBindingById:", err.response);
      return err.response;
    });
}

export async function deleteEmailBindingById(id: string) {
  return axios
    .delete(baseUrl5056 + `email-binding/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> deleteEmailBindingById:", err.response);
      return err.response;
    });
}

export async function deletePhoneConnectionById(id: string) {
  return axios
    .delete(baseUrl5053 + `phone-connection/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> deletePhoneConnectionById:", err.response);
      return err.response;
    });
}

export async function deleteEmailConnectionById(id: string) {
  return axios
    .delete(baseUrl5056 + `email-connection/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> deleteEmailConnectionById:", err.response);
      return err.response;
    });
}

export async function postPhoneConnectionGreenApi({ instance_id, auth_token }: { instance_id: string; auth_token: string }): Promise<any> {
  return axios
    .post(
      baseUrl5053 + "phone-connection/green-api",
      { instance_id, auth_token },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> postPhoneConnectionGreenApi:", err.response);
      return err.response;
    });
}

export async function postEmailConnection({
  email,
  password,
  provider_name,
}: {
  email: string;
  password: string;
  provider_name: string;
}): Promise<any> {
  return axios
    .post(
      baseUrl5056 + `email-connection/${provider_name}`,
      { email, password },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> postEmailConnection:", err.response);
      return err.response;
    });
}

export async function getPhoneAuthQr(connection_id: string): Promise<IGetQrAuthResponse> {
  return axios
    .get(baseUrl5053 + `phone-connection/${connection_id}/auth-qr-code`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getPhoneAuthQr:", err.response);
      return err.response;
    });
}

export async function getShopNotificationSettingsById({
  shop_id,
}: {
  shop_id: string; // Идентификатор магазина
}): Promise<{ data: INotificationsSettings; status: number }> {
  return axios
    .get(baseUrl5050 + `notification-settings/shop/${shop_id}`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов в методе ==> getShopnotificationSettingsById:", err.response);
      return err.response;
    });
}

export async function postShopNotificationSettingsById({
  shop_id,
  allow_override_for_deal,
  additional_footer_text,
}: {
  shop_id: string;
  allow_override_for_deal: boolean;
  additional_footer_text: string;
}): Promise<{ data: INotificationsSettings; status: number }> {
  return axios
    .post(
      baseUrl5050 + `notification-settings/shop/${shop_id}`,
      { allow_override_for_deal, additional_footer_text },
      {
        headers: { Authorization: "Bearer " + Cookies.get("access_token") },
      },
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов в методе ==> postShopNotificationSettingsById:", err.response);
      return err.response;
    });
}
