import React, { Dispatch, ReactElement, useContext, useEffect, useReducer, useState } from "react";
import { useQuery } from "react-query";
import { IStuffUser, getAllStuff, getCurrentUserInfo } from "../services/User";
import { checkAuthenticated } from "../helpers/checkAuth";
import { IShop, getShops } from "../services/Shops";
import { IDepartment, getDepartment } from "../services/Department";

interface IShopsProps {
  children: ReactElement;
}

interface IShopsContext {
  shops: IShop[];
  setShops: React.Dispatch<React.SetStateAction<IShop[]>>;
  allDepartments: IDepartment[];
}

const initialContext: IShopsContext = {
  shops: [],
  setShops: () => {},
  allDepartments: [],
};

const ShopsContext = React.createContext<IShopsContext>(initialContext);

export const useShops = () => {
  return useContext(ShopsContext);
};

export const ShopsProvider: React.FC<IShopsProps> = ({ children }) => {
  const authenticated = checkAuthenticated();

  const [shops, setShops] = useState<IShop[]>([]);
  const [allDepartments, setAllDepartments] = useState<IDepartment[]>([]);

  const { data: shopsData } = useQuery({
    queryFn: () => getShops(),
    queryKey: ["shops"],
    enabled: authenticated,
    onSuccess: (data) => {
      if (!data?.data) {
        return;
      }
      setShops(data.data);
    },
  });

  const { data: allDepartmentsData } = useQuery({
    queryFn: () => getDepartment(),
    queryKey: ["allDepartments"],
    enabled: authenticated,
    onSuccess: (data) => {
      if (!data?.data) {
        return;
      }
      setAllDepartments(data.data);
    },
  });

  return <ShopsContext.Provider value={{ shops, setShops, allDepartments }}>{children}</ShopsContext.Provider>;
};
