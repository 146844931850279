import React, { useEffect, useState } from "react";
import styles from "./DealCardCreate.module.scss";
import InputCustom from "../../../../components/ui/InputCustom/InputCustom";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import "./DealCardCreate_override.scss";
import { isEmailValid, isPhoneValid, isTextValid } from "../../../../helpers/validation";
import { formatPhone } from "../../../../helpers/formatString";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cross.svg";
import Modal from "antd/es/modal/Modal";
import { useMutation } from "react-query";
import { addDeal } from "../../../../services/Deals";
import { message } from "antd";
import Loader from "../../../../components/ui/Loader/Loader";
import content from "../../../../settings/content";
import { NoticeType } from "antd/es/message/interface";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import moment from "moment";
import { IClient, ICreateClient, createClient } from "../../../../services/Clients";
import ClientCard from "./ClientCard/ClientCard";
import { PhoneInput } from "react-international-phone";
import { PHONE_COUNTRIES } from "../../../../settings/countries";
import { useClients } from "../../../../context/useClients";
import SelectClient from "../../../../components/SelectClient/SelectClient";

interface IDealCardCreateProps {
  open: boolean;
  onCancel: () => void;
  updateDealsList: () => void;
  handleSuccess?: (id: string) => void;
  openModalDetail?: (id: string) => any;
}

interface IData {
  client_fullname: string;
  client_email: string;
  client_phone: string;
  renovation_stage: string;
  room_type: string;
  surface_area: 0;
  comment: string;
  estate_name: string;
}

const DealCardCreate: React.FC<IDealCardCreateProps> = ({ open, onCancel, updateDealsList, handleSuccess, openModalDetail }) => {
  const { refetchClients } = useClients();
  const [messageApi, contextHolder] = message.useMessage();

  const { mutateAsync: onAddDeal } = useMutation({
    mutationFn: addDeal,
    onSuccess: () => {
      updateDealsList();
    },
  });

  const { mutateAsync: onCreateClient } = useMutation({
    mutationFn: createClient,
    onSuccess: () => {
      refetchClients();
    },
  });

  const [data, setData] = useState({
    client_fullname: "",
    client_email: "",
    client_phone: "",
    renovation_stage: "",
    room_type: "",
    surface_area: 0,
    comment: "",
    estate_name: "",
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const [selectedClient, setSelectedClient] = useState<IClient>();
  const [isClientCardOpened, setIsClientCardOpened] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => {
    setData({ ...data, [e.target.name as string]: e.target.value });
  };

  const onPhoneChange = (phone: string) => {
    const formatedPhone = formatPhone(phone);
    setData({ ...data, client_phone: formatedPhone });
  };

  useEffect(() => {
    if (!selectedClient) {
      setData((prev) => ({
        ...prev,
        client_fullname: "",
        client_email: "",
        client_phone: "",
      }));
    }
    if (selectedClient) {
      setData((prev) => ({
        ...prev,
        client_fullname: selectedClient.title,
        client_email: selectedClient.persons[0].email ?? "",
        client_phone: selectedClient.persons[0].phone ?? "",
      }));
    }
  }, [selectedClient]);

  // В случае если оба чекбокса сняты, будем блокировать конпку
  // Или если какой-либо поле не прошло валидацию, тоже блокируем
  useEffect(() => {
    if (!isPhoneValid(data.client_phone)) {
      setIsDisabled(true);
      return;
    }
    if (data.client_email && !isEmailValid(data.client_email)) {
      setIsDisabled(true);
      return;
    }
    if (data.client_fullname === "") {
      setIsDisabled(true);
      return;
    }
    setIsDisabled(false);
  }, [data]);

  const handleSubmit = async () => {
    if (selectedClient) {
      onAddDeal({
        client_id: selectedClient.id,
        title: `${data.client_fullname} ${moment().format("DD.MM.YYYY HH:mm")}`,
        renovation_stage: data.renovation_stage,
        room_type: data.room_type,
        surface_area: +data.surface_area,
        comment: data.comment,
        estate_name: data.estate_name,
      }).then((res) => {
        if (!res) {
          return alert("error", content.alert_messages.deals.create.error_deal);
        }
        if (res.status === 200) {
          alert("success", content.alert_messages.deals.create.success_deal);
          resetData();
          <Loader />;
          onCancel();
          if (handleSuccess) {
            handleSuccess(res.id);
          }
          if (openModalDetail) {
            openModalDetail(res.id);
          }
        } else {
          alert("error", content.alert_messages.deals.create.error_deal);
        }
      });
    }
    if (!selectedClient) {
      const clientObj: ICreateClient = {
        title: data.client_fullname,
        type: null,
        persons: [
          {
            fullname: data.client_fullname,
            role: null,
            deleted: false,
            email: data.client_email ? data.client_email.toLowerCase() : null,
            phone:
              data.client_phone && data.client_phone !== "" && data.client_phone !== " " && data.client_phone.length > 8
                ? formatPhone(data.client_phone)
                : null,
          },
        ],
      };

      onCreateClient(clientObj).then((response) => {
        onAddDeal({
          client_id: response.data.id,
          title: `${data.client_fullname} ${moment().format("DD.MM.YYYY HH:mm")}`,
          renovation_stage: data.renovation_stage,
          room_type: data.room_type,
          surface_area: +data.surface_area,
          comment: data.comment,
          estate_name: data.estate_name,
        }).then((res) => {
          if (!res) {
            return alert("error", content.alert_messages.deals.create.error_deal);
          }
          if (res.status === 200) {
            alert("success", content.alert_messages.deals.create.success_deal);
            resetData();
            <Loader />;
            onCancel();
            if (handleSuccess) {
              handleSuccess(res.id);
            }
            if (openModalDetail) {
              openModalDetail(res.id);
            }
          } else {
            alert("error", response.data);
          }
        });
      });
    }
  };

  const resetData = () => {
    setData({
      client_fullname: "",
      client_email: "",
      client_phone: "",
      renovation_stage: "",
      room_type: "",
      surface_area: +"",
      comment: "",
      estate_name: "",
    });
    setSelectedClient(undefined);
  };

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const getDataInput = (index: string) => data[index as keyof IData];

  const refetchClient = (updatedClient: IClient) => {
    setSelectedClient({ ...updatedClient });
    refetchClients();
  };

  const isDataError = (input: any): boolean => {
    if (input.name === "client_email" && !!getDataInput(input.name) && !isEmailValid(String(getDataInput(input.name)))) {
      return true;
    }

    const inputValue = String(getDataInput(input.name));
    if (input.required && input.type === "" && inputValue != "" && !isTextValid(inputValue)) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Modal
        className={"custom_shop_modal"}
        zIndex={99999}
        open={open}
        closeIcon={false}
        onCancel={() => {
          onCancel();
          resetData();
        }}
        centered
        footer={null}
        width={340}
        maskClosable={false}
      >
        {contextHolder}
        <div className={styles.dealCardCreate} id="custom_deal_create_styles">
          <div className={styles.dealCardCreate__modal_header}>
            <span>{content.deals.deal_card_create.title}</span>
            <ButtonCustom isTransparent maxWidth="20px" className={styles.dealCardCreate__modal_close} onClick={() => onCancel()}>
              <CloseIcon />
            </ButtonCustom>
          </div>
          <div className={styles.dealCardCreate__wrapper}>
            <SelectClient setSelectedClient={setSelectedClient} selectedClient={selectedClient} />

            {content.deals.deal_card_create.dealCardInputs.map((input) => {
              return (
                <div key={input.id} className={styles.dealCardCreate__input}>
                  {input.required && !selectedClient && <span className={styles.dealCardCreate__input_warn}>*</span>}
                  {input.type === "mask" ? (
                    <PhoneInput
                      defaultCountry="ru"
                      value={getDataInput(input.name) ? getDataInput(input.name).toString() : ""}
                      onChange={onPhoneChange}
                      placeholder={content.login.form.phone.placeholder}
                      countries={PHONE_COUNTRIES}
                      disabled={!!selectedClient}
                      className={styles.phone__input}
                      name={input.name}
                      forceDialCode
                    />
                  ) : input.type === "" ? (
                    <InputCustom
                      disabled={
                        (input.name === "client_email" && !!selectedClient) || (input.name === "client_fullname" && !!selectedClient)
                      }
                      className={styles.custom_input}
                      placeholder={input.placeholder}
                      name={input.name}
                      value={getDataInput(input.name) !== 0 ? getDataInput(input.name) : undefined}
                      onChange={handleChange}
                      style={{
                        display: input.name === "room_type" ? "none" : "block",
                      }}
                      isError={isDataError(input)}
                    />
                  ) : null}
                </div>
              );
            })}
            <div className={styles.dealCardCreate__footer}>
              <p>
                <span>*</span>
                {content.deals.deal_card_create.hint}
              </p>
              <ButtonCustom maxWidth="190px" className={styles.custom_button} disabled={isDisabled} onClick={handleSubmit}>
                <span>{content.deals.deal_card_create.submit}</span>
              </ButtonCustom>
            </div>
          </div>
        </div>
      </Modal>
      <ClientCard
        isOpen={isClientCardOpened}
        client={selectedClient}
        onCancel={() => setIsClientCardOpened(false)}
        refetchClient={refetchClient}
      />
    </>
  );
};

export default DealCardCreate;
