export function dirUpToPitchYaw(matrix: number[][]): { pitch: number; yaw: number } {
  let yaw: number;
  const dir = [matrix[2][0], matrix[2][1], matrix[2][2]];
  const up = [matrix[1][0], matrix[1][1], matrix[1][2]];

  if (Math.abs(dir[2]) < 0.5) {
    yaw = (Math.atan2(dir[1], -dir[0]) * 180.0) / Math.PI;
  } else if (-dir[2] >= 0.5) {
    yaw = 180.0 + (Math.atan2(-up[1], up[0]) * 180.0) / Math.PI;
  } else {
    yaw = 180.0 + (Math.atan2(up[1], -up[0]) * 180.0) / Math.PI;
  }

  const pitch = (180.0 / Math.PI) * Math.asin(dir[2]);
  return { pitch, yaw };
}
export function extractPitchYawRoll(matrixInitial: number[][]): { pitch: number; yaw: number; roll: number } | undefined {
  // Убедимся, что матрица имеет размер 4x4
  if (matrixInitial.length !== 4 || matrixInitial[0].length !== 4) {
    return;
  }

  const matrix = [...matrixInitial];

  let yaw = -Math.asin(matrix[2][0]);
  const cosYaw = Math.cos(yaw);

  let pitch = -Math.atan2(matrix[2][1] / cosYaw, matrix[2][2] / cosYaw);
  let roll = Math.atan2(matrix[1][0] / cosYaw, matrix[0][0] / cosYaw);

  // Преобразование радианов в градусы
  pitch = pitch * (180 / Math.PI);
  yaw = yaw * (180 / Math.PI);
  roll = roll * (180 / Math.PI);

  // Коррекция углов
  if (roll < -90 || roll > 90) {
    pitch = pitch + 90;
    yaw = -yaw + 90;
    roll = roll + 180;
  } else {
    pitch = pitch - 90;
    yaw = yaw - 180;
    roll = roll;
  }

  return {
    pitch: pitch,
    yaw: yaw,
    roll: roll,
  };
}
