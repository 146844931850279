import React, { useEffect, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./PrivateRoute";
import MainPage from "./pages/MainPage/MainPage";
import DealsPage from "./pages/DealsPage/DealsPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Organization from "./pages/Organization/Organization";
import Panorama from "./pages/Panorama/Panorama";
import PublicGallery from "./pages/PublicGallery/PublicGallery";
import { refreshToken } from "./services/Authorization";
import Cookies from "js-cookie";
import PublicProjectView from "./pages/PublicGallery/PublicProjectView/PublicProjectView";
import {
  INotification,
  delayNotification,
  getRemindersNotifications,
  markNotificationAsNotified,
  markNotificationAsRead,
} from "./services/Reminders";
import { Dropdown, MenuProps, notification } from "antd";
import { useUser } from "./context/useUser";
import { Role } from "./settings/content";
import "./CrmClient.scss";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ru from "dayjs/locale/ru";
import calendar from "dayjs/plugin/calendar";
import ButtonCustom from "./components/ui/ButtonCustom/ButtonCustom";
import { useNotificationsContext } from "./context/useNotifications";
import { useNotificationContext } from "./context/useAntdNotification";
import PanoramaIframe from "./pages/ProjectIframe/PanoramaIframe";
import ProjectItemsIframe from "./pages/ProjectIframe/ProjectItemsIframe";
import LeadsPage from "./pages/LeadsPage/LeadsPage";
import { BlanksPage } from "./pages/BlanksPage/BlanksPage";

dayjs.extend(relativeTime);
dayjs.extend(calendar);

dayjs.locale(ru);

type Props = {
  authenticated: boolean;
  pagePermission: boolean;
};
const Context = React.createContext({ name: "notificationsContext" });

const CrmClient = ({ authenticated, pagePermission }: Props) => {
  const { user } = useUser();
  const { notifications, setNotifications, mailingNotification, setMailingNotification } = useNotificationsContext();

  const { mutateAsync: onReadNotification } = useMutation({
    mutationFn: markNotificationAsRead,
  });
  const { mutateAsync: onCloseNotification } = useMutation({
    mutationFn: markNotificationAsNotified,
  });
  const { mutateAsync: onDelayNotification } = useMutation({
    mutationFn: delayNotification,
  });

  const onNotificationClick = async (notification: INotification, isNeedToRead?: boolean) => {
    if (isNeedToRead) {
      const request = await onReadNotification(notification.id);
      if (request) {
        let url = "";
        if (notification.deal_id) {
          url = `${window.location.origin}/deals?deal_id=${notification.deal_id}`;
        }
        if (notification.lead_id) {
          url = `${window.location.origin}/leads?lead_id=${notification.lead_id}`;
        }
        setNotifications((prevState) => {
          const filteredArr = prevState.filter((item) => item.id !== notification.id);
          return filteredArr;
        });
        /// костылёк, из-за того, что на этом уровне нет доступа к реакт-роутеру
        window.location.href = url;
      }
    } else if (!isNeedToRead) {
      let url = "";
      if (notification.deal_id) {
        url = `${window.location.origin}/deals?deal_id=${notification.deal_id}`;
      }
      if (notification.lead_id) {
        url = `${window.location.origin}/leads?lead_id=${notification.lead_id}`;
      }
      setMailingNotification([]);
      /// костылёк, из-за того, что на этом уровне нет доступа к реакт-роутеру
      window.location.href = url;
    }
  };

  const { api, contextHolder } = useNotificationContext();
  const contextValue = useMemo(() => ({ name: "NotificationsContext" }), []);

  const openNotification = (notifcation: INotification) => {
    const notificationType: string = (() => {
      if (notifcation.isSuccess === false) {
        return "error";
      }
      return "info";
    })();
    api[notificationType]({
      onClose: () => {
        if (!notifcation.id || notifcation.id === "") {
          return;
        }
        onCloseNotification(notifcation.id);
      },
      message: (
        <div className="notification-title-block">
          <span className="notification-title-name" style={{ color: notifcation.isSuccess === false ? "inherit" : "black" }}>
            {notifcation.id ? "Напоминание" : notifcation.isSuccess ? "Сделка завершена" : "Ошибка отправления"}
          </span>
          <span className="notification-title-time">
            {dayjs(notifcation.postponed_until ? notifcation.postponed_until : notifcation.notify_at).calendar(null, {
              sameDay: "[Сегодня в] HH:mm", // Тот же день ( Today at 2:30 AM )
              nextDay: "[Завтра в] HH:mm", // Следующий день ( Tomorrow at 2:30 AM )
              nextWeek: "dddd", // Следующая неделя ( Sunday at 2:30 AM )
              lastDay: "[Вчера в] HH:mm", // Накануне ( Yesterday at 2:30 AM )
              lastWeek: "[На прошлой неделе]", // Прошлая неделя ( Last Monday at 2:30 AM )
              sameElse: "DD/MM/YYYY HH:mm", // Все остальное ( 7/10/2011 )
            })}
          </span>
        </div>
      ),
      description: (
        <Context.Consumer>
          {() => {
            const items: MenuProps["items"] = [
              {
                key: "hour",
                label: (
                  <ButtonCustom
                    onClick={() => {
                      onDelayNotification({ notificationId: notifcation.id, minutes: 60 }).then(() => {
                        setNotifications((prevState) => {
                          const filteredArr = prevState.filter((item) => item.id !== notifcation.id);
                          return filteredArr;
                        });
                        api.destroy(notifcation.id);
                      });
                    }}
                    className="dropdown-btn"
                    maxWidth="100%"
                    isTransparent
                  >
                    <span>1 час</span>
                  </ButtonCustom>
                ),
              },
              {
                key: "halfhour",
                label: (
                  <ButtonCustom
                    onClick={() => {
                      onDelayNotification({ notificationId: notifcation.id, minutes: 30 }).then(() => {
                        setNotifications((prevState) => {
                          const filteredArr = prevState.filter((item) => item.id !== notifcation.id);
                          return filteredArr;
                        });
                        api.destroy(notifcation.id);
                      });
                    }}
                    className="dropdown-btn"
                    maxWidth="100%"
                    isTransparent
                  >
                    <span>30 минут</span>
                  </ButtonCustom>
                ),
              },
              {
                key: "tenMinutes",
                label: (
                  <ButtonCustom
                    onClick={() => {
                      onDelayNotification({ notificationId: notifcation.id, minutes: 10 }).then(() => {
                        setNotifications((prevState) => {
                          const filteredArr = prevState.filter((item) => item.id !== notifcation.id);
                          return filteredArr;
                        });
                        api.destroy(notifcation.id);
                      });
                    }}
                    className="dropdown-btn"
                    maxWidth="100%"
                    isTransparent
                  >
                    <span>10 минут</span>
                  </ButtonCustom>
                ),
              },
              {
                key: "fiveMinutes",
                label: (
                  <ButtonCustom
                    onClick={() => {
                      onDelayNotification({ notificationId: notifcation.id, minutes: 5 }).then((response) => {
                        setNotifications((prevState) => {
                          const filteredArr = prevState.filter((item) => item.id !== notifcation.id);
                          return filteredArr;
                        });
                        api.destroy(notifcation.id);
                      });
                    }}
                    className="dropdown-btn"
                    maxWidth="100%"
                    isTransparent
                  >
                    <span>5 минут</span>
                  </ButtonCustom>
                ),
              },
            ];
            return (
              <div className="notification-text-block">
                <div className="notification-text">{`${notifcation.comment}`}</div>
                <div className="notification-buttons">
                  {notifcation.id !== "" && (
                    <ButtonCustom className="notification-btn" maxWidth="fit-content">
                      <Dropdown menu={{ items }} overlayStyle={{ zIndex: 3000 }} placement="top">
                        <span>Отложить</span>
                      </Dropdown>
                    </ButtonCustom>
                  )}
                  <ButtonCustom
                    onClick={() => onNotificationClick(notifcation, notifcation.id !== "")}
                    className="notification-btn"
                    maxWidth="fit-content"
                  >
                    <span>К {notifcation.deal_id ? "сделке" : notifcation.lead_id ? "лиду" : ""}</span>
                  </ButtonCustom>
                </div>
              </div>
            );
          }}
        </Context.Consumer>
      ),
      placement: "bottomRight",
      duration: null,
      key: notifcation.id,
    });
  };
  const { data: userNotifications } = useQuery({
    queryFn: () => getRemindersNotifications(),
    queryKey: ["userNotifications"],
    enabled: authenticated && user.role === Role.DEPARTMENT_EMPLOYEE,
    refetchInterval: 30000,
  });
  const { mutateAsync: resetToken } = useMutation({
    mutationFn: refreshToken,
  });

  useEffect(() => {
    const intervalId: NodeJS.Timeout = setInterval(() => {
      const token = Cookies.get("refresh_token");
      const accToken = Cookies.get("access_token");

      if (token && accToken) {
        resetToken({ access_token: accToken, refresh_token: token });
      }
    }, 240000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (userNotifications?.data) {
      setNotifications(userNotifications.data);
    }
  }, [userNotifications]);

  useEffect(() => {
    const reversedNotifications = [...notifications].reverse();
    if (mailingNotification.length) {
      reversedNotifications.unshift(mailingNotification[0]);
    }
    reversedNotifications.forEach((item) => {
      openNotification(item);
    });
  }, [notifications, mailingNotification]);

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <BrowserRouter>
        <Switch>
          <ProtectedRoute isAuthenticated={!authenticated} authenticationPath={"/"} path={"/signin"} exact component={LoginPage} />
          <ProtectedRoute isAuthenticated={!authenticated} authenticationPath={"/"} path={"/reset-password"} component={ResetPassword} />
          <Route path={"/panorama-iframe/:id"} component={PanoramaIframe} />
          <Route path={"/project-items-iframe/:id"} component={ProjectItemsIframe} />
          <ProtectedRoute isAuthenticated={authenticated} authenticationPath={"/signin"} path={"/"} exact component={MainPage} />
          <ProtectedRoute isAuthenticated={authenticated} authenticationPath={"/signin"} path={"/deals"} exact component={DealsPage} />
          <ProtectedRoute isAuthenticated={authenticated} authenticationPath={"/signin"} path={"/leads"} exact component={LeadsPage} />
          <ProtectedRoute isAuthenticated={authenticated} authenticationPath={"/signin"} path={"/blanks"} exact component={BlanksPage} />
          <ProtectedRoute
            isAuthenticated={pagePermission}
            authenticationPath={"/signin"}
            path={"/organization"}
            exact
            component={Organization}
          />
          <Route path={"/panorama-view/:id/pano/:pano"} exact component={Panorama} />
          <Route path={"/view-project/:id"} exact component={PublicProjectView} />
          <Route path={"/public-gallery/:id"} exact component={PublicGallery} />
        </Switch>
      </BrowserRouter>
    </Context.Provider>
  );
};

export default CrmClient;
