import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { IProjectItem, getProjectItems, getPublicProjectItems } from "../../../services/Projects";
import styles from "./GalleryCardItems.module.scss";
import "./GalleryCardItems_override.scss";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow.svg";
import { Image, Carousel } from "antd";
import { useParams } from "react-router-dom";

type Props = {
  selectedProjectId: string;
  isPublicGallery?: boolean;
  dealStatus?: string;
};

const GalleryCardItems: React.FC<Props> = ({ selectedProjectId, isPublicGallery, dealStatus }) => {
  const { id }: any = useParams();

  const { data: projectItemsData } = useQuery({
    queryFn: () => (isPublicGallery ? getPublicProjectItems(id, selectedProjectId) : getProjectItems(selectedProjectId)),
    queryKey: ["projectItems"],
  });

  const [projectItems, setProjectItems] = useState<IProjectItem[]>();

  useEffect(() => {
    if (!projectItemsData) {
      return;
    }
    setProjectItems(projectItemsData.data);
  }, [projectItemsData]);

  // Если нет панорам для выбранного проекта, ничего не отрисовываем
  if (!projectItems?.length) {
    return null;
  }

  return (
    <div className={styles.galleryProjectItems}>
      <Carousel
        className="custom-slider"
        slidesToShow={4}
        arrows
        dots={false}
        infinite={false}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            },
          },
        ]}
        nextArrow={<ArrowIcon />}
        prevArrow={<ArrowIcon />}
      >
        {projectItems &&
          Array.isArray(projectItems) &&
          projectItems?.map((item) => {
            return (
              <div key={item.id} className={styles.galleryProjectItems__item}>
                <Image
                  className="custom-image"
                  fallback={`https://update.ceramic3d.ru/getResource.php?aes=1&preview=1&guid=${item.resource_id}`}
                />
                <p className={styles.galleryProjectItems__name}>{item.name}</p>
                <p className={styles.galleryProjectItems__vendor_code}>Артикул: {item.vendor_code}</p>
                <p className={styles.galleryProjectItems__collection}>
                  {item.collection} {item.manufacturer}
                  {/* {item.square.toFixed(2) === "0.00" ? null : item.square.toFixed(2) */}
                </p>
                {/* <p className={styles.galleryProjectItems__price}>999р.</p> */}
              </div>
            );
          })}
      </Carousel>
    </div>
  );
};
export default GalleryCardItems;
