import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import { checkAuthenticated } from "./helpers/checkAuth";
import { getPermittedPages } from "./helpers/checkPagePermission";
import { PageName } from "./settings/content";
import { UserProvider } from "./context/useUser";
import CrmClient from "./CrmClient";
import { CitiesProvider } from "./context/useCities";
import { NotificationsProvider } from "./context/useNotifications";
import { NotificationProvider } from "./context/useAntdNotification";
import { ShopsProvider } from "./context/useShops";
import { WorkstationsProvider } from "./context/useWorkstations";
import { ClientsProvider } from "./context/useClients";
import { LeadsProvider } from "./context/useLeads";
import { SourcesProvider } from "./context/useSources";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cacheTime: 5000,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const authenticated = checkAuthenticated();
  const [pagePermission, setPagePermission] = useState<boolean>(authenticated);

  useEffect(() => {
    getPermittedPages().then((data) => {
      if (!data) {
        return;
      }
      setPagePermission(authenticated && data.includes(PageName.organization_page));
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <NotificationProvider>
        <NotificationsProvider>
          <ShopsProvider>
            <CitiesProvider>
              <UserProvider>
                <WorkstationsProvider>
                  <ClientsProvider>
                    <LeadsProvider>
                      <SourcesProvider>
                        <CrmClient authenticated={authenticated} pagePermission={pagePermission} />
                      </SourcesProvider>
                    </LeadsProvider>
                  </ClientsProvider>
                </WorkstationsProvider>
              </UserProvider>
            </CitiesProvider>
          </ShopsProvider>
        </NotificationsProvider>
      </NotificationProvider>
    </QueryClientProvider>
  );
}

export default App;
