import { useEffect, useState } from "react";
import styles from "./InputCustom.module.scss";
import InputCustom from "./InputCustom";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/cross.svg";
import { ReactComponent as OkIcon } from "../../../assets/icons/check_outlined.svg";

type Props = {
  width?: string;
  isBoldText?: boolean;
  inputName: string;
  inputPlaceholder: string;
  inputValue: string;
  isInputError?: boolean;
  inputDisabled?: boolean;
  id: string;
  onSubmit: ({ id, value }: { id: string; value: string }) => Promise<{ status: number; data: any }>;
  maxLength?: number;
};

const InputWithViewMode = ({
  width,
  isBoldText,
  inputName,
  inputPlaceholder,
  inputValue,
  inputDisabled,
  isInputError,
  onSubmit,
  id,
  maxLength,
}: Props) => {
  const [value, setValue] = useState(inputValue);
  const [isEditing, setisEditing] = useState(false);

  useEffect(() => {
    if (inputValue) {
      setValue(inputValue);
    }
  }, [inputValue]);

  return (
    <div className={styles.InputWithViewMode} style={{ maxWidth: width ?? "100%" }}>
      {isEditing ? (
        <div className={styles.InputWithViewMode__input_block}>
          <InputCustom
            type={"text"}
            className={styles.InputWithViewMode__input}
            name={inputName}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={value}
            placeholder={inputPlaceholder}
            isError={isInputError}
            disabled={inputDisabled}
            maxLength={maxLength}
          />
          <ButtonCustom
            isTransparent
            className={styles.InputWithViewMode__close_btn}
            onClick={() => {
              setisEditing(false);
              setValue(inputValue);
            }}
            maxWidth="25px"
          >
            <CloseIcon />
          </ButtonCustom>
          <ButtonCustom
            isTransparent
            className={styles.InputWithViewMode__submit_btn}
            onClick={() => {
              onSubmit({ id, value }).then((response) => {
                if (response.status === 200) {
                  setisEditing(false);
                  setValue(response.data.name);
                }
              });
            }}
            maxWidth="25px"
          >
            <OkIcon />
          </ButtonCustom>
        </div>
      ) : (
        <div className={styles.InputWithViewMode__viewValue_block}>
          <span
            style={{ maxWidth: width }}
            className={`${styles.InputWithViewMode__viewValue} ${isBoldText && styles.InputWithViewMode__viewValue_bold}`}
          >
            {value}
          </span>
          <ButtonCustom
            isTransparent
            className={styles.InputWithViewMode__edit_btn}
            onClick={() => {
              setisEditing(true);
            }}
            maxWidth="20px"
          >
            <EditIcon />
          </ButtonCustom>
        </div>
      )}
    </div>
  );
};

export default InputWithViewMode;
