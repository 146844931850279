import React, { useEffect, useState } from "react";
import styles from "./StuffCard.module.scss";
import "./StuffCard_override.scss";
import { useQuery } from "react-query";
import { IGetAllStuffResponse, IStuffUser, IUserRole, getUserRoles } from "../../../../services/User";
import Loader from "../../../../components/ui/Loader/Loader";
import { IGetShops, IShop } from "../../../../services/Shops";
import { IDepartment } from "../../../../services/Department";
import { Table, TableProps } from "antd";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import ValidateComponentByRole from "../../../../components/ValidateComponentByRole/ValidateComponentByRole";
import { allRolesWithLabels, onlyAdmin, Role } from "../../../../settings/content";
import { compareStuffForSorting } from "./compareStuffForSorting";
import { tableLocale } from "../../../../helpers/tableLocale";
import { useShops } from "../../../../context/useShops";

interface IProps {
  stuff: IGetAllStuffResponse;
  isLoading: boolean;
  shops: IGetShops;
  departments: IDepartment[];
  setIsOpen: React.Dispatch<React.SetStateAction<string>>;
}

interface DataType {
  name: string;
  email: string;
  phone: string;
  shop: string;
  shopId: string;
  department: string;
  departmentId: string;
  role: string;
  roleValue: string;
  status: string | null;
}

const StuffCardList: React.FC<IProps> = ({ stuff, isLoading, setIsOpen, departments, shops }) => {
  const { shops: shopsFromContext, allDepartments } = useShops();

  const [currentShopIdFilter, setCurrentShopIdFilter] = useState<string[]>([]);
  const [stuffData, setStuffData] = useState<IStuffUser[]>([]);
  const [shopsData, setShopsData] = useState<IShop[]>([]);
  const [departmentsData, setDepartmentsData] = useState<IDepartment[]>([]);
  const [rolesList, setRolesList] = useState<IUserRole[]>([]);

  const { data: userRoles } = useQuery({
    queryFn: () => getUserRoles(),
    queryKey: ["roles"],
    onSuccess: (data) => {
      setRolesList(data.data);
    },
  });

  useEffect(() => {
    if (shops?.data) {
      setShopsData(shops.data);
    }
  }, [shops]);

  useEffect(() => {
    if (departments) {
      setDepartmentsData(departments);
    }
  }, [departments]);

  useEffect(() => {
    if (stuff?.data && Array.isArray(stuff.data)) {
      const sortedData = [...stuff.data].sort(compareStuffForSorting);

      setStuffData(sortedData);
    }
  }, [stuff]);

  const editStuffHandler = (e: React.MouseEvent<HTMLDivElement>, stuff: IStuffUser) => {
    e.stopPropagation();
    setIsOpen(stuff.id);
  };

  if ((!stuff?.data && isLoading) || !shops || !departments) {
    return <Loader />;
  }

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Имя Фамилия",
      dataIndex: "name",
      key: "name",
      width: "16%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "20%",
    },
    {
      title: "Номер телефона",
      dataIndex: "phone",
      key: "phone",
      width: "14%",
    },
    {
      title: "Магазин",
      dataIndex: "shop",
      key: "shop",
      width: "18%",
      filters: shopsFromContext.map((item) => ({ text: item.name, value: item.id })),
      onFilter: (value, record) => {
        return record.shopId?.indexOf(value as string) === 0;
      },
      filterSearch: true,
    },
    {
      title: "Отдел",
      dataIndex: "department",
      key: "department",
      width: "18%",
      filters:
        currentShopIdFilter.length > 0
          ? allDepartments
              .filter((item) => currentShopIdFilter.includes(item.shop_id))
              .map((item) => ({ text: `${item?.name} ${shopsData.find((shop) => shop.id === item.shop_id)?.address}`, value: item.id }))
          : allDepartments.map((item) => ({
              text: `${item?.name} ${shopsData.find((shop) => shop.id === item.shop_id)?.address}`,
              value: item.id,
            })),
      onFilter: (value, record) => record.departmentId?.indexOf(value as string) === 0,
      filterSearch: true,
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      width: "18%",
      filters: allRolesWithLabels.map((item) => ({ text: item.title, value: item.value })),
      onFilter: (value, record) => {
        return record.roleValue.indexOf(value as string) === 0;
      },
      filterSearch: true,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: "12%",
      filters: [
        {
          text: "Активен",
          value: "active",
        },
        {
          text: "Заблокирован",
          value: "blocked",
        },
      ],
      onFilter: (value, record) => {
        if (value === "active") {
          return record.status === null;
        } else if (value === "blocked") {
          return record.status !== null;
        }
        return true;
      },
      render: (text) => <span style={{ color: text ? "red" : "green" }}>{text ? "Заблокирован" : "Активен"}</span>,
    },
    {
      title: "",
      dataIndex: "button",
      key: "button",
      width: "6%",
    },
  ];
  const handleChange = (pagination: any, filters: any) => {
    setCurrentShopIdFilter(filters.shop ?? []); // Обновляем состояние с выбранными фильтрами
  };

  return (
    <>
      {departments && shops && stuff && (
        <Table
          id="stuff-list-table"
          columns={columns}
          dataSource={stuffData.map((stuff) => {
            const department = departmentsData.find((item) => item.id === stuff.department_id);
            const shop = shopsData.find((item) => item.id === stuff.shop_id);
            return {
              key: stuff.id,
              name: stuff.name,
              email: stuff.email,
              phone: stuff.phone,
              shop: Array.isArray(shopsData) ? (shop?.name ?? "---") : "---",
              shopId: stuff.shop_id,
              department: Array.isArray(departmentsData) ? (department?.name ?? "---") : "---",
              departmentId: stuff.department_id,
              role: rolesList.find((item) => item.id === stuff.role)?.name ?? "---",
              roleValue: stuff.role,
              statusRender: (
                <span style={{ color: stuff.deactivated_at ? "red" : "green" }}>{stuff.deactivated_at ? "Заблокирован" : "Активен"}</span>
              ),
              status: stuff.deactivated_at,
              button: (
                <ValidateComponentByRole requiredRoles={onlyAdmin}>
                  {stuff.role !== Role.ORGANIZATION_ADMIN && (
                    <div onClick={(e) => editStuffHandler(e, stuff)} className={styles.stuffCard__btn}>
                      <EditIcon />
                    </div>
                  )}
                </ValidateComponentByRole>
              ),
            };
          })}
          pagination={{ defaultPageSize: 15, position: ["bottomCenter"], showSizeChanger: false }}
          locale={tableLocale}
          key={"stuff-list-table"}
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default StuffCardList;
