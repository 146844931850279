export enum LeadStatuses {
  NEW = "new",
  IN_PROCESS = "in_process",
  CLOSED_WITH_SUCCESS = "closed_with_success",
  CLOSED_WITH_NO_SUCCESS = "closed_with_no_success",
}

export const statuses = [
  {
    id: LeadStatuses.NEW,
    value: LeadStatuses.NEW,
    disabled: false,
    name: "Новый",
  },
  {
    id: LeadStatuses.IN_PROCESS,
    value: LeadStatuses.IN_PROCESS,
    disabled: false,
    name: "Приглашён",
  },
  {
    id: LeadStatuses.CLOSED_WITH_SUCCESS,
    value: LeadStatuses.CLOSED_WITH_SUCCESS,
    disabled: false,
    name: "Успешно закрыт",
  },
  {
    id: LeadStatuses.CLOSED_WITH_NO_SUCCESS,
    value: LeadStatuses.CLOSED_WITH_NO_SUCCESS,
    disabled: false,
    name: "Закрыт с ошибками",
  },
];

export const firstStagesId = [LeadStatuses.NEW, LeadStatuses.IN_PROCESS];
