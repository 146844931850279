import { DatePicker } from "antd";
import { useState } from "react";
import styles from "./AnalyticsByActiveDays.module.scss";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/ru_RU";
import ButtonCustom from "../../ui/ButtonCustom/ButtonCustom";
import content from "../../../settings/content";
import { useUser } from "../../../context/useUser";
import AnalyticsByActiveDaysTable from "./AnalyticsByActiveDaysTable";

type Props = {};

const AnalyticsByActiveDays = (props: Props) => {
  const { user } = useUser();

  const [dateFrom, setDateFrom] = useState(dayjs().subtract(30, "day").format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(dayjs().format("YYYY-MM-DD"));
  const [selectedInterval, setSelectedInterval] = useState<{ dateFrom: string; dateTo: string }>({ dateFrom, dateTo });
  return (
    <div className={styles.AnalyticsByActiveDays}>
      <div className={styles.AnalyticsByActiveDays__block}>
        <div className={styles.AnalyticsByActiveDays__block_date}>
          <DatePicker
            defaultValue={dayjs().subtract(30, "day")}
            className={styles.AnalyticsByActiveDays__block_picker}
            locale={locale}
            onChange={(dateString) => setDateFrom(dayjs(dateString).format("YYYY-MM-DD"))}
          />
          <DatePicker
            defaultValue={dayjs()}
            className={styles.AnalyticsByActiveDays__block_picker}
            locale={locale}
            onChange={(dateString) => setDateTo(dayjs(dateString).format("YYYY-MM-DD"))}
          />
          <ButtonCustom
            className={styles.AnalyticsByActiveDays__block_btn}
            maxWidth="60px"
            onClick={() => setSelectedInterval({ dateFrom, dateTo })}
            disabled={false}
          >
            <span>{content.control.projects.search_btn}</span>
          </ButtonCustom>
          <ButtonCustom className={styles.AnalyticsByActiveDays__block_btn_filters} maxWidth="120px" onClick={() => null} disabled={true}>
            <span>Скачать Excel</span>
          </ButtonCustom>
        </div>
      </div>
      <div className={styles.AnalyticsByActiveDays__table_wrapper}>
        <AnalyticsByActiveDaysTable selectedInterval={selectedInterval} />
      </div>
    </div>
  );
};

export default AnalyticsByActiveDays;
