import React, { ReactElement, useState } from "react";
import { Upload, UploadProps, message } from "antd";
import "./UploadCustom_override.scss";
import { baseUrl5052, headers } from "../../../env";

type Props = {
  typesOfFiles: string[];
  children: ReactElement;
  uploadedFiles: ([]) => void;
  numberFilesToUpload?: number;
  handleAcceptedTypes: (toggle: boolean) => void;
  maxSizeKb: number;
};

const UploadCustom: React.FC<Props> = ({ children, typesOfFiles, uploadedFiles, numberFilesToUpload, handleAcceptedTypes, maxSizeKb }) => {
  const [error, setError] = useState(false);

  const props: UploadProps = {
    name: "file",
    action: `${baseUrl5052}file`,
    headers: headers,
    beforeUpload: (file) => {
      const isAcceptedSzie = file.size / 1024 <= maxSizeKb; // Ограничение на размер файла - 1 Мб

      const types = typesOfFiles;
      const isAccepted = types.includes(file.type) && isAcceptedSzie;
      if (!isAccepted) {
        message.error(`${file.name} данный файл не поддерживается либо его больше 1мб`);
        handleAcceptedTypes(false);
        setError(true);
        return false;
      }
      handleAcceptedTypes(true);
      setError(false);
      return false;
    },
    onChange(info) {
      const types = typesOfFiles;
      const isAccepted = types.includes(info.file.type as string);
      if (!isAccepted) {
        return;
      }
      const isErorr = info.fileList.map((i) => i.status === "error");
      uploadedFiles(!isErorr ? [] : info?.fileList);
      setError(false);
    },
    onRemove() {
      setError(false);
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  return (
    <Upload maxCount={numberFilesToUpload} {...props} className={error ? "upload-error" : ""}>
      {children}
    </Upload>
  );
};

export default UploadCustom;
