import React, { useEffect, useState } from "react";
import styles from "./AddBlank.module.scss";
import ButtonCustom from "../../ui/ButtonCustom/ButtonCustom";
import content from "../../../settings/content";
import DatePickerCustom, { IPeriod } from "../../ui/DatePickerCustom/DatePickerCutsom";
import UploadCustom from "../../ui/UploadCustom/UploadCustom";
import { uploadFile } from "../../../services/FileController";
import { useMutation } from "react-query";
import { addBlank } from "../../../services/Blanks";
import { NoticeType } from "antd/es/message/interface";
import { message } from "antd";
import Loader from "../../ui/Loader/Loader";

type Props = {
  handleCloseModal: (isOpen: boolean, type: string) => void;
  updateBlanks: () => void;
};

const AddBlank: React.FC<Props> = ({ handleCloseModal, updateBlanks }) => {
  const [date, setDate] = useState<IPeriod>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [uploadedFile, setUploadedFile] = useState<FormData>();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [isFileAccepted, setIsFileAccepted] = useState(true);
  const [errorBlank, setErrorBlank] = useState(false);

  const { mutateAsync: onUploadFile } = useMutation({
    mutationFn: uploadFile,
  });

  const { mutateAsync: onAddBlank } = useMutation({
    mutationFn: addBlank,
  });

  const getDate = (data: IPeriod) => setDate(data);

  useEffect(() => {
    if (!date?.to || !date.from || !uploadedFile || !isFileAccepted) {
      setIsDisabled(true);
      return;
    }
    setIsDisabled(false);
  }, [date, uploadedFile, isFileAccepted]);

  const handleAcceptedTypes = (toggle: boolean) => setIsFileAccepted(toggle);

  // Для создания бланка нам нужен путь к файлу.
  // Бывает так что файл мы загрузили, а сам бланк не удалось
  // из-за того что пападаем в существующий диапазон дат.
  // Скорее всего нужен какой-то метод, чтобы мы могли удалять файл с бека.
  // Но запрос на отправку файла все равно будет лететь.
  const handleBlankToAdd = async () => {
    if (!date || !uploadedFile) {
      return;
    }
    setButtonLoader(true);
    onUploadFile(uploadedFile)
      .then((response) => {
        if (response.status === 200) {
          return response;
        } else {
          setButtonLoader(false);
          throw new Error("Не удалось загрузить файл");
        }
      })
      .then((file) => {
        onAddBlank({
          file_id: file?.id,
          start: date?.from,
          end: date?.to,
        })
          .then((data) => {
            // В будущем нужно переделать. Так как нет ответа с сервера об ошибке
            // сейчас прилетает только ошибка 500.
            if (data.status === 500) {
              setErrorBlank(true);
              alert("error", content.alert_messages.add_blank.error_blank);
              setButtonLoader(false);
              return;
            }
            updateBlanks();
            setButtonLoader(false);
            alert("success", content.alert_messages.add_blank.success_blank);
            // Поставил тайм аут на закрытие, чтобы модалка об успешном
            // добавлении бланка не исчезала очень быстро
            setTimeout(() => handleCloseModal(false, ""), 1500);
            setErrorBlank(false);
          })
          .catch(() => {
            setButtonLoader(false);
            throw new Error("Не удалось создать документ");
          });
      })
      .catch(() => (setButtonLoader(false), alert("error", content.alert_messages.add_blank.error_blank)));
  };

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  // Преобразуем прикрепленный файл в FormData
  // На данный момент можно отправить только один файл
  const uploadedFilesHandler = async (files: any) => {
    if (!files.length) {
      return;
    }
    const formData = new FormData();
    formData.append(files[0].name, files[0].originFileObj);
    setUploadedFile(formData);
  };

  return (
    <div className={styles.addBlank}>
      {contextHolder}
      <div className={styles.addBlank__block}>
        <div className={styles.addBlank__content}>
          <span>{content.blanks.add_modal.block_1.index}</span>
          <span>{content.blanks.add_modal.block_1.text}</span>
        </div>
        <UploadCustom
          handleAcceptedTypes={handleAcceptedTypes}
          uploadedFiles={uploadedFilesHandler}
          numberFilesToUpload={1}
          typesOfFiles={["image/png", "image/jpeg", "image/jpg"]}
          maxSizeKb={1024}
        >
          <ButtonCustom maxWidth="200px" className={styles.addBlank__download_btn}>
            <span>{content.blanks.add_modal.block_1.download}</span>
          </ButtonCustom>
        </UploadCustom>
      </div>
      <div className={styles.addBlank__block}>
        <div className={styles.addBlank__content}>
          <span>{content.blanks.add_modal.block_2.index}</span>
          <span>{content.blanks.add_modal.block_2.text}</span>
        </div>
        <div className={styles.addBlank__date}>
          <DatePickerCustom format="YYYY-MM-DD" getDate={getDate} />
        </div>
      </div>
      {errorBlank && <span className={styles.addBlank__blank_hint}>{content.blanks.add_modal.hint}</span>}
      <div className={styles.addBlank__send}>
        <ButtonCustom maxWidth="200px" disabled={isDisabled} className={styles.addBlank__send_btn} onClick={() => handleBlankToAdd()}>
          <>
            <span>{content.blanks.add_modal.send}</span>
            {buttonLoader && <Loader />}
          </>
        </ButtonCustom>
      </div>
    </div>
  );
};

export default AddBlank;
