import { Checkbox, message } from "antd";
import styles from "./StatusesSettings.module.scss";
import "./StatusesSettings_override.scss";
import content from "../../settings/content";
import { useMutation, useQuery } from "react-query";
import { IDealStatusResponse, editStatus, getDealStatuses } from "../../services/Deals";
import { useEffect, useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { NoticeType } from "antd/es/message/interface";
import ButtonCustom from "../ui/ButtonCustom/ButtonCustom";

interface IProps {
  closeModal: () => void;
  refetchDeals: () => void;
  refetchDealStatuses: () => void;
}

const StatusesSettings = ({ closeModal, refetchDeals, refetchDealStatuses }: IProps) => {
  const [messageApi, contextHolder] = message.useMessage();

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const [statuses, setStatuses] = useState<IDealStatusResponse[]>([]);

  const { data: statusesData } = useQuery({
    queryFn: () => getDealStatuses(),
    queryKey: ["statuses"],
  });

  const { mutateAsync: onEditStatus } = useMutation({
    mutationFn: editStatus,
  });

  useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData);
    }
  }, [statusesData]);

  const onChange = (e: CheckboxChangeEvent) => {
    const index = statuses.findIndex((status) => status.value === e.target.name);
    if (index !== -1) {
      const updatedStatus = { ...statuses[index], disabled: !e.target.checked };
      const newState = [...statuses.slice(0, index), updatedStatus, ...statuses.slice(index + 1)];
      setStatuses(newState);
    }
  };

  const onSubmit = () => {
    onEditStatus({ statuses: statuses.map((item) => ({ id: item.id, disabled: item.disabled })) }).then((response) => {
      if (response.status === 200) {
        alert("success", content.alert_messages.status.success_status_edit);
        refetchDealStatuses();
        refetchDeals();
      } else {
        alert("error", content.alert_messages.status.error_status_edit);
      }
    });
  };

  return (
    <div className={styles.statusesSettings}>
      {contextHolder}
      <div className={styles.statusesSettings__hint}>{content.statuses_settings.hint}</div>

      <div className={styles.statusesSettings__data}>
        <div className="custom_styles">
          {statuses
            .map((item) => ({
              label: item?.name ? item.name : item.value,
              value: item.value,
              disabled: item.disabled,
              isEditable: item.editable,
            }))
            .map((item) => (
              <Checkbox
                key={item.label}
                name={item.value as string}
                value={item.disabled}
                checked={!item.disabled}
                onChange={onChange}
                disabled={item.isEditable ? false : true}
                style={{ display: "flex", alignItems: "center" }}
              >
                {item.label}
              </Checkbox>
            ))}
        </div>
      </div>
      <div className={styles.statusesSettings__buttons}>
        <ButtonCustom maxWidth="calc(50% - 10px)" onClick={() => closeModal()} bgColor={"gray"}>
          <span>Отмена</span>
        </ButtonCustom>
        <ButtonCustom maxWidth="calc(50% - 10px)" onClick={onSubmit} bgColor={"blue"}>
          <span>Применить</span>
        </ButtonCustom>
      </div>
    </div>
  );
};

export default StatusesSettings;
