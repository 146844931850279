import { Table, TableProps } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { IMyLesson, getMyLessons } from "../../../services/Lessons";
import dayjs from "dayjs";
import { onlyEmployee, Role } from "../../../settings/content";
import { useUser } from "../../../context/useUser";

interface DataType {
  user_name: string;
  finished_at: string;
  test: string;
  name: string;
  trainer_name: string;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "ФИО",
    dataIndex: "user_name",
    key: "user_name",
  },
  {
    title: "Дата прохождения урока",
    dataIndex: "finished_at",
    key: "finished_at",
  },
  //   {
  //     title: "Дата назначенного урока",
  //     dataIndex: "test",
  //     key: "test",
  //   },
  {
    title: "Название урока",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Тренер",
    dataIndex: "trainer_name",
    key: "trainer_name",
  },
];

type Props = {
  selectedInterval: { dateFrom: string; dateTo: string };
};

const MyTrainingTable = ({ selectedInterval }: Props) => {
  const { user } = useUser();

  const [myLessons, setMyLessons] = useState<IMyLesson[]>([]);
  const { data: myLessonsData } = useQuery({
    queryFn: () => getMyLessons(selectedInterval),
    queryKey: [`myLessons_${selectedInterval.dateFrom}/${selectedInterval.dateTo}`],
    onSuccess: (data) => {
      if (!Array.isArray(data.data)) {
        return;
      }
      setMyLessons(data.data);
    },
    enabled: onlyEmployee.includes(user.role as Role),
  });

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Table
        id="table"
        columns={columns}
        dataSource={myLessons.map((item) => ({
          user_name: item.user_name,
          finished_at: dayjs(item.finished_at).format("DD.MM.YYYY HH:MM"),
          test: dayjs(item.finished_at).format("DD.MM.YYYY HH:MM"),
          name: item.name,
          trainer_name: item.trainer_name,
        }))}
        pagination={false}
        scroll={{ y: 500, x: 600 }}
        locale={{ emptyText: "Данные по проектам не найдены" }}
        key={"my-lessons-table"}
      />
    </div>
  );
};

export default MyTrainingTable;
