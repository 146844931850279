import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { checkAuthenticated } from "../helpers/checkAuth";
import { IWorkstation, getWorkstations } from "../services/Workstation";
import { useUser } from "./useUser";
import { onlyAdmin, onlyLPR, Role } from "../settings/content";
import { getLicense, ILicense } from "../services/License";

interface IWorkStationProps {
  children: ReactElement;
}

interface IWorkstationsContext {
  workstations: IWorkstation[];
  licences: ILicense[];
  refetch: () => void;
  refetchLicences: () => void;
}

const initialContext: IWorkstationsContext = {
  workstations: [],
  licences: [],
  refetch: () => {},
  refetchLicences: () => {},
};

const WorkstationsContext = React.createContext<IWorkstationsContext>(initialContext);

export const useWorkstations = () => {
  return useContext(WorkstationsContext);
};

export const WorkstationsProvider: React.FC<IWorkStationProps> = ({ children }) => {
  const { user } = useUser();
  const authenticated = checkAuthenticated();

  const [licences, setLicences] = useState<ILicense[]>([]);
  const [workstations, setWorkstations] = useState<IWorkstation[]>([]);

  const { data: workstationsData, refetch } = useQuery({
    queryFn: () => getWorkstations(),
    queryKey: ["workstations"],
    enabled: authenticated && [...onlyAdmin, ...onlyLPR].includes(user.role as Role),
  });
  const { data: licencesData, refetch: refetchLicences } = useQuery({
    queryFn: () => getLicense(),
    queryKey: ["licences"],
    enabled: authenticated && [...onlyAdmin, ...onlyLPR].includes(user.role as Role),
    onSuccess: (data) => {
      setLicences(data.data);
    },
  });

  useEffect(() => {
    if (!workstationsData) {
      return;
    }
    setWorkstations(workstationsData.data);
  }, [workstationsData]);

  return (
    <WorkstationsContext.Provider value={{ workstations, licences, refetch, refetchLicences }}>{children}</WorkstationsContext.Provider>
  );
};
