import { Table, TableProps } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { useUser } from "../../../context/useUser";
import { IGetAnalyticsByActiveDaysItem, getAnalyticsByActiveDays } from "../../../services/Analytics";
import { useShops } from "../../../context/useShops";
import { IDepartment, getDepartment } from "../../../services/Department";

interface DataType {
  shop: string;
  address: string;
  department: string;
  key: string;
  active_days: string;
  projects_count: string;
  unique_projects_count: string;
  visualization_count: string;
  multi_room_projects_count: string;
  unique_multiroom_projects_count: string;
}

type Props = {
  selectedInterval: { dateFrom: string; dateTo: string };
};

const AnalyticsByActiveDaysTable = ({ selectedInterval }: Props) => {
  const { user } = useUser();
  const { shops } = useShops();

  const [analyticsData, setAnalyticsData] = useState<IGetAnalyticsByActiveDaysItem[]>([]);
  const [departmentsData, setDepartmentsData] = useState<IDepartment[]>([]);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Ключ",
      dataIndex: "key",
      key: "key",
      width: "10%",
      filters: analyticsData.map((item) => ({ text: item.key, value: item.key })),
      onFilter: (value, record) => record.key.indexOf(value as string) === 0,
      filterSearch: true,
    },
    {
      title: "Магазин",
      dataIndex: "shop",
      key: "shop",
      width: "15%",
      filters: shops.map((item) => ({ text: item.name, value: item.name })),
      onFilter: (value, record) => record.shop.indexOf(value as string) === 0,
      filterSearch: true,
    },
    {
      title: "Адрес магазина",
      dataIndex: "address",
      key: "address",
      width: "15%",
      filters: shops.map((item) => ({ text: item.address, value: item.address })),
      onFilter: (value, record) => record.address.indexOf(value as string) === 0,
      filterSearch: true,
    },
    {
      title: "Отдел",
      dataIndex: "department",
      key: "department",
      width: "15%",
    },
    {
      title: "Активные дни",
      dataIndex: "active_days",
      key: "active_days",
      width: "10%",
    },
    {
      title: "Кол-во проектов",
      dataIndex: "projects_count",
      key: "projects_count",
      width: "10%",
    },
    {
      title: "Кол-во уникальных проектов",
      dataIndex: "unique_projects_count",
      key: "unique_projects_count",
      width: "10%",
    },
    {
      title: "Визуализаций",
      dataIndex: "visualization_count",
      key: "visualization_count",
      width: "10%",
    },
    {
      title: "Количество многокомнатных проектов",
      dataIndex: "multi_room_projects_count",
      key: "multi_room_projects_count",
      width: "10%",
    },
    {
      title: "Количество уникальных многокомнатных проектов",
      dataIndex: "unique_multiroom_projects_count",
      key: "unique_multiroom_projects_count",
      width: "10%",
    },
  ];

  const { data } = useQuery({
    queryFn: () => getAnalyticsByActiveDays(selectedInterval),
    queryKey: [`analytics_by_active_days_${selectedInterval.dateFrom}/${selectedInterval.dateTo}`],
    onSuccess: (data) => {
      if (!Array.isArray(data.data.items)) {
        return;
      }
      setAnalyticsData(data.data.items);
    },
  });

  const { data: departmentData } = useQuery({
    queryFn: () => getDepartment(),
    queryKey: [`departments`],
    onSuccess: (data) => {
      setDepartmentsData(data.data);
    },
  });

  return (
    <Table
      id="analytics-by-active-days-table"
      columns={columns}
      dataSource={analyticsData.map((item) => ({
        shop: shops.find((shop) => shop.id === item.shop_id)?.name ?? item.shop_id,
        address: shops.find((shop) => shop.id === item.shop_id)?.address ?? "Адрес",
        department: departmentsData.find((department) => department.id === item.department_id)?.name ?? item.department_id,
        key: item.key,
        active_days: item.active_days_count.toString(),
        projects_count: item.opened_projects_count.toString(),
        unique_projects_count: item.unique_opened_projects_count.toString(),
        visualization_count: item.visualizations_count.toString(),
        multi_room_projects_count: item.opened_multiroom_projects_count.toString(),
        unique_multiroom_projects_count: item.opened_unique_multiroom_projects_count.toString(),
      }))}
      pagination={{ defaultPageSize: 15, position: ["bottomCenter"] }}
      scroll={{ y: 500, x: 1100 }}
      locale={{
        filterSearchPlaceholder: "Поиск",
        emptyText: "Данные по проектам не найдены",
        filterConfirm: "Применить",
        filterReset: "Сбросить",
        filterTitle: "Фильтр",
        filterEmptyText: "Нет данных",
        selectAll: "Выбрать все",
        selectInvert: "Отменить выбор",
        selectionAll: "Выбрать все",
        sortTitle: "Сортировка",
        expand: "Развернуть строку",
        collapse: "Свернуть строку",
        triggerDesc: "Сортировать по убыванию",
        triggerAsc: "Сортировать по возрастанию",
        cancelSort: "Отменить сортировку",
      }}
      key={"analytics-by-active-days-table"}
    />
  );
};

export default AnalyticsByActiveDaysTable;
