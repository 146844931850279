import React, { useEffect, useState } from "react";
import styles from "./WorkstationAdd.module.scss";
import { Modal, message } from "antd";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cross.svg";
import InputCustom from "../../../../components/ui/InputCustom/InputCustom";
import SelectCustom from "../../../../components/ui/SelectCustom/SelectCustom";
import content, { onlyAdmin } from "../../../../settings/content";
import { useMutation, useQuery } from "react-query";
import { getDepartment } from "../../../../services/Department";
import { getShops } from "../../../../services/Shops";
import { getLicense } from "../../../../services/License";
import { addWorkstation } from "../../../../services/Workstation";
import { NoticeType } from "antd/es/message/interface";
import ValidateComponentByRole from "../../../../components/ValidateComponentByRole/ValidateComponentByRole";

type Props = {
  updateWorkstationsList: (id: string) => void;
};

interface ISelect {
  value: string;
  label: string;
}

const WorkstationAdd: React.FC<Props> = ({ updateWorkstationsList }) => {
  const { mutateAsync: onAddWorkstation } = useMutation({
    mutationFn: addWorkstation,
  });

  const { data: shopsData } = useQuery({
    queryFn: () => getShops(),
    queryKey: ["shops"],
  });

  const { data: departmentData } = useQuery({
    queryFn: () => getDepartment(),
    queryKey: ["departments"],
  });

  const { data: licencesData, refetch: refetchLicences } = useQuery({
    queryFn: () => getLicense(),
    queryKey: ["licences"],
  });

  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);
  const [departments, setDepartments] = useState<(ISelect | null)[]>([]);
  const [licences, setLicences] = useState<(ISelect | null)[]>([]);
  const [departmentId, setDepartmentId] = useState<string>("");
  const [licenseId, setLicenseId] = useState<string>("");
  const [workstationName, setWorkstationName] = useState<string>("");

  useEffect(() => {
    if (!departmentData || !departments) {
      return;
    }
    if (!shopsData?.data || !Array.isArray(shopsData.data)) {
      return;
    }

    // Пройдемся по двум массивам (Магазины и отделы) найдем совпадение по ID
    // и пересоберем массив для селекта, где полю label добавим название магазина
    const departmentsWithShopNames = departmentData.data
      .map((i) => {
        const arr: ISelect[] = [];
        shopsData?.data.forEach((j) => {
          if (j.id === i.shop_id) {
            return arr.push({ value: i.id, label: `${i.name} (${j.name})` });
          }
        });
        return arr;
      })
      .reduce((acc, val) => acc.concat(val), []);

    setDepartments(departmentsWithShopNames ?? []);
  }, [departmentData]);

  useEffect(() => {
    if (!licencesData) {
      return;
    }

    // Фильтрация для null нужна чтобы убрать из преобразованного массива null
    // иначе Select сломается
    const licencesArray = licencesData.data.map((key) => (!key.assigned ? { value: key.id, label: key.key } : null)).filter((i) => i);
    setLicences(licencesArray);
  }, [licencesData]);

  const handleCloseModal = () => {
    setOpen(false);
    setDepartmentId("");
    setLicenseId("");
    setWorkstationName("");
    refetchLicences();
  };

  const handleAddWorkstation = () => {
    onAddWorkstation({
      department_id: departmentId,
      license_id: licenseId,
      name: workstationName,
    }).then((response) => {
      if (response.status === 200) {
        alert("success", content.alert_messages.add_workstation.success);
        updateWorkstationsList(response.id);
        handleCloseModal();
      } else {
        alert("error", content.alert_messages.add_workstation.error);
      }
    });
  };

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  return (
    <div className={styles.workstationAdd}>
      {contextHolder}
      <ValidateComponentByRole requiredRoles={[...onlyAdmin]}>
        <ButtonCustom onClick={() => setOpen(true)} className={styles.workstationAdd__modal_btn}>
          <span>{content.organizations.keys.add_workstation.btn_title}</span>
        </ButtonCustom>
      </ValidateComponentByRole>
      <Modal
        destroyOnClose
        className={"custom_shop_modal"}
        open={open}
        centered
        mask={false}
        onCancel={handleCloseModal}
        closeIcon={false}
        footer={false}
        width={340}
        maskClosable={false}
      >
        <div className={styles.workstationAdd__modal_header}>
          <span>{content.organizations.keys.add_workstation.modal_title}</span>
          <ButtonCustom isTransparent maxWidth="20px" className={styles.workstationAdd__modal_close} onClick={handleCloseModal}>
            <CloseIcon />
          </ButtonCustom>
        </div>
        <div className={styles.workstationAdd__body}>
          <div className={styles.workstationAdd__block}>
            <InputCustom
              value={workstationName}
              onChange={(value) => setWorkstationName(value.currentTarget.value)}
              placeholder={content.organizations.keys.add_workstation.name}
              maxLength={100}
            />
          </div>
          <div className={styles.workstationAdd__block}>
            <SelectCustom
              options={[...(departments as [])]}
              placeholder={content.organizations.keys.add_workstation.department}
              width="100%"
              onChange={(value) => setDepartmentId(value)}
            />
          </div>
          <div className={styles.workstationAdd__block}>
            <SelectCustom
              options={[...(licences as [])]}
              placeholder={content.organizations.keys.add_workstation.key}
              width="100%"
              onChange={(value) => setLicenseId(value)}
            />
          </div>
          <div className={styles.workstationAdd__block_btn}>
            <ButtonCustom
              maxWidth="100px"
              className={styles.workstationAdd__add_btn}
              onClick={handleAddWorkstation}
              disabled={!departmentId || !licenseId || !workstationName || workstationName === ""}
            >
              <span>{content.organizations.keys.add_workstation.add_btn_title}</span>
            </ButtonCustom>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default WorkstationAdd;
