import { useEffect, useState } from "react";
import styles from "./ConnectionIdModal.module.scss";
import {
  IGetPhoneBinding,
  deletePhoneBindingById,
  deletePhoneConnectionById,
  getPhoneBinding,
  getPhoneConnection,
  getPhoneConnections,
} from "../../../../services/Shops";
import { useMutation, useQuery } from "react-query";
import { Checkbox, message } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useShops } from "../../../../context/useShops";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import { NoticeType } from "antd/es/message/interface";

type Props = {
  connectionId: string;
  onCancel: () => void;
  onDeleteSuccess: () => void;
  refetchShops: () => void;
  isCheckboxes?: boolean;
  mailingModalId: string;
};

const ConnectionIdModal = ({ connectionId, onCancel, refetchShops, isCheckboxes = true, onDeleteSuccess, mailingModalId }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const { shops } = useShops();
  const [phoneBinding, setPhoneBinding] = useState<IGetPhoneBinding[]>([]);

  const [selectedIds, setSelectedIds] = useState<CheckboxValueType[]>([]);

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const { data: phoneBindingData, refetch } = useQuery({
    queryFn: () => getPhoneBinding({ connection_id: connectionId }),
    queryKey: [`phoneBinding_${connectionId}`],
    refetchInterval: 10000,
  });

  const { data: phoneConnectionData } = useQuery({
    queryFn: () => getPhoneConnection(connectionId),
    queryKey: [`phone_connection${connectionId}`],
  });

  const { refetch: refetchConnections } = useQuery({
    queryFn: () => getPhoneConnections(),
    queryKey: [`phone_connections${mailingModalId}`],
  });

  const { mutateAsync: onDeletePhoneBindingById } = useMutation({
    mutationFn: deletePhoneBindingById,
  });

  const { mutateAsync: onDeletePhoneConnectionById } = useMutation({
    mutationFn: deletePhoneConnectionById,
  });

  useEffect(() => {
    if (phoneBindingData?.data) {
      setPhoneBinding(phoneBindingData.data);
    }
  }, [phoneBindingData]);

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedIds(checkedValues);
  };

  const onSubmit = async () => {
    for (const id of selectedIds) {
      await onDeletePhoneBindingById(id.toString());
    }
    refetch();
    refetchShops();
    onCancel();
  };

  const onDeletePhone = async () => {
    const bindingDeleteRequests = phoneBinding.map(async (item) => {
      const request = await onDeletePhoneBindingById(item.id);
      return request.data;
    });
    await Promise.all(bindingDeleteRequests);

    await onDeletePhoneConnectionById(connectionId).then((response) => {
      if (response.status === 200) {
        refetch();
        refetchConnections();
        refetchShops();
        onDeleteSuccess();
      } else {
        alert("success", response.data.toString());
      }
    });
  };

  return (
    <div className={styles.ConnectionIdModal}>
      {contextHolder}
      {!!phoneBinding.length && <span className={styles.ConnectionIdModal_title}>Магазины, которые останутся без рассылки:</span>}
      {!phoneBinding.length && !isCheckboxes && (
        <span className={styles.ConnectionIdModal_title}>Вы действительно хотите удалить номер {phoneConnectionData?.data.phone}?</span>
      )}
      <div className={styles.ConnectionIdModal__content}>
        {isCheckboxes && (
          <Checkbox.Group
            options={phoneBinding.map((bind, index) => {
              const currentShop = shops.find((shop) => shop.id === bind.shop_id);
              return {
                value: bind.id,
                label: `${currentShop?.name} ${currentShop?.address}`,
              };
            })}
            onChange={onChange}
            rootClassName={styles.ConnectionIdModal__checkboxes}
          />
        )}
        {!isCheckboxes &&
          phoneBinding.map((bind, index) => {
            const currentShop = shops.find((shop) => shop.id === bind.shop_id);

            return (
              <span key={bind.connection_id + index + "delete_title"}>{`${index + 1}. ${currentShop?.name} ${currentShop?.address}`}</span>
            );
          })}
      </div>
      <div className={styles.ConnectionIdModal__footer_btns}>
        <ButtonCustom className={styles.ConnectionIdModal__footer_btn} onClick={onCancel} maxWidth="150px" bgColor="gray">
          <span>Отмена</span>
        </ButtonCustom>
        <ButtonCustom
          className={styles.ConnectionIdModal__footer_btn}
          onClick={isCheckboxes ? onSubmit : onDeletePhone}
          maxWidth="150px"
          bgColor="blue"
        >
          <span>Подтвердить</span>
        </ButtonCustom>
      </div>
    </div>
  );
};

export default ConnectionIdModal;
