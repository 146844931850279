import React, { createContext, useContext, useState, ReactNode } from "react";
import { INotification } from "../services/Reminders";

interface Data {
  notifications: INotification[];
  setNotifications: React.Dispatch<React.SetStateAction<INotification[]>>;
  mailingNotification: INotification[];
  setMailingNotification: React.Dispatch<React.SetStateAction<INotification[]>>;
}

const initialData: Data = {
  notifications: [],
  setNotifications: () => {},
  mailingNotification: [],
  setMailingNotification: () => {},
};

const DataContext = createContext<Data>(initialData);

export const NotificationsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [mailingNotification, setMailingNotification] = useState<INotification[]>([]);

  return (
    <DataContext.Provider value={{ notifications, setNotifications, mailingNotification, setMailingNotification }}>
      {children}
    </DataContext.Provider>
  );
};

export const useNotificationsContext = () => useContext(DataContext);
