import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { buildDealURL } from "./buildDealURL";

export const useOpenDeal = (dealId?: string) => {
  const history = useHistory();

  const openDealOnClick = useCallback(() => {
    if (!dealId) {
      return;
    }
    history.push(buildDealURL(dealId));
  }, [dealId, history]);

  return {
    openDealOnClick,
  };
};
