import { Modal, QRCode } from "antd";
import styles from "./GalleryCardDetailed.module.scss";
import ButtonCustom from "../../../components/ui/ButtonCustom/ButtonCustom";
import { ReactComponent as CloseIcon } from "../../../assets/icons/cross.svg";
import { IProjectJson } from "../../../services/Projects";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";

interface Props {
  id: string;
  item: IProjectJson;
  qrModal: boolean;
  handleCloseQr: () => void;
  projectId: string;
  organizationId: string;
  isOpenedFromGallery: boolean;
  isOpenedFromIframe: boolean;
}

const GalleryQRCode: React.FC<Props> = ({
  id,
  item,
  handleCloseQr,
  qrModal,
  projectId,
  organizationId,
  isOpenedFromGallery,
  isOpenedFromIframe = false,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <Modal
      closeIcon={false}
      className={"custom_shop_modal"}
      width={380}
      footer={null}
      onCancel={() => handleCloseQr()}
      open={qrModal}
      maskClosable={false}
    >
      <div className={styles.gallery_card_detailed__modal_qr_header}>
        <span>QRCode</span>
        <ButtonCustom
          isTransparent
          maxWidth="20px"
          className={styles.gallery_card_detailed__modal_qr_close}
          onClick={() => handleCloseQr()}
        >
          <CloseIcon />
        </ButtonCustom>
      </div>
      <div className={styles.gallery_card_detailed__qr_container}>
        <QRCode
          className={`qr_${id}`}
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={
            isOpenedFromGallery
              ? `${window.location.origin}/public-gallery/${organizationId}?project_id=${projectId}`
              : `${window.location.origin}/view-project/${projectId}`
          }
        />
        <CopyToClipboard
          text={
            isOpenedFromGallery
              ? `${window.location.origin}/public-gallery/${organizationId}?project_id=${projectId}`
              : `${window.location.origin}/view-project/${projectId}`
          }
          onCopy={() => setIsCopied(true)}
        >
          <button className={styles.gallery_card_detailed_copy}>Скопировать ссылку</button>
        </CopyToClipboard>
        {isCopied && <span style={{ color: "green" }}>Ссылка скопирована</span>}
      </div>
    </Modal>
  );
};

export default GalleryQRCode;
