import axios from "axios";
import { ICity } from "../context/useCities";
import Cookies from "js-cookie";
import { baseUrl } from "../env";

export interface IGetAllCities {
  data: ICity[];
  status: number;
}

// Метод для получения всех городов
export async function getAllCities(): Promise<IGetAllCities> {
  try {
    const request = await axios.get(baseUrl + `city`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } });
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getAllCities:");
    throw new Error(err.request.status);
  }
}
