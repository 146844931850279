import styles from "./AddEmailModal.module.scss";
import { Tabs, TabsProps } from "antd";
import EmailFaqContent from "./EmailFaqContent/EmailFaqContent";
import { gmailcom, mailru, yandex } from "./constants";

type Props = {};

const EmailFaq = ({}: Props) => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Mail.ru",
      children: (
        <div>
          <EmailFaqContent list={mailru} />
        </div>
      ),
    },
    {
      key: "2",
      label: "Yandex.ru",
      children: (
        <div>
          <EmailFaqContent list={yandex} />
        </div>
      ),
    },
    {
      key: "3",
      label: "Gmail.com",
      children: (
        <div>
          <EmailFaqContent list={gmailcom} />
        </div>
      ),
    },
    {
      key: "4",
      label: "Остальное",
      children: (
        <div className={styles.EmailFaq__other_text}>
          Для служб электронной почты, отличных от указанной, настройка учетной записи не требуется. Для корректной работы рассылки
          электронной почты необходимо предоставить учетные данные для входа в учетную запись службы электронной почты, а именно: имя
          пользователя и пароль от аккаунта почтовой службы.
        </div>
      ),
    },
  ];
  return (
    <div className={styles.EmailFaq}>
      <Tabs defaultActiveKey="1" destroyInactiveTabPane={true} items={items} />
    </div>
  );
};

export default EmailFaq;
