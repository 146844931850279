import React, { useEffect, useState } from "react";
import ButtonCustom from "../../ui/ButtonCustom/ButtonCustom";
import { AnalyticsDeal, getAnalyticsProjects } from "../../../services/Analytics";
import { useQuery } from "react-query";
import styles from "./AnalyticsProjects.module.scss";
import "./AnalyticsProjects_override.scss";
import content from "../../../settings/content";
import locale from "antd/es/date-picker/locale/ru_RU";
import "dayjs/locale/ru";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { Table } from "antd";
import type { TableProps } from "antd";
import { IShop, getShops } from "../../../services/Shops";
import { IStuffUser, getAllStuff } from "../../../services/User";
import { IDepartment, getDepartment } from "../../../services/Department";

type Props = {};

interface ITableData extends AnalyticsDeal {
  department_id_value: string;
  shop_id_value: string;
}

const AnalyticsProjects: React.FC<Props> = ({}) => {
  const { data: shopsData } = useQuery({
    queryFn: () => getShops(),
    queryKey: ["shops"],
  });

  const { data: users } = useQuery({
    queryFn: () => getAllStuff(),
    queryKey: ["users"],
  });

  const { data: departmentData } = useQuery({
    queryFn: () => getDepartment(),
    queryKey: ["departments"],
  });

  const [infoData, setinfoData] = useState<{ shops: IShop[]; departments: IDepartment[]; users: IStuffUser[] }>({
    shops: [],
    departments: [],
    users: [],
  });
  const [data, setData] = React.useState<ITableData[]>([]);
  const [dateFrom, setDateFrom] = useState(dayjs().subtract(30, "day").format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(dayjs().format("YYYY-MM-DD"));
  const [disabled, setDisabled] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [currentShopIdFilter, setCurrentShopIdFilter] = useState<string[]>([]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (shopsData?.data) {
      setinfoData((prevState) => ({ ...prevState, shops: shopsData.data }));
    }
    if (departmentData?.data) {
      setinfoData((prevState) => ({ ...prevState, departments: departmentData.data }));
    }
    if (users?.data) {
      setinfoData((prevState) => ({ ...prevState, users: users.data }));
    }
  }, [shopsData, departmentData, users]);

  const columns: TableProps<ITableData>["columns"] = [
    {
      title: "Магазин",
      dataIndex: "shop_id",
      key: "shop_id",
      width: "17%",
      render: (text) => <span className={styles.table__text}>{text}</span>,
      filters: data
        .map((item) => ({ text: item.shop_id, value: item.shop_id_value }))
        .filter((value, index, self) => index === self.findIndex((t) => t.value === value.value)),
      onFilter: (value, record) => record.shop_id_value.indexOf(value as string) === 0,
      filterSearch: true,
    },
    {
      title: "Отдел",
      dataIndex: "department_id",
      key: "department_id",
      width: "17%",
      render: (text) => <span className={styles.table__text}>{text}</span>,
      filters:
        currentShopIdFilter.length > 0
          ? data
              .filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.department_id_value === value.department_id_value) &&
                  currentShopIdFilter.includes(value.shop_id_value),
              )
              .map((item) => ({ text: item.department_id, value: item.department_id_value }))
          : data
              .map((item) => ({ text: item.department_id, value: item.department_id_value }))
              .filter((value, index, self) => index === self.findIndex((t) => t.value === value.value)),
      onFilter: (value, record) => record.department_id_value.indexOf(value as string) === 0,
      filterSearch: true,
    },
    {
      title: "Сотрудник",
      dataIndex: "created_by",
      key: "created_by",
      width: "17%",
      render: (text) => <span className={styles.table__text}>{text}</span>,
      filters: data
        .map((item) => ({ text: item.created_by, value: item.created_by }))
        .filter((value, index, self) => index === self.findIndex((t) => t.value === value.value)),
      onFilter: (value, record) => record.created_by.indexOf(value as string) === 0,
      filterSearch: true,
    },
    {
      title: "Кол-во созданных сделок",
      dataIndex: "deals_count",
      key: "deals_count",
      width: "12.5%",
      render: (text) => <span className={`${styles.table__text} ${styles.table__text_int}`}>{text}</span>,
    },
    {
      title: "Кол-во сделок проданных",
      dataIndex: "deals_sold_count",
      key: "deals_sold_count",
      width: "12.5%",
      render: (text) => <span className={`${styles.table__text} ${styles.table__text_int}`}>{text}</span>,
    },
    {
      title: "Кол-во созданных проектов",
      dataIndex: "projects_count",
      key: "projects_count",
      width: "12.5%",
      render: (text) => <span className={`${styles.table__text} ${styles.table__text_int}`}>{text}</span>,
    },
    {
      title: "Среднее время на 1 сделку, рабочие дни",
      dataIndex: "days_to_sold_average",
      key: "days_to_sold_average",
      width: "12.5%",
      render: (text) => <span className={`${styles.table__text} ${styles.table__text_int}`}>{text}</span>,
    },
  ];

  const onSumbit = () => {
    getAnalyticsProjects(dayjs(dateFrom, "YYYY-MM-DD").toISOString(), dayjs(dateTo, "YYYY-MM-DD").add(1, "day").toISOString()).then(
      (response) => {
        if (response?.data && Array.isArray(response.data)) {
          const responseData = response.data;
          const convertedResponse: ITableData[] = responseData
            .map((item) => {
              const username = infoData.users.find((user) => user.id === item.created_by)?.name;
              const shopName = infoData.shops.find((shop) => shop.id === item.shop_id)?.name;
              const departmentName = infoData.departments.find((department) => department.id === item.department_id)?.name;
              return {
                ...item,
                created_by: username ?? item.created_by,
                shop_id: shopName ?? item.shop_id,
                department_id: departmentName ?? item.department_id,
                department_id_value: item.department_id,
                shop_id_value: item.shop_id,
              };
            })
            .sort((a, b) => {
              if (a.shop_id === b.shop_id) {
                return a.department_id.localeCompare(b.department_id);
              } else {
                return a.shop_id.localeCompare(b.shop_id);
              }
            });
          setData(convertedResponse);
        }
      },
    );
  };

  useEffect(() => {
    if (shopsData && users && departmentData) {
      onSumbit();
    }
  }, [shopsData, users, departmentData, infoData]);

  useEffect(() => {
    if (!dateFrom || !dateTo || dateFrom === "Invalid Date" || dateTo === "Invalid Date") {
      setDisabled(true);
      return;
    }

    if (dayjs(dateFrom).isAfter(dateTo)) {
      setDisabled(true);
      return;
    }

    if (dayjs(dateTo).isBefore(dateTo)) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  }, [dateFrom, dateTo]);

  const handleChange = (pagination: any, filters: any) => {
    setCurrentShopIdFilter(filters.shop_id ?? []); // Обновляем состояние с выбранными фильтрами
  };

  return (
    <div className={styles.projects}>
      <div className={styles.projects__block}>
        <div className={styles.projects__block_date}>
          <DatePicker
            defaultValue={dayjs().subtract(30, "day")}
            className={styles.projects__block_picker}
            locale={locale}
            onChange={(dateString) => setDateFrom(dayjs(dateString).format("YYYY-MM-DD"))}
          />
          <DatePicker
            defaultValue={dayjs()}
            className={styles.projects__block_picker}
            locale={locale}
            onChange={(dateString) => setDateTo(dayjs(dateString).format("YYYY-MM-DD"))}
          />
          <ButtonCustom className={styles.projects__block_btn} maxWidth="60px" onClick={() => onSumbit()} disabled={disabled}>
            <span>{content.control.projects.search_btn}</span>
          </ButtonCustom>
          <ButtonCustom maxWidth="120px" className={styles.clients__btn} disabled>
            <span>{content.control.clients_list.excel_btn}</span>
          </ButtonCustom>
        </div>
      </div>
      <div className={styles.projects__table_wrapper}>
        <Table
          id="table"
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ y: 500, x: isMobile ? 1100 : undefined }}
          locale={{
            filterSearchPlaceholder: "Поиск",
            emptyText: "Данные по проектам не найдены",
            filterConfirm: "Применить",
            filterReset: "Сбросить",
            filterTitle: "Фильтр",
            filterEmptyText: "Нет данных",
            selectAll: "Выбрать все",
            selectInvert: "Отменить выбор",
            selectionAll: "Выбрать все",
            sortTitle: "Сортировка",
            expand: "Развернуть строку",
            collapse: "Свернуть строку",
            triggerDesc: "Сортировать по убыванию",
            triggerAsc: "Сортировать по возрастанию",
            cancelSort: "Отменить сортировку",
          }}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
export default AnalyticsProjects;
