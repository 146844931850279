import { Modal } from "antd";
import React from "react";
import styles from "../GalleryCards.module.scss";
import content from "../../../../settings/content";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cross.svg";

type Props = {
  modalSelect: boolean;
  handleModalSelect: (select: boolean) => void;
  handleModalCopy: (copy: boolean) => void;
  handleModalCreate: () => void;
};

const GalleryCardsModalOptions: React.FC<Props> = ({ modalSelect, handleModalSelect, handleModalCopy, handleModalCreate }) => {
  return (
    <Modal
      className={"custom_shop_modal"}
      onCancel={() => handleModalSelect(false)}
      centered
      width={305}
      open={modalSelect}
      closeIcon={null}
      footer={null}
      zIndex={9999}
      maskClosable={false}
    >
      <div className={styles.gallery_cards_container__modal_header}>
        <span>{content.gallery.detail_card.select_btn_copy_title}</span>
        <ButtonCustom
          isTransparent
          maxWidth="20px"
          className={styles.gallery_cards_container__modal_close}
          onClick={() => handleModalSelect(false)}
        >
          <CloseIcon />
        </ButtonCustom>
      </div>
      <div className={styles.gallery_cards_container__wrapper_modal}>
        <ButtonCustom className={styles.gallery_cards_container__wrapper_modal_btn} maxWidth="190px" onClick={() => handleModalCreate()}>
          <span>{content.gallery.detail_card.select_btn_create}</span>
        </ButtonCustom>
        <ButtonCustom className={styles.gallery_cards_container__wrapper_modal_btn} maxWidth="190px" onClick={() => handleModalCopy(true)}>
          <span>{content.gallery.detail_card.select_btn_select}</span>
        </ButtonCustom>
      </div>
    </Modal>
  );
};
export default GalleryCardsModalOptions;
