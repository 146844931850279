import DealColumnHeader from "../DealColumnHeader/DealColumnHeader";
import styles from "./DealsColumnList.module.scss";
import React from "react";
import moment from "moment";
require("moment/locale/ru"); // подключаем локализацию для русского языка

moment.locale("ru"); // устанавливаем локаль для момента

export interface IDealsColumnListProps {
  title: string;
  dataLength: number;
  childrens: React.ReactNode;
  notFoundText?: string;
}

const DealsColumnlist: React.FC<IDealsColumnListProps> = ({ dataLength, title, childrens, notFoundText }) => {
  return (
    <div className={styles.dealsContainer}>
      <DealColumnHeader title={title} count={dataLength} />
      <div className={styles.dealsContainer__column}>
        <div className={styles.dealsContainer__card}>{childrens}</div>
      </div>
    </div>
  );
};

export default DealsColumnlist;
