import ButtonCustom from "../../../components/ui/ButtonCustom/ButtonCustom";
import { ReactComponent as HideIcon } from "../../../assets/icons/hide.svg";
import { ReactComponent as HiddenIcon } from "../../../assets/icons/hided.svg";
import { IProject, updateProjectStatus, updateProjectTitle, updatePublicProjectStatus } from "../../../services/Projects";
import styles from "./GalleryCard.module.scss";
import { Skeleton } from "antd";
import { useState } from "react";
import ValidateComponentByRole from "../../../components/ValidateComponentByRole/ValidateComponentByRole";
import "./GalleryCard_override.scss";
import { useMutation } from "react-query";
import { onlyAdmin, onlyLPR, Role } from "../../../settings/content";
import { ProjectStatusType } from "../../../helpers/projectStatus";
import ModerationStatus from "../../../components/ui/ModerationStatus/ModerationStatus";
import { yandexCloudUrl } from "../../../env";
import { useUser } from "../../../context/useUser";
import InputWithViewMode from "../../../components/ui/InputCustom/InputWithViewMode";

interface ICard {
  project: IProject;
  handleCardClick: (id: string) => void;
  updateProjects: () => void;
  isPublicGallery?: boolean;
  onProjectTitleChange: ({ id, title }: { id: string; title: string }) => void;
}

const GalleryCard: React.FC<ICard> = ({ project, handleCardClick, updateProjects, isPublicGallery, onProjectTitleChange }) => {
  const { users, user } = useUser();

  const [isPreview, setIsPreview] = useState<boolean>();

  const mutationFn = isPublicGallery ? updatePublicProjectStatus : updateProjectStatus;

  const { mutateAsync: onUpdateProjectStatus } = useMutation({
    mutationFn,
    onSuccess: updateProjects ? () => updateProjects() : () => null,
  });

  const { mutateAsync: onProjectNameEdit } = useMutation({
    mutationFn: updateProjectTitle,
    onSuccess: (response) => {
      if (response.status === 200) {
        updateProjects();
      }
    },
  });

  const onVisibleChange = (status: ProjectStatusType) => {
    onUpdateProjectStatus({
      id: project.id,
      status,
    });
  };

  const previewSrc = `${yandexCloudUrl}${project.id}/small.png`;
  const projectPreview = new Image();
  projectPreview.src = previewSrc;
  projectPreview.onload = function () {
    setIsPreview(true);
  };
  projectPreview.onerror = function () {
    setIsPreview(false);
  };

  return (
    <div className={styles.gallery__card}>
      <ValidateComponentByRole requiredRoles={[...onlyLPR, ...onlyAdmin]} key={"hideBtn"}>
        <div className={styles.gallery__card_hide}>
          <ModerationStatus status={project.status} />
          {(project.status === "published" || project.status === "hidden") && (
            <ButtonCustom
              className={styles.gallery__card_hide_btn}
              onClick={() =>
                onVisibleChange(
                  project.status === ProjectStatusType.PUBLISHED
                    ? ProjectStatusType.HIDDEN
                    : project.status === ProjectStatusType.HIDDEN
                      ? ProjectStatusType.PUBLISHED
                      : ProjectStatusType.HIDDEN,
                )
              }
              maxWidth="fit-content"
              isTransparent
            >
              {project.status === "published" ? <HideIcon /> : project.status === "hidden" ? <HiddenIcon /> : <></>}
            </ButtonCustom>
          )}
        </div>
      </ValidateComponentByRole>
      <div className={styles.gallery__img_block}>
        {!isPreview ? (
          <Skeleton.Image className="custom_styles" />
        ) : (
          <img src={previewSrc} alt={project.name + project.id} className={styles.gallery__img} style={{ height: "auto" }} />
        )}
      </div>
      <div className={styles.gallery__block}>
        <div className={styles.gallery__card_controle} style={{ width: "100%" }}>
          {!isPublicGallery && [...onlyLPR, ...onlyAdmin].includes(user?.role as Role) ? (
            <div style={{ width: "calc(100% - 100px)" }}>
              <InputWithViewMode
                inputName="projectName"
                inputPlaceholder="Название проекта"
                inputValue={project.name}
                id={project.id}
                onSubmit={onProjectNameEdit}
                maxLength={30}
              />
            </div>
          ) : (
            <p className={styles.gallery__card_title}>{project.name ? project.name : "Без названия"}</p>
          )}
          <ButtonCustom
            className={styles.custom_button}
            onClick={() => handleCardClick(project.id)}
            maxWidth="fit-content"
            bgColor="darkGrey"
          >
            <span>Открыть</span>
          </ButtonCustom>
        </div>
        {!isPublicGallery && (
          <span className={styles.gallery__author}>Автор: {users.find((item) => item.id === project.created_by)?.name ?? "Без имени"}</span>
        )}
      </div>
    </div>
  );
};

export default GalleryCard;
