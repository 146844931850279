export enum SortOptionsList {
  oldFirst = "oldFirst",
  newFirst = "newFirst",
  updatedFirst = "updatedFirst",
  byTitle = "byTitle",
  byClientName = "byClientName",
}

export const sortOptions = [
  { value: SortOptionsList.newFirst, label: "Сначала новые" },
  { value: SortOptionsList.oldFirst, label: "Сначала старые" },
  // { value: SortOptionsList.updatedFirst, label: "Сначала обновленные", disabled: true },
  { value: SortOptionsList.byTitle, label: "По названию" },
  { value: SortOptionsList.byClientName, label: "По ФИО клиента" },
];
