import { useEffect, useState } from "react";
import styles from "./FiltersSettings.module.scss";
import FiltersList from "./FiltersList";
import FilterDetailed from "./FilterDetailed";
import { getDealFilters, IDealFilter } from "../../../../services/DealFilters";
import { useQuery } from "react-query";

type Props = {};

const FiltersSettings = ({}: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<IDealFilter>();
  const { data: dealFiltersData } = useQuery({
    queryFn: () => getDealFilters(),
    queryKey: ["dealFilters"],
  });

  useEffect(() => {
    if (!selectedFilter && dealFiltersData?.data.length) {
      setSelectedFilter(dealFiltersData.data[0]);
    }
  }, [dealFiltersData]);

  return (
    <div className={styles.filtersSettings}>
      <div className={styles.filtersSettings__left}>
        <FiltersList filtersList={dealFiltersData?.data ?? []} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
      </div>
      <div className={styles.filtersSettings__right}>
        {selectedFilter ? (
          <FilterDetailed selectedFilter={selectedFilter} />
        ) : (
          <div className={styles.filtersSettings__empty}>Выберите фильтр</div>
        )}
      </div>
    </div>
  );
};

export default FiltersSettings;
