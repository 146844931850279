import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { ColumnType, useDragDrop } from "./DragDropProvider";
import styles from "./KanbanBoard.module.scss";
import Column from "./Column";

type Props = {
  onCardClick: (id: string) => void;
  isDragDisabled: boolean;
};

export type TaskBoardType = {
  columns: ColumnType[];
};

const KanbanBoard = ({ onCardClick, isDragDisabled }: Props) => {
  const { handleDragEnd, handleDragStart, handleDragUpdate, columns } = useDragDrop();

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragUpdate={handleDragUpdate}>
      <Droppable droppableId="all-columns" direction="horizontal" type="column">
        {(provided, snapshot) => (
          <div className={styles.container} id="task-board" {...provided.droppableProps} ref={provided.innerRef}>
            {columns.map((column, columnIndex) => (
              <Column key={column.id} column={column} columnIndex={columnIndex} onCardClick={onCardClick} isDragDisabled={isDragDisabled} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default KanbanBoard;
