export const tableLocale = {
  filterSearchPlaceholder: "Поиск",
  emptyText: "Данные не найдены",
  filterConfirm: "Применить",
  filterReset: "Сбросить",
  filterTitle: "Фильтр",
  filterEmptyText: "Нет данных",
  selectAll: "Выбрать все",
  selectInvert: "Отменить выбор",
  selectionAll: "Выбрать все",
  sortTitle: "Сортировка",
  expand: "Развернуть строку",
  collapse: "Свернуть строку",
  triggerDesc: "Сортировать по убыванию",
  triggerAsc: "Сортировать по возрастанию",
  cancelSort: "Отменить сортировку",
};
