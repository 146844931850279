export enum ProjectStatusType {
  DRAFT = "draft",
  CREATED = "created",
  MODERATION_REQUIRED = "moderation_required",
  PUBLISHED = "published",
  HIDDEN = "hidden",
}

export const getProjectStatus = (status: ProjectStatusType) => {
  if (status === "draft") {
    return "Черновик";
  }
  if (status === "created") {
    return "Потенциал для галереи";
  }
  if (status === "moderation_required") {
    return "Требует модерации";
  }
  if (status === "published") {
    return "Опубликован";
  }
  if (status === "hidden") {
    return "Скрыт";
  }
  return "";
};
