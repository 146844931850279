import { useState } from "react";
import ModalCustom from "../../../components/ModalCustom/ModalCustom";
import styles from "./CreateLead.module.scss";
import SelectCustom from "../../../components/ui/SelectCustom/SelectCustom";
import { IClient, ICreateClient, createClient } from "../../../services/Clients";
import SelectClient from "../../../components/SelectClient/SelectClient";
import ClientForm from "../components/ClientForm/ClientForm";
import InputCustom from "../../../components/ui/InputCustom/InputCustom";
import { ICreateLead, createLead } from "../../../services/Leads";
import ButtonCustom from "../../../components/ui/ButtonCustom/ButtonCustom";
import { useMutation } from "react-query";
import { useClients } from "../../../context/useClients";
import { formatPhone } from "../../../helpers/formatString";
import { useLeads } from "../../../context/useLeads";
import { isEmailValid, isPhoneValid } from "../../../helpers/validation";

type Props = {
  isOpen: boolean;
  handleCloseModal: () => void;
};

const leadDataInitial: ICreateLead = {
  title: "",
  client_id: "",
  channel_id: "",
  promo_id: "",
};

const CreateLead = ({ isOpen, handleCloseModal }: Props) => {
  const { refetchClients } = useClients();
  const { refetchLeads, leadChannelsData, leadPromosData } = useLeads();
  const { mutateAsync: onCreateClient } = useMutation({
    mutationFn: createClient,
    onSuccess: () => {
      refetchClients();
    },
  });
  const { mutateAsync: onCreateLead } = useMutation({
    mutationFn: createLead,
    onSuccess: () => {
      refetchLeads();
    },
  });
  const [isFormAlive, setIsFormAlive] = useState(false);
  const [leadData, setLeadData] = useState<ICreateLead>(leadDataInitial);
  const [selectedClient, setSelectedClient] = useState<IClient>();
  const [clientData, setClientData] = useState<ICreateClient>({
    title: "",
    type: null,
    persons: [{ fullname: "", role: null, deleted: false, email: "", phone: "" }],
  });

  const resetForm = () => {
    setSelectedClient(undefined);
    setIsFormAlive(false);
    setLeadData(leadDataInitial);
    setClientData({ title: "", type: null, persons: [{ fullname: "", role: null, deleted: false, email: "", phone: "" }] });
  };

  const handleLeadData = ({ value, name }: { value: string; name: keyof any }) => {
    setIsFormAlive(true);
    setLeadData({ ...leadData, [name]: value });
  };

  const handleClientData = (client: ICreateClient) => {
    setClientData(client);
  };

  const getData = (name: keyof ICreateLead) => {
    const value = leadData[name];
    if (!value) {
      return;
    }
    return value;
  };

  const isEmptyValue = ({ name, isFormAlive }: { name: keyof ICreateLead; isFormAlive: boolean }) => {
    if (!getData(name) && isFormAlive) {
      return true;
    }
    if (getData(name) === "" && isFormAlive) {
      return true;
    }
    return false;
  };

  const returnNullIfEmptyString = (value: string) => {
    if (value === "") {
      return null;
    }
    return value;
  };

  const handleSubmit = (data: ICreateLead): void => {
    if (!selectedClient) {
      const clientObj: ICreateClient = {
        title: clientData.title,
        type: null,
        persons: [
          {
            ...clientData.persons[0],
            email: clientData.persons[0].email ? clientData.persons[0].email.toLowerCase() : null,
            phone:
              clientData.persons[0].phone &&
              clientData.persons[0].phone !== "" &&
              clientData.persons[0].phone !== " " &&
              formatPhone(clientData.persons[0].phone).length > 10
                ? formatPhone(clientData.persons[0].phone)
                : null,
          },
        ],
      };

      onCreateClient(clientObj).then((response) => {
        onCreateLead({
          ...data,
          channel_id: returnNullIfEmptyString(data.channel_id ?? ""),
          promo_id: returnNullIfEmptyString(data.promo_id ?? ""),
          client_id: response.data.id,
        }).then((response) => {
          if (response.status === 200) {
            setSelectedClient(undefined);
            setLeadData(leadDataInitial);
            handleCloseModal();
          }
        });
      });
    }
    if (selectedClient) {
      onCreateLead({
        ...data,
        channel_id: returnNullIfEmptyString(data.channel_id ?? ""),
        promo_id: returnNullIfEmptyString(data.promo_id ?? ""),
        client_id: selectedClient.id,
      }).then((response) => {
        if (response.status === 200) {
          setSelectedClient(undefined);
          setLeadData(leadDataInitial);
          handleCloseModal();
        }
      });
    }
  };

  const isFormDisabled = () => {
    const isChannelId = !isEmptyValue({ name: "channel_id" as keyof ICreateLead, isFormAlive: true });
    const isTitle = !isEmptyValue({ name: "title" as keyof ICreateLead, isFormAlive: true });
    const isClientId = !isEmptyValue({ name: "client_id" as keyof ICreateLead, isFormAlive: true }) || !!selectedClient;
    const isCreatingClientValid =
      clientData.title &&
      isPhoneValid(clientData.persons[0].phone) &&
      (!clientData.persons[0].email || isEmailValid(clientData.persons[0].email));

    if (isChannelId && isTitle && (isClientId || isCreatingClientValid)) {
      return false;
    }
    return true;
  };

  return (
    <ModalCustom
      isOpen={isOpen}
      title={"Заполните информацию о лиде"}
      handleCloseModal={() => {
        resetForm();
        handleCloseModal();
      }}
      centered={false}
      width={400}
      footer={null}
    >
      <>
        <div className={styles.createLead}>
          <SelectClient setSelectedClient={setSelectedClient} selectedClient={selectedClient} />
          {!selectedClient && <ClientForm client={clientData} setClient={handleClientData} isFormAlive={isFormAlive} />}
          <InputCustom
            className={styles.createLead__input}
            placeholder={"Название лида"}
            name={"title"}
            value={getData("title")}
            onChange={(e) => handleLeadData({ value: e.target.value, name: "title" })}
            isError={isEmptyValue({ name: "title", isFormAlive })}
          />
          <SelectCustom
            options={leadPromosData.map((item) => ({ value: item.id, label: item.name }))}
            onChange={(value) => handleLeadData({ value, name: "promo_id" })}
            className={styles.createLead__select}
            placeholder="Начните вводить для поиска промо"
            width="100%"
            allowClear
            showSearch
            value={getData("promo_id")}
          />
          <SelectCustom
            options={leadChannelsData.map((item) => ({ value: item.id, label: item.name }))}
            onChange={(value) => handleLeadData({ value: value, name: "channel_id" })}
            className={styles.createLead__select}
            placeholder="Начните вводить для поиска канала"
            width="100%"
            allowClear
            showSearch
            value={getData("channel_id")}
            isError={isEmptyValue({ name: "channel_id", isFormAlive })}
          />
          <ButtonCustom
            maxWidth="190px"
            className={styles.createLead__btn}
            disabled={isFormDisabled()}
            onClick={() => handleSubmit(leadData)}
          >
            <span>Создать</span>
          </ButtonCustom>
        </div>
      </>
    </ModalCustom>
  );
};

export default CreateLead;
