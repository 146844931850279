import { Modal } from "antd";
import React, { ReactElement } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/cross.svg";
import ButtonCustom from "../ui/ButtonCustom/ButtonCustom";
import styles from "./ModalCustom.module.scss";
import "./ModalCustom__override.scss";

type Props = {
  title: string;
  key?: string;
  isOpen: boolean;
  onCancel?: () => void;
  handleCloseModal: () => void;
  width?: number;
  children?: ReactElement;
  footer?: ReactElement | null;
  centered: boolean;
  style?: React.CSSProperties;
  isMask?: boolean;
  destroyOnClose?: boolean;
};

const ModalCustom = ({
  isOpen,
  onCancel,
  key,
  handleCloseModal,
  title,
  width,
  children,
  footer,
  centered = true,
  style,
  isMask = false,
  destroyOnClose = true,
}: Props) => {
  return (
    <Modal
      key={key}
      centered={centered}
      width={width ? width : 600}
      open={isOpen}
      closeIcon={false}
      onCancel={onCancel}
      className="custom-styles"
      footer={footer}
      style={style}
      mask={isMask}
      maskClosable={false}
      destroyOnClose={destroyOnClose}
    >
      <div className={styles.modalCustom}>
        <div className={styles.modalCustom__header}>
          <p>{title}</p>
          <ButtonCustom className={styles.modalCustom__header_close} onClick={handleCloseModal} isTransparent>
            <CloseIcon />
          </ButtonCustom>
        </div>
      </div>
      <div className={styles.modalCustom__block}>{children}</div>
    </Modal>
  );
};

export default ModalCustom;
