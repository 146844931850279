import React from "react";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import styles from "./KanbanBoard.module.scss";
import { ILead } from "../../services/Leads";
import { IDeals } from "../../services/Deals";
import DealCard from "../../pages/DealsPage/components/DealCard/DealCard";
import moment from "moment";

type Props = {
  card: IDeals | ILead;
  index: number;
  onCardClick?: (id: string) => void;
  isDragDisabled: boolean;
};

const Row: React.FC<Props> = ({ card, index, onCardClick, isDragDisabled }) => (
  <Draggable draggableId={card.id} index={index} isDragDisabled={isDragDisabled}>
    {(provided: DraggableProvided) => (
      <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} onClick={() => onCardClick?.(card.id)}>
        <DealCard
          client_fullname={card.client_fullname ?? ""}
          title={card.title ?? ""}
          created_at={moment(card.created_at).format("DD/MM/YYYY")}
          //  isEditing={card.dealId === id}
          reminders_count={card.reminders_count ?? 0}
          responsible_user_id={card.created_by ?? ""}
        />
      </div>
    )}
  </Draggable>
);

export default Row;
