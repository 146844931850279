import React, { useEffect, useRef, useState } from "react";
import styles from "../../../DealsPage/components/DealCardDetail/DetailCardHistoryStatuses/DetailCardHistoryStatuses.module.scss";
import moment from "moment";
import NotFound from "../../../../components/NotFound/NotFound";
import { useQuery } from "react-query";
import { ILeadHistory, getLeadHistoryById, leadHistoryTypesEnum } from "../../../../services/Leads";
import { checkAuthenticated } from "../../../../helpers/checkAuth";
import LeadHistoryItem from "./LeadHistoryItem";
import { IReminder } from "../../../../services/Reminders";

interface IProps {
  id: string;
  remindersData?: IReminder[];
  updatingHistoryTrigger?: string;
}

const LeadHistory: React.FC<IProps> = ({ id, remindersData, updatingHistoryTrigger }) => {
  const authenticated = checkAuthenticated();
  const statusesHistoryRef = useRef<HTMLDivElement>(null);

  const [remindersList, setRemindersList] = useState<ILeadHistory[]>([]);
  const [changesList, setChangesList] = useState<ILeadHistory[]>([]);
  const [fullList, setFullList] = useState<
    {
      date: string;
      items: ILeadHistory[];
    }[]
  >([]);

  const { refetch } = useQuery({
    queryFn: () => getLeadHistoryById({ id }),
    queryKey: [`lead_${id}/history`],
    onSuccess: (data) => {
      if (!data?.data) {
        return;
      }
      setChangesList(data.data);
    },
    enabled: authenticated && id !== "" && !!id,
  });

  useEffect(() => {
    if (!updatingHistoryTrigger) {
      return;
    }
    refetch();
  }, [updatingHistoryTrigger]);

  useEffect(() => {
    if (!remindersData) {
      return;
    }
    const transformedRemindersData = remindersData.map((item) => {
      return {
        created_at: item.notify_at,
        created_by: item.created_by,
        transaction_type: leadHistoryTypesEnum.reminder,
        transaction_value: {
          reminder_text: item.comment,
        },
      };
    }) as unknown as ILeadHistory[];
    setRemindersList(transformedRemindersData);
  }, [remindersData]);

  useEffect(() => {
    const divElement = statusesHistoryRef.current;
    if (divElement) {
      divElement.scrollTop = divElement.scrollHeight;
    }
  }, [fullList]);

  useEffect(() => {
    const list: { [key: string]: ILeadHistory[] } = {};
    changesList.concat(remindersList).forEach((obj) => {
      const createdAt = moment(obj.created_at).format("DD.MM.YYYY");
      if (list[createdAt]) {
        list[createdAt] = [...list[createdAt], obj];
      } else {
        list[createdAt] = [obj];
      }
    });

    const transformedArray = Object.entries(list)
      .map(([date, items]) => ({
        date,
        items: items.sort((a, b) => {
          const dateA = moment(a.created_at);
          const dateB = moment(b.created_at);
          return dateA.diff(dateB);
        }),
      }))
      .sort((a, b) => {
        const dateA = moment(a.date, "DD.MM.YYYY");
        const dateB = moment(b.date, "DD.MM.YYYY");
        return dateA.diff(dateB);
      });

    setFullList(transformedArray);
  }, [changesList, remindersList]);

  return (
    <>
      <div className={styles.LeadHistory__border} />
      <div className={styles.LeadHistory} ref={statusesHistoryRef}>
        {fullList.map((item, index) => {
          return (
            <div className={styles.LeadHistory__block} key={item.date + index}>
              <p className={styles.LeadHistory__date}>{item.date}</p>
              {item.items.map((historyItem) => (
                <LeadHistoryItem historyItem={historyItem} />
              ))}
            </div>
          );
        })}
        {!Array.from(fullList).length && <NotFound title="История изменений отсутствует" />}
      </div>
    </>
  );
};

export default LeadHistory;
