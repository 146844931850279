import { useState } from "react";
import styles from "./EmailSampleModal.module.scss";
import ModalCustom from "../../../../components/ModalCustom/ModalCustom";
import TextArea from "antd/es/input/TextArea";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import { useMutation, useQuery } from "react-query";
import { getShopNotificationSettingsById, postShopNotificationSettingsById } from "../../../../services/Shops";
import { message } from "antd";

type Props = {
  shopId: string;
  handleClose: () => void;
};

const EmailSampleModal = ({ shopId, handleClose }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [text, setText] = useState<string>("");

  const { data } = useQuery({
    queryFn: () => getShopNotificationSettingsById({ shop_id: shopId }),
    queryKey: [`isAvalableData-${shopId}`],
    onSuccess: (data) => {
      if (!data?.data) {
        return;
      }
      setText(data.data.additional_footer_text);
    },
    enabled: !!shopId && shopId !== "",
  });

  const { mutateAsync: onAdditionalFooterSubmit } = useMutation({
    mutationFn: postShopNotificationSettingsById,
    onSuccess: () => {
      messageApi.open({
        type: "success",
        content: "Подпись успешно изменена",
        duration: 5,
      });
    },
    onError: () => {
      messageApi.open({
        type: "error",
        content: "Не удалось изменить подпись",
        duration: 5,
      });
    },
  });

  return (
    <>
      {contextHolder}
      <ModalCustom
        title={"Изменение подписи рассылки"}
        isOpen={shopId !== ""}
        handleCloseModal={handleClose}
        centered
        width={600}
        footer={null}
        onCancel={handleClose}
        destroyOnClose
      >
        <div className={styles.EmailSampleModal}>
          <TextArea value={text} onChange={(e) => setText(e.target.value)} rows={10} placeholder="Введите текст подписи" />
          <ButtonCustom
            className={styles.EmailSampleModal__sendBtn}
            onClick={() => {
              if (data?.data) {
                onAdditionalFooterSubmit({
                  shop_id: shopId,
                  additional_footer_text: text,
                  allow_override_for_deal: data?.data.allow_override_for_deal,
                }).then((response) => {
                  if (response.status === 200) {
                    handleClose();
                  }
                });
              }
            }}
            maxWidth="170px"
          >
            <span>Сохранить</span>
          </ButtonCustom>
        </div>
      </ModalCustom>
    </>
  );
};

export default EmailSampleModal;
