import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl5052 } from "../env";

export interface IBlank {
  id?: string;
  file_id: string;
  start: string;
  end: string;
  status?: string;
  sold_count?: number;
}

export interface IBlanks {
  data: {
    count: number;
    items: IBlank[];
  };
  status: number;
}

export interface IBlanksPages {
  offset?: number;
  limit?: number;
  room_type_id?: string;
}

export async function getBlanks(blankPages: IBlanksPages): Promise<IBlanks> {
  const offset = "?offset=" + blankPages.offset;
  const limit = "&limit=" + blankPages.limit;
  return axios
    .get(baseUrl5052 + `blank${offset + limit}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getBlanks:", err.response);
      return err.response;
    });
}

export async function addBlank(blank: IBlank): Promise<IBlanks> {
  return axios
    .post(baseUrl5052 + "blank", blank, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> addBlank:", err.response);
      return err.response;
    });
}

/**
 * Мы можем удалить только бланки, которые еще не опубликованы и имеют поле status в значении pending
 */
export async function deleteBlank(blank_id: string): Promise<IBlanks> {
  return axios
    .delete(baseUrl5052 + `blank/${blank_id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> deleteBlank:", err.response);
      return err.response;
    });
}
