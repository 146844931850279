import { useEffect, useState } from "react";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import content, { onlyAdmin, onlyLPR, Role } from "../../../../settings/content";
import styles from "./ShopsCards.module.scss";
import "./ShopsCards_override.scss";
import { useMutation, useQuery } from "react-query";
import { Modal, Table, message } from "antd";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cross.svg";
import {
  IAddShop,
  IEmailBinding,
  IGetPhoneBinding,
  IGetShops,
  deleteShop,
  getEmailBinding,
  getPhoneBinding,
  getShops,
} from "../../../../services/Shops";
import CreateShop from "../CreateShop/CreateShop";
import EditShop from "../EditShop/EditShop";
import OrganizationHeader from "../../OrganizationHeader/OrganizationHeader";
import { NoticeType } from "antd/es/message/interface";
import ValidateComponentByRole from "../../../../components/ValidateComponentByRole/ValidateComponentByRole";
import { useCities } from "../../../../context/useCities";
import ModalCustom from "../../../../components/ModalCustom/ModalCustom";
import MailingModal from "../MailingModal/MailingModal";
import ConnectionIdModal from "../ConnectionIdModal/ConnectionIdModal";
import AddPhoneModal from "../AddPhoneModal/AddPhoneModal";
import { useUser } from "../../../../context/useUser";
import QrModal from "../QrModal/QrModal";
import MailingEmailModal from "../MailingEmailModal/MailingEmailModal";
import AddEmailModal from "../AddEmailModal/AddEmailModal";
import EmailDeleteModal from "../EmailDeleteModal/EmailDeleteModal";
import { columns } from "./constants";
import Departments from "../Departments/Departments";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as ConvertIcon } from "../../../../assets/icons/convert.svg";
import { ReactComponent as WhatsappIcon } from "../../../../assets/icons/whatsapp-svgrepo-com.svg";
import { tableLocale } from "../../../../helpers/tableLocale";
import EmailSampleModal from "../EmailSampleModal/EmailSampleModal";

type departmentsLength = {
  length: number;
  shop_id: string;
};

const enum modalTypes {
  CREATE = "create",
  EDIT = "edit",
  EMPTY = "",
}

export interface IProps {}

export interface IShop {
  id?: string;
  name: string;
  address?: string;
  department?: string;
  city_id: string;
}

const initialState: IAddShop = {
  name: "",
  address: "",
  city_id: "",
  // department: "",
};

export interface ShopCardsDataType {
  name: string;
  city: JSX.Element;
  address: string;
  departments: JSX.Element;
  contacts: JSX.Element;
  actions: JSX.Element;
}

const ShopsCards: React.FC<IProps> = ({}) => {
  const { cities } = useCities();
  const { user } = useUser();

  const [search, setSearch] = useState<string>("");
  const [shopsData, setShopsData] = useState<IGetShops>();
  const [open, setOpen] = useState("");
  const [data, setData] = useState<IAddShop>(initialState);
  const [deleteModalId, setDeleteModalId] = useState("");
  const [mailingModalId, setMailingModalId] = useState("");
  const [emailMailingModalId, setEmailMailingModalId] = useState("");
  const [connectionIdModal, setConnectionIdModal] = useState("");
  const [deletePhoneModal, setDeletePhoneModal] = useState("");
  const [addPhoneModal, setAddPhoneModal] = useState("");
  const [qrModal, setQrModal] = useState("");
  const [addEmailConnectionModal, setAddEmailConnectionModal] = useState("");
  const [deleteEmailModal, setDeleteEmailModal] = useState("");
  const [emailDeleteBindingModal, setEmailDeleteBindingModal] = useState("");
  const [shopEmailSampleModal, setShopEmailSampleModal] = useState("");

  const [messageApi, contextHolder] = message.useMessage();
  const [departmentsData, setDepartmentsData] = useState<departmentsLength[]>([]);
  const [departmentsLength, setDepartmentsLength] = useState<number>();
  const [phoneBinding, setPhoneBinding] = useState<IGetPhoneBinding[]>([]);
  const [emailBinding, setEmailBinding] = useState<IEmailBinding[]>([]);

  const {
    data: shops,
    refetch,
    isLoading,
    isRefetching,
  } = useQuery({
    queryFn: () => getShops(search),
    queryKey: ["shops"],
  });

  const { data: phoneBindingData, refetch: refetchPhoneBindingData } = useQuery({
    queryFn: () => getPhoneBinding({}),
    queryKey: [`phonebinding`],
    enabled: [...onlyLPR, ...onlyAdmin].includes(user.role as Role),
  });

  const { data: emailBindingData, refetch: refetchEmailBindingData } = useQuery({
    queryFn: () => getEmailBinding({}),
    queryKey: [`emailBinding`],
    enabled: [...onlyLPR, ...onlyAdmin].includes(user.role as Role),
    onSuccess: (data) => {
      if (!Array.isArray(data.data)) {
        return;
      }
      setEmailBinding(data.data);
    },
  });

  const { mutateAsync: onDeleteShop } = useMutation({
    mutationFn: deleteShop,
  });

  const refetchShopsAndClose = () => {
    refetch();
    setData(initialState);
    setOpen(modalTypes.EMPTY);
  };

  const handleCloseModal = () => {
    setData(initialState);
    setOpen(modalTypes.EMPTY);
  };

  useEffect(() => {
    if (!phoneBindingData?.data) {
      return;
    }
    setPhoneBinding(phoneBindingData.data);
  }, [phoneBindingData]);

  useEffect(() => {
    refetch();
  }, [refetch, search]);

  useEffect(() => {
    if (!shops) {
      return;
    }
    if (!Array.isArray(shops?.data)) {
      return;
    }
    setShopsData(shops);
  }, [shops]);

  useEffect(() => {
    if (!deleteModalId) {
      return;
    }
    const getLength = departmentsData.find((department) => (deleteModalId === department.shop_id ? department.length : null))?.length;
    setDepartmentsLength(getLength);
  }, [deleteModalId, departmentsData]);

  const editShop = (shop: IAddShop, e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setData(shop);
    setOpen(modalTypes.EDIT);
  };

  const onSuccessEmailAdding = (email: string) => {
    alert("success", `Привязка Email (${email}) успешно завершена`);
  };

  const deleteShopHandler = async (shop_id: string) => {
    onDeleteShop(shop_id).then((response) => {
      if (response.status === 200) {
        alert("success", content.alert_messages.shops.delete.success_shop);
        refetch();
        setDeleteModalId("");
      } else {
        alert("error", content.alert_messages.shops.delete.error_shop);
      }
    });
  };

  const getDepartmentsLength = (length: number, shop_id: string) =>
    setDepartmentsData((prev) => [...prev, { length: length, shop_id: shop_id }]);

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const onRefetch = () => {
    refetchPhoneBindingData();
    refetchEmailBindingData();
  };

  return (
    <div className={styles.shops}>
      {contextHolder}
      <div className={styles.shopsCards__header_wrapper}>
        <div className={styles.shopsCards__add}>
          <ValidateComponentByRole requiredRoles={onlyAdmin}>
            <ButtonCustom
              onClick={() => setOpen(modalTypes.CREATE)}
              className={styles.shopsCards__add_button}
              maxWidth="fit-content"
              disabled={shops?.status !== 200}
            >
              <span>{content.organizations.shops.add_shop_btn}</span>
            </ButtonCustom>
          </ValidateComponentByRole>
        </div>
        <OrganizationHeader title={""} placeholder={content.organizations.shops.search_placeholder} search={search} setSearch={setSearch} />
      </div>

      {shopsData && (
        <Table
          id="shops-list-table"
          columns={columns}
          dataSource={shopsData.data.map((shop) => ({
            name: shop.name,
            city: (() => {
              const currCity = cities.find((item) => item.id === shop.city_id);
              return (
                <>
                  <p className={styles.shopsCards__city}>{currCity ? currCity.name : "Город не выбран"}</p>
                  <p className={styles.shopsCards__time_zone}>{currCity ? `GMT+${currCity?.gmt_delta}` : "---"}</p>
                </>
              );
            })(),
            address: shop.address,
            departments: <Departments shop_id={shop.id} getDepartmentsLength={getDepartmentsLength} />,
            contacts: (() => {
              const phoneBindingTable = Array.isArray(phoneBinding) ? phoneBinding.filter((item) => item.shop_id === shop.id) : [];
              const emailBindingTable = Array.isArray(emailBinding) ? emailBinding.filter((item) => item.shop_id === shop.id) : [];
              return (
                <>
                  <div className={styles.shopsCards__street}>
                    {phoneBindingTable && phoneBindingTable.length ? (
                      phoneBindingTable.map((item) => (
                        <div key={item.id} className={styles.shopsCards__phoneBinding_item}>
                          {item.phone}
                        </div>
                      ))
                    ) : (
                      <span style={{ color: "#333333ae" }}>Номер телефона не привязан</span>
                    )}
                  </div>
                  <div className={styles.shopsCards__street}>
                    {emailBindingTable && emailBindingTable.length ? (
                      emailBindingTable.map((item) => (
                        <div
                          key={item.id}
                          className={styles.shopsCards__phoneBinding_item}
                          style={{ color: "#4073ac", fontWeight: "bold" }}
                        >
                          {item.email}
                        </div>
                      ))
                    ) : (
                      <span style={{ color: "#333" }}>Почта не привязана</span>
                    )}
                  </div>
                </>
              );
            })(),
            actions: (() => {
              return (
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <ValidateComponentByRole requiredRoles={onlyAdmin}>
                    <div onClick={(e) => editShop(shop, e)}>
                      <EditIcon />
                    </div>
                    <div onClick={() => setDeleteModalId(shop.id)}>
                      <DeleteIcon />
                    </div>
                  </ValidateComponentByRole>
                  <ValidateComponentByRole requiredRoles={[...onlyLPR, ...onlyAdmin]}>
                    <div onClick={() => setEmailMailingModalId(shop.id)}>
                      <ConvertIcon width={22} height={22} style={{ paddingTop: "3px" }} />
                    </div>
                    <div onClick={() => setMailingModalId(shop.id)}>
                      <WhatsappIcon width={20} height={20} className={styles.shopsCards__whatsapp} />
                    </div>
                  </ValidateComponentByRole>
                </div>
              );
            })(),
          }))}
          pagination={false}
          locale={tableLocale}
          key={"shops-list-table"}
          loading={isLoading || isRefetching}
        />
      )}
      <Modal
        className={"custom_shop_modal"}
        open={open !== modalTypes.EMPTY}
        centered
        mask={false}
        onCancel={() => handleCloseModal()}
        closeIcon={false}
        footer={false}
        width={340}
        destroyOnClose={open === modalTypes.CREATE}
        maskClosable={false}
      >
        <div className={styles.shopsCards__header}>
          <span>{content.organizations.shops.modal_title}</span>
          <ButtonCustom isTransparent maxWidth="20px" className={styles.shopsCards__header_close} onClick={() => handleCloseModal()}>
            <CloseIcon />
          </ButtonCustom>
        </div>
        {open === modalTypes.CREATE ? <CreateShop refetchShopsAndClose={refetchShopsAndClose} /> : null}
        {open === modalTypes.EDIT ? <EditShop data={data} refetchShopsAndClose={refetchShopsAndClose} /> : null}
      </Modal>
      <Modal
        className={"custom_shop_modal"}
        open={deleteModalId !== ""}
        centered
        onCancel={() => handleCloseModal()}
        closeIcon={false}
        footer={false}
        width={340}
        destroyOnClose={open === modalTypes.CREATE}
        maskClosable={false}
      >
        <div className={styles.shopsCards__warning}>
          {departmentsLength ? (
            <p>{content.organizations.shops.warning.with_departments}</p>
          ) : (
            <p>{content.organizations.shops.warning.title}</p>
          )}
          <div className={styles.shopsCards__warning_btns}>
            <ButtonCustom
              disabled={departmentsLength ? true : false}
              onClick={() => deleteShopHandler(deleteModalId)}
              className={styles.shopsCards__warning_close}
            >
              <span>{content.organizations.shops.warning.delete}</span>
            </ButtonCustom>
            <ButtonCustom onClick={() => setDeleteModalId("")} isTransparent>
              <span>{content.organizations.shops.warning.cancel}</span>
            </ButtonCustom>
          </div>
        </div>
      </Modal>
      <ModalCustom
        title={"Управление рассылкой магазина"}
        isOpen={mailingModalId !== ""}
        handleCloseModal={() => setMailingModalId("")}
        centered
        width={350}
        footer={null}
        onCancel={() => setMailingModalId("")}
        destroyOnClose
      >
        <MailingModal
          mailingModalId={mailingModalId}
          shop={shopsData?.data.find((item) => item.id === mailingModalId)}
          closeModal={() => setMailingModalId("")}
          refetchShops={onRefetch}
          openConnectionModal={setConnectionIdModal}
          openDeletePhoneModal={setDeletePhoneModal}
          openAddPhoneModal={setAddPhoneModal}
          openQrModal={setQrModal}
          setEmailSampleModal={setShopEmailSampleModal}
        />
      </ModalCustom>
      <ModalCustom
        title={"Отключение рассылки у магазинов"}
        isOpen={connectionIdModal !== ""}
        handleCloseModal={() => setConnectionIdModal("")}
        centered
        width={450}
        footer={null}
        onCancel={() => setConnectionIdModal("")}
        destroyOnClose
      >
        <ConnectionIdModal
          connectionId={connectionIdModal}
          onCancel={() => setConnectionIdModal("")}
          refetchShops={onRefetch}
          onDeleteSuccess={() => {
            setConnectionIdModal("");
            setDeletePhoneModal("");
          }}
          mailingModalId={mailingModalId}
        />
      </ModalCustom>
      <ModalCustom
        title={"Удаление номера телефона"}
        isOpen={deletePhoneModal !== ""}
        handleCloseModal={() => setDeletePhoneModal("")}
        centered
        width={450}
        footer={null}
        onCancel={() => setDeletePhoneModal("")}
        destroyOnClose
      >
        <ConnectionIdModal
          connectionId={deletePhoneModal}
          onCancel={() => setDeletePhoneModal("")}
          onDeleteSuccess={() => {
            setConnectionIdModal("");
            setDeletePhoneModal("");
          }}
          refetchShops={onRefetch}
          isCheckboxes={false}
          mailingModalId={mailingModalId}
        />
      </ModalCustom>
      <ModalCustom
        title={"Подключение номера"}
        isOpen={addPhoneModal !== ""}
        handleCloseModal={() => setAddPhoneModal("")}
        centered
        width={300}
        footer={null}
        onCancel={() => setAddPhoneModal("")}
        destroyOnClose
      >
        <AddPhoneModal closeModal={() => setAddPhoneModal("")} mailingModalId={mailingModalId} />
      </ModalCustom>
      <ModalCustom
        title={"Привязка номера к сервису рассылки"}
        isOpen={qrModal !== ""}
        handleCloseModal={() => setQrModal("")}
        centered
        width={350}
        footer={null}
        onCancel={() => setQrModal("")}
        destroyOnClose
      >
        <QrModal connection_id={qrModal} closeModal={() => setQrModal("")} />
      </ModalCustom>
      <ModalCustom
        title={"Управление почтовой рассылкой магазина"}
        isOpen={emailMailingModalId !== ""}
        handleCloseModal={() => setEmailMailingModalId("")}
        centered
        width={400}
        footer={null}
        onCancel={() => setEmailMailingModalId("")}
        destroyOnClose
      >
        <MailingEmailModal
          emailMailingModalId={emailMailingModalId}
          shop={shopsData?.data.find((item) => item.id === emailMailingModalId)}
          closeModal={() => setEmailMailingModalId("")}
          refetchShops={onRefetch}
          openAddEmailModal={setAddEmailConnectionModal}
          openDeleteEmailBindingModal={setEmailDeleteBindingModal}
          openDeleteEmailConnectionModal={setDeleteEmailModal}
          setEmailSampleModal={setShopEmailSampleModal}
        />
      </ModalCustom>
      <ModalCustom
        title={"Подключение почты"}
        isOpen={addEmailConnectionModal !== ""}
        handleCloseModal={() => setAddEmailConnectionModal("")}
        centered
        width={320}
        footer={null}
        onCancel={() => setAddEmailConnectionModal("")}
        destroyOnClose
      >
        <AddEmailModal
          closeModal={() => setAddEmailConnectionModal("")}
          emailMailingModalId={emailMailingModalId}
          onSuccessEmailAdding={onSuccessEmailAdding}
        />
      </ModalCustom>

      <ModalCustom
        title={"Отключение e-mail рассылки у магазинов"}
        isOpen={emailDeleteBindingModal !== ""}
        handleCloseModal={() => setEmailDeleteBindingModal("")}
        centered
        width={450}
        footer={null}
        onCancel={() => setEmailDeleteBindingModal("")}
        destroyOnClose
      >
        <EmailDeleteModal
          emailConnectionId={emailDeleteBindingModal}
          onCancel={() => setEmailDeleteBindingModal("")}
          refetchShops={onRefetch}
          onDeleteSuccess={() => {
            setEmailDeleteBindingModal("");
            setDeleteEmailModal("");
          }}
          emailMailingModalId={emailMailingModalId}
        />
      </ModalCustom>
      <ModalCustom
        title={"Удаление почты"}
        isOpen={deleteEmailModal !== ""}
        handleCloseModal={() => setDeleteEmailModal("")}
        centered
        width={450}
        footer={null}
        onCancel={() => setDeleteEmailModal("")}
        destroyOnClose
      >
        <EmailDeleteModal
          emailConnectionId={deleteEmailModal}
          onCancel={() => setDeleteEmailModal("")}
          onDeleteSuccess={() => {
            setEmailDeleteBindingModal("");
            setDeleteEmailModal("");
          }}
          refetchShops={onRefetch}
          isCheckboxes={false}
          emailMailingModalId={emailMailingModalId}
        />
      </ModalCustom>
      <EmailSampleModal shopId={shopEmailSampleModal} handleClose={() => setShopEmailSampleModal("")} />
    </div>
  );
};

export default ShopsCards;
