import styles from "../AddEmailModal.module.scss";
type Props = {
  list: {
    title: string;
    img: string;
  }[];
};

const EmailFaqContent = ({ list }: Props) => {
  return (
    <ol className={styles.list}>
      {list.map((item) => (
        <li className={styles.list_item}>
          <span>{item.title}</span>
          {item.img !== "" && <img className={styles.list_item_img} src={item.img} alt="1" />}
        </li>
      ))}
    </ol>
  );
};

export default EmailFaqContent;
