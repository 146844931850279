import { ChartBar, ChartLine, ClipboardText, CrownSimple, HouseSimple, Scroll, SuitcaseSimple, UserList, Users } from "phosphor-react";

export const enum Role {
  ORGANIZATION_ADMIN = "organization_admin",
  ORGANIZATION_MANAGER = "organization_manager",
  SHOP_MANAGER = "shop_manager",
  DEPARTMENT_MANAGER = "department_manager",
  DEPARTMENT_EMPLOYEE = "department_employee",
}

export const enum PageName {
  main_page = "main_page",
  deals_page = "deals_page",
  organization_page = "organization_page",
}

export const allRoles = [
  Role.ORGANIZATION_ADMIN,
  Role.DEPARTMENT_MANAGER,
  Role.ORGANIZATION_MANAGER,
  Role.SHOP_MANAGER,
  Role.DEPARTMENT_EMPLOYEE,
];

export const allRolesWithLabels = [
  { value: Role.DEPARTMENT_EMPLOYEE, title: "Сотрудник отдела" },
  { value: Role.DEPARTMENT_MANAGER, title: "Руководитель отдела" },
  { value: Role.ORGANIZATION_ADMIN, title: "Администратор организации" },
  { value: Role.ORGANIZATION_MANAGER, title: "Менеджер организации" },
  { value: Role.SHOP_MANAGER, title: "Руководитель магазина" },
];

export const onlyAdmin = [Role.ORGANIZATION_ADMIN];
export const onlyLPR = [Role.DEPARTMENT_MANAGER, Role.ORGANIZATION_MANAGER, Role.SHOP_MANAGER];
export const onlyEmployee = [Role.DEPARTMENT_EMPLOYEE];

const content = {
  pages: {
    [PageName.main_page]: { requiredRole: allRoles },
    [PageName.deals_page]: { requiredRole: onlyEmployee },
    [PageName.organization_page]: { requiredRole: [...onlyAdmin, ...onlyLPR] },
  },
  gallery: {
    title: "Галерея проектов",
    header_btn: "Проекты для вдохновения",
    settings: {
      types: [
        {
          id: "coll",
          icon: true,
          disabled: true,
          mode: "multiple",
          placeholder: "Коллекция",
        },
        {
          id: "room_type",
          icon: true,
          disabled: false,
          mode: "multiple",
          placeholder: "Тип помещения",
        },
        {
          id: "color",
          icon: true,
          disabled: true,
          mode: "multiple",
          placeholder: "Цвет",
        },
      ],
      search: {
        id: "search",
        text: "Искать",
        resetText: "Сбросить",
        icon: false,
      },
    },
    show_all: "Посмотреть все проекты",
    error_on_load: "Не удалось загрузить панораму, или она еще не загрузилась",
    load_warning: "Для получения панорамы более высокого качества необходимо подождать!",
    metadata_error: "Не удалось загрузить панораму по адресу: ",
    detail_card: {
      title: "Название проекта",
      select_btn_copy_title: "Копирование проекта",
      create_projec_button_title: "Создать на основании этого проекта",
      select_btn_create: "Создать сделку",
      select_btn_select: "Выбрать сделку",
      select_modal_title: "Создать проект",
      choose_modal_title: "Выберите сделку",
      copy_btn: "Копировать",
    },
    statusBtns: {
      published: "Отобразить проект в галерее",
      hidden: "Скрыть проект из галереи",
    },
    public: {
      header: {
        logo: "Логотип компании",
        title: "ГОТОВЫЕ ИНТЕРЬЕРНЫЕ РЕШЕНИЯ",
      },
    },
  },

  profile_settings: {
    header: {
      title: "Профиль",
    },
    buttons: {
      save: "Сохранить",
      change_user: "Сменить пользователя",
      logout: "Выйти",
    },
    block_titles: {
      name: "Фамилия имя",
      change_password: "Изменить пароль",
    },
    inputs: {
      name: "Иванов Иван",
      olg_password: "Старый пароль",
      new_password: "Новый пароль",
      repeat_password: "Повторите пароль",
    },
    users_list: {
      me: "Вы",
      available_users: "Доступные пользователи",
      exit: "Перейти",
      stay: "Остаться",
      text: "Вы уверены, что хотите перейти в другой аккаунт?",
    },
  },

  deals: {
    header: {
      block_title: "Задайте имя Вашего проекта",
      create_deal: "Создать сделку",
      search_deals: "Поиск сделки",
      show_my_deals: "Показать только мои сделки",
      show_all_deals: "Показать все сделки",
    },
    deal_card_create: {
      title: "Заполните информацию о сделке",
      submit: "Создать",
      hint: " Поля, обязательные для заполнения",
      dealCardInputs: [
        {
          id: "client_phone",
          type: "mask",
          name: "client_phone",
          placeholder: "+7",
          mask: "+7 (999) 999-99-99",
          maskChar: " ",
          required: true,
          validate: true,
        },
        {
          id: "client_email",
          type: "",
          name: "client_email",
          placeholder: "Email",
          required: false,
          validate: true,
        },
        {
          id: "client_fullname",
          type: "",
          name: "client_fullname",
          placeholder: "ФИО клиента",
          required: true,
          validate: true,
        },
        { id: "surface_area", type: "", name: "surface_area", placeholder: "Площадь", required: false, validate: false },
        { id: "estate_name", type: "", name: "estate_name", placeholder: "ЖК", required: false, validate: false },
        { id: "renovation_stage", type: "", name: "renovation_stage", placeholder: "Этап ремонта", required: false, validate: false },
        { id: "room_type", type: "", name: "room_type", placeholder: "Тип помещения", required: false, validate: false },
      ],
    },
    client_card: {
      title: "Карточка клиента",
      name: "ФИО",
      phone: "Телефон",
      email: "Email",
      btn_cancel: "Отменить",
      btn_save: "Сохранить",
    },
    detail_deal_card: {
      status_title: "Статус (этап воронки)",
      save_button: "Сохранить сделку",
      dealCardDetailedColumns: [
        [
          { id: "name_1", name: "client_fullname", placeholder: "Имя фамилия клиента" },
          { id: "phone_1", name: "client_phone", placeholder: "Номер телефона" },
          { id: "email_1", name: "client_email", placeholder: "Почта" },
          { id: "title_1", name: "title", placeholder: "Название сделки" },
        ],
        [
          { id: "square_1", name: "surface_area", placeholder: "Общая площадь" },
          { id: "zhk_1", name: "estate_name", placeholder: "ЖК" },
          { id: "stage_1", name: "renovation_stage", placeholder: "Этап ремонта" },
        ],
      ],
      buttons: {
        title: {
          project_name: "Имя проекта",
          project_title: "Задайте имя Вашего проекта",
          add_goods: "Дополнительные товары",
          create_project: "Создать проект",
          show_gallery: "Посмотреть галерею",
          open_project: "Открыть файл проекта с ПК",
          create_apartment: "Создать из квартиротеки",
        },
      },
      tabs: {
        history: {
          label: "История",
        },
        projects: {
          label: "Проекты и сметы",
          label_create: "- проект создан",
          button_block: {
            edit: "Редактировать",
            show: "Посмотреть",
            estimate: "Открыть смету",
            panorama: "Открыть панораму",
            sendPanorama: "Отправить панорамы клиенту",
          },
          history: {
            title: "Проект изменен",
          },
          program: {
            title: "Открыть в программе",
            path: "",
          },
          not_found: "Проектов не найдено",
        },
      },
      comment: {
        label: "Комментарий",
        placeholder: "Введите сообщение",
      },
      history_statuses: {
        label: "Перевод сделки в статус",
      },
      placeholder: {
        newProjectName: "Введите название проекта",
        newProjectRoomType: "Тип помещения",
      },
      add_leads: {
        title: "Переадресация на другой отдел",
        send: "Отправить",
        placehoder: "Введите комментарий",
        select_department: "Выберите отдел",
      },
    },
    board: {
      not_found: "Сделок не найдено",
      show_all: "Посмотреть все",
    },
  },

  organizations: {
    tabs: {
      tabShops: "Магазины",
      tabStaff: "Сотрудники",
      tabKeys: "Рабочие места",
    },

    shops: {
      title: "Магазины",
      add_shop_btn: "Добавить магазин",
      save: "Сохранить",
      modal_title: "Заполните информацию о магазине",
      departments_not_set: "Отделы не указаны",
      empty_departments: "Отделы отсутсвуют",
      new_department: "Новый отдел",
      search_placeholder: "Введите адрес магазина",
      warning: {
        title: "Вы уверены, что хотите удалить магазин?",
        delete: "Удалить",
        cancel: "Отменить",
        with_departments: "В выбранном магазине есть отделы, удаление невозможно",
      },
      inputs_create: [
        { id: "name_id", name: "name", placeholder: "Название", title: "Название организации / магазина", enabled: true, options: false },
        { id: "city_id", name: "city_id", placeholder: "Выберите город", title: "Город", enabled: true, options: false },
        { id: "gmt_delta", name: "gmt_delta", placeholder: "Часовой пояс", title: "Часовой пояс", enabled: true, options: false },
        { id: "address_id", name: "address", placeholder: "Адрес", title: "Адрес магазина / офиса", enabled: true, options: false },
        { id: "department_id", name: "department", placeholder: "Название отдела", title: "Отдел", enabled: true, options: true },
      ],
      inputs_edit: [
        {
          id: "name_id",
          name: "name",
          placeholder: "Название",
          title: "Название организации / магазина",
          enabled: true,
          options: false,
          hidden: false,
        },
        { id: "city_id", name: "city_id", placeholder: "Выберите город", title: "Город", enabled: true, options: false },
        { id: "gmt_delta", name: "gmt_delta", placeholder: "Часовой пояс", title: "Часовой пояс", enabled: true, options: false },
        {
          id: "address_id",
          name: "address",
          placeholder: "Адрес",
          title: "Адрес магазина / офиса",
          enabled: true,
          options: false,
          hidden: false,
        },
        {
          id: "department_id",
          name: "department",
          placeholder: "Название отдела",
          title: "Отдел",
          enabled: true,
          options: true,
          hidden: false,
        },
      ],
    },
    stuff: {
      title: "Заполните информацию о сотруднике",
      title_edit: "Редактирование информации о сотруднике",
      password_btn: "Сгенерировать",
      detail_card: {
        title: "Заполните информацию о сотруднике",
        data_input: [
          { title: "Номер телефона", name: "phone", placeholder: "+7", mask: "+7 (999) 999-99-99" },
          { title: "Логин", name: "login", placeholder: "Логин", mask: "", type: "text" },
          { title: "Email", name: "email", placeholder: "admin@mail.ru", mask: "E-mail", type: "text" },
          { title: "ФИО", name: "name", placeholder: "ФИО", mask: "", type: "text" },
          { title: "Пароль", name: "password", placeholder: "Пароль", mask: "", type: "password" },
        ],

        shop: {
          title: "Магазин",
          placeholder: "Адрес магазина",
        },
        department: {
          title: "Отдел",
          placeholder: "Название отдела",
        },
        roles: {
          title: "Пользовательская роль",
          placeholder: "Роль",
        },
        footer: {
          save: "Сохранить",
          block: "Заблокировать доступ",
        },
      },
      search_placeholder: "Введите фамилию сотрудника",
      headers: [
        { id: "stuff_name", name: "Имя Фамилия" },
        { id: "stuff_login", name: "Email" },
        { id: "stuff_phone", name: "Номер телефона" },
        { id: "stuff_shop", name: "Магазин" },
        { id: "stuff_department", name: "Отдел" },
        { id: "stuff_role", name: "Роль" },
        { id: "stuff_empty", name: "" },
      ],
      footer: {
        add: "Добавить сотрудника",
        excel: "Выгрузить в Excel",
      },
    },
    keys: {
      placeholder: "Введите название рабочего места",
      name: "Название / номер",
      department_title: "Название отдела",
      id_key: "ID ключа",
      key_not_set: "Ключ не установлен",
      key_confirm: "Ок",
      add_workstation: {
        btn_title: "Добавить рабочее место",
        modal_title: "Заполните информацию о рабочем месте",
        name: "Название/номер рабочего места",
        department: "Название отдела",
        key: "Номер ключа",
        add_btn_title: "Создать",
      },
    },
  },

  control: {
    leads: {
      from: "От кого:",
      comment: "Комментарий:",
      button_txt: "Создать сделку",
      not_leads: "Входящих лидов нет",
    },
    clients_list: {
      table_columns: {
        fullname: "ФИО клиента",
        phone: "Телефон",
        email: "Почта",
      },
      clear_filters: "Удалить фильтры",
      excel_btn: "Скачать excel",
      ok_btn: "Ок",
      cancle_btn: "Отмена",
      sort_a_z: "Сортировка А → Я",
      sort_z_a: "Сортировка Я → А",
      search_placeholder: "Начните вводить...",
    },
    controlButtons: [
      {
        title: "Главная",
        description: "",
        name: "invoices",
        notificationCount: 0,
        requiredRole: allRoles,
        type: "url",
        path: "/",
        icon: <HouseSimple size={24} />,
      },

      {
        title: "Сделки",
        description: "",
        name: "invoices",
        notificationCount: 0,
        requiredRole: [...onlyLPR, ...onlyEmployee],
        type: "url",
        path: "/deals",
        icon: <SuitcaseSimple size={24} />,
      },

      {
        title: "Лиды",
        description: "",
        name: "invoices",
        notificationCount: 0,
        requiredRole: allRoles,
        type: "url",
        path: "/leads",
        icon: <CrownSimple size={24} />,
      },

      {
        title: "Сотрудники и магазины",
        description: "Информация о счетах",
        name: "invoices",
        notificationCount: 0,
        requiredRole: [...onlyAdmin, ...onlyLPR],
        type: "url",
        path: "/organization?tab=shops",
        icon: <UserList size={24} />,
      },

      {
        title: "Счета",
        description: "Информация о счетах",
        name: "invoices",
        notificationCount: 0,
        requiredRole: onlyLPR,
        type: "default",
        icon: <Scroll size={24} />,
      },

      {
        title: "Карточки клиентов",
        description: "",
        name: "register",
        notificationCount: 0,
        requiredRole: [...onlyLPR, Role.DEPARTMENT_EMPLOYEE, Role.ORGANIZATION_ADMIN],
        type: "default",
        icon: <Users size={24} />,
      },

      {
        title: "Аналитика по товарам",
        description: "",
        name: "analytic_products",
        notificationCount: 0,
        requiredRole: [...onlyLPR, Role.ORGANIZATION_ADMIN],
        type: "default",
        icon: <ChartLine size={24} />,
      },

      {
        title: "Аналитика по проектам",
        description: "Аналитика по вашим проектам",
        name: "analytic_projects",
        notificationCount: 0,
        requiredRole: allRoles,
        type: "default",
        icon: <ChartLine size={24} />,
      },

      {
        title: "Статистика по обучению (тренер)",
        description: "Информация об обучении сотрудников с тренером",
        name: "statistics_coach",
        notificationCount: 0,
        requiredRole: [...onlyLPR, ...onlyEmployee],
        type: "default",
        icon: <ChartBar size={24} />,
      },

      {
        title: "Статистика по обучению (платформа)",
        description: "Информация об обучении сотрудников на платформе",
        name: "statistics_platform",
        notificationCount: 0,
        requiredRole: allRoles,
        type: "default",
        icon: <ChartBar size={24} />,
      },

      {
        title: "Аналитика по активным дням",
        description: "Аналитика по активным дням в разрезе по модулям",
        name: "analytic_active_days",
        notificationCount: 0,
        requiredRole: [...onlyLPR, Role.ORGANIZATION_ADMIN],
        type: "default",
        icon: <ChartLine size={24} />,
      },
      {
        title: "Бланки",
        description: "Бланки",
        path: "/blanks",
        name: "blanks",
        notificationCount: 0,
        requiredRole: [...onlyAdmin, ...onlyLPR],
        type: "url",
        icon: <ClipboardText size={24} />,
      },
    ],
    projects: {
      search_btn: "Найти",
      empty_data: "Данные отсутсвуют",
    },
  },

  blanks: {
    title: "Загрузка бланков (титульный лист для отчета)",
    title_for_admin: "Реестр бланков",
    buttons: {
      add: "Добавить",
      order: "Заказать",
      list: "Реестр бланков",
    },
    add_modal: {
      block_1: {
        index: 1,
        download: "Загрузить файл",
        text: "*Бланк должен быть загружен в графическом формате и не должен превышать размер 1 Mb. Его размер должен быть равен формату А4.",
      },
      block_2: {
        index: 2,
        text: "Задайте период публикации нового бланка (титульного листа) в отчете",
      },
      hint: "Вы не можете выбрать эти даты для публикации. Пожалуйста, удалите старый бланк или измените даты публикации.",
      form_title: "Добавление бланка",
      from: "C",
      to: "По",
      send: "Отправить бланк",
    },
    order_modal: {
      form_title: "Заказ бланка",
      block_upload: {
        index: 1,
        download: "Загрузить логотип",
        text: "Загрузите логотип в кривых (pdf, ai, eps)",
      },
      textareas: [
        {
          index: 2,
          name_textarea: "colors",
          placeholder: "C2C7CE - Hex",
          text: "Напишите Ваши фирменные цвета (rgb, hex, css и т.д.)",
        },
        {
          index: 3,
          name_textarea: "fonts",
          placeholder: "Roboto",
          text: "Напишите пожелания к шрифтам",
        },
        {
          index: 4,
          name_textarea: "contacts",
          placeholder: "Адреса, номера телефонов",
          text: "Контактная информация для бланка",
        },
        {
          index: 5,
          name_textarea: "comments",
          placeholder: "Текст",
          text: "Текст/пожелания",
        },
        {
          index: 6,
          name_textarea: "phone",
          placeholder: "Телефон",
          text: "Телефон/почта для связи с менджером",
        },
      ],
      block_date: {
        index: 7,
        text: "Задайте период публикации нового бланка (титульного листа) в отчете",
      },
      send: "Заказать бланк",
    },
    list_modal: {
      form_title: "Реестр бланков",
      header_titles: ["Превью бланка", "Период публикации", "Продано проектов за этот период", "Статус"],
      statuses: {
        published: "Опубликован",
        await: "Ожидает публикации",
        expired: "Истек срок публикации",
      },
      error: "Ошибка загрузки данных. Попробуйте перезагрузить страницу.",
      empty: "Нет ни одного загруженого бланка.",
    },
    warning: {
      title: "Если вы закроете окно, все введенные данные будет стёрты.",
      subtitle: "Закрыть форму?",
      close: "Закрыть",
      cancel: "Отмена",
    },
    delete_warning: {
      title: "Вы уверены, что хотите удалить бланк?.",
      delete: "Удалить",
      cancel: "Отмена",
    },
    requiredRole: [...onlyAdmin, ...onlyLPR],
    requiredRoleForLPR: onlyLPR,
  },

  reminders: {
    comments: "Комментарий не указан",
    time_title: "Время",
    comment_title: "Добавить напоминание",
    save_btn: "Сохранить",
    list_title: "Текущие напоминания",
    list_empty: "Напоминания отсутствуют",
    list_error: "Не удалось загрузить напоминания...",
    comment_placeholder: "Комментарий",
  },

  header: {
    title: "CRM релиз от 04/07/2024",
    menu: [
      {
        id: "main_menu",
        title: "Главная",
        path: "/",
        requiredRole: allRoles,
      },
      { id: "deals", title: "Сделки", path: "/deals", requiredRole: [...onlyLPR, ...onlyEmployee] },
      { id: "leads", title: "Лиды", path: "/leads", requiredRole: allRoles },
      {
        id: "stuff_and_shops",
        title: "Сотрудники и магазины",
        path: "/organization?tab=shops",
        requiredRole: [...onlyAdmin, ...onlyLPR],
      },
    ],
  },

  reset_password: {
    title: "Для восстановления пароля введите свой номер телефона",
    placeholder: {
      phone: "+7 (999) 999-99-99",
      sms_code: "Код из СМС",
    },
    mask: "+7 (999) 999-99-99",
    get_code: "Запросить код",
    hint: "Вы можете запросить пароль по смс не чаще 1 раза в сутки",
    button_back_title: "Назад ко входу",
    button_back_path: "/signin",
  },

  login: {
    title: "Пожалуйста, авторизуйтесь в программе",
    resent_users: "Последние пользователи",
    tabs: [
      { title: "По email", value: "email" },
      { title: "По логину", value: "login" },
      { title: "По номеру телефона", value: "phone" },
    ],
    form: {
      phone: {
        mask: "+7 (999) 999-99-99",
        placeholder: "+7 (999) 999-99-99",
      },
      email: {
        placeholder: "E-mail",
      },
      login: {
        placeholder: "Логин",
      },
      password: {
        placeholder: "Пароль",
        password_hint: "Минимум 4 символа",
        lpr_password_hint: "Минимальная длина 6 символов, минимум 1 цифра, 1 латинская буква и 1 спец. символ ",
        password_compare_hint: "Пожалуйста, убедитесь, что пароли совпадают",
      },
    },
    footer: {
      button_title: "ВХОД",
      reset_password: {
        title: "Забыли пароль?",
        path: "/reset-password",
      },
    },
  },

  loader: {
    title: "Loading",
  },

  pagination: {
    break_label: "...",
  },

  alert_messages: {
    login: {
      nokey: "Не удалось найти ключ лицензии!",
      error: "Неверный логин или пароль",
    },
    status: {
      error_status_edit: "Не удалось отключить статус(ы). Возможно в них есть активные сделки",
      success_status_edit: "Статусы успешно изменены",
    },
    profile: {
      success_profile: "Данные пользователя успешно изменены",
      error_profile: "Что-то пошло не так...",
    },
    deals: {
      create: {
        success_deal: "Сделка успешно создана",
        error_deal: "Ошибка при создании сделки",
      },
      edit: { success_deal: "Сделка успешно отредактирована", error_deal: "Ошибка при редактировании сделки" },
      comment: { error_comment_edit: "Не удалось редактировать комментарий", error_comment_delete: "Не удалось удалить комментарий" },
    },
    shops: {
      create: {
        success_shop: "Магазин успешно создан",
        error_shop: "Ошибка при создании магазина",
      },
      edit: {
        success_shop: "Магазин успешно изменен",
        error_shop: "Ошибка при изменении магазина",
      },
      delete: {
        success_shop: "Магазин успешно удалён",
        error_shop: "Ошибка при удаление магазина",
      },
    },
    leads: {
      success_lead: "Лид успешно создан",
      error_lead: "Ошибка при создании лида",
    },
    stuffs: {
      create: {
        success_stuff: "Сотрудник успешно создан",
        error_stuff: "Ошибка при создании сотрудника",
      },
      edit: {
        success_stuff: "Данные по сотруднику успешно изменены",
        error_stuff: "Ошибка при изменении данных сотрудника",
        password_error_stuff: "Ошибка при изменении пароля для сотрудника",
      },
      deactivate: {
        deactivate_success: "Пользователь успешно заблокирован",
        deactivate_error: "Ошибка при деактивации пользователя",
      },
      activate: {
        activate_success: "Пользователь успешно активирован",
        activate_error: "Ошибка при активации пользователя",
      },
    },
    projects: {
      hint: {
        hint_title: "Если вы закроете окно, проект не будет создан.",
        hint_subtitle: "Продолжить создание проекта?",
      },
      create: {
        success: "Проект успешно создан",
      },
      close: "Закрыть",
      continue: "Продолжить",
    },
    add_blank: {
      success_blank: "Бланк успешно создан",
      error_blank: "Ошибка при создании бланка",
    },
    list_blanks: {
      success_blank: "Бланк успешно удален",
      error_blank: "Ошибка при удалении бланка",
    },
    reminders: {
      success_blank: "Напоминание успешно создано",
      error_blank: "Ошибка при создании напоминания",
    },
    add_workstation: {
      success: "Рабочее место успешно создано",
      error: "Ошибка при создании рабочего места",
    },
    downloadExcel: {
      success: "Документ успешно загружен",
      error: "Ошибка при загрузке документа",
    },
    projectStatus: {
      success: "Статус успешно изменен",
      error: "Ошибка при изменении статуса",
    },
    client_card: {
      success: "Клиент успешно изменен",
      error: "Ошибка при изменении клиента",
      requiredError: "Пожалуйста убедитесь, что поля ФИО и номер телефона заполнены, а введённый email корректен!",
    },
    NoDataStart: "К сожалению по Вашему запросу",
    NoDataEnd: "ничего не найдено.",
  },

  date_picker: {
    hint: "*Введите корректный диапазон дат",
  },

  panorama: {
    link: "Назад",
    path: "/",
  },

  statuses_settings: {
    title: "Настройка статусов *",
    hint: "*Статус можно убрать из воронки продаж только если там нет проектов",
    requiredRole: [Role.ORGANIZATION_MANAGER, Role.SHOP_MANAGER, Role.DEPARTMENT_MANAGER],
  },

  not_found: "Информации не найдено",
};

export default content;
