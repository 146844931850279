export const yandex = [
  {
    title: "1. В Яндекс почте нажать на иконку «Шестеренка»",
    img: require("./EmailFaqContent/imgs/yandex/1.png"),
  },
  {
    title: "2. Выбрать «Все настройки»",
    img: require("./EmailFaqContent/imgs/yandex/2.png"),
  },
  {
    title: "3. Открыть раздел «Почтовые программы»",
    img: require("./EmailFaqContent/imgs/yandex/3.png"),
  },
  {
    title: "4. Активировать «С сервера imap.yandex.ru» по протоколу IMAP» и «Пароли приложений и OAuth-токены»",
    img: require("./EmailFaqContent/imgs/yandex/4.png"),
  },
  {
    title: "5. Открыть раздел «Безопасность»",
    img: require("./EmailFaqContent/imgs/yandex/5.png"),
  },
  {
    title: "6. Нажать на выделенный текст «пароли приложений»",
    img: require("./EmailFaqContent/imgs/yandex/6.png"),
  },
  {
    title: "7. На открывшейся странице в разделе «Создать пароль приложения» выбрать «Почта»",
    img: require("./EmailFaqContent/imgs/yandex/7.png"),
  },
  {
    title: "8. Придумать любое название и нажать кнопку «Далее»",
    img: require("./EmailFaqContent/imgs/yandex/8.png"),
  },
  {
    title: "9. Скопировать и сохранить предоставленный код",
    img: require("./EmailFaqContent/imgs/yandex/9.png"),
  },
];

export const mailru = [
  {
    title: "1. Аккаунт, с которого будет осуществляться рассылка должен быть привязан к телефону",
    img: "",
  },
  {
    title: "2. В разделе «Почта» нажать на кнопку «Настройки», расположенную в левом нижнем углу.",
    img: require("./EmailFaqContent/imgs/mailru/2.png"),
  },
  {
    title: "3. Выбрать “Все настройки”",
    img: require("./EmailFaqContent/imgs/mailru/3.png"),
  },
  {
    title: "4. На открывшейся странице нажать на «Все настройки безопасности»",
    img: require("./EmailFaqContent/imgs/mailru/4.png"),
  },
  {
    title: "5. Открыть раздел «Внешние сервисы»",
    img: require("./EmailFaqContent/imgs/mailru/5.png"),
  },
  {
    title: "6. Активировать «Доступ к Почте по IMAP, POP и SMTP»",
    img: require("./EmailFaqContent/imgs/mailru/6.png"),
  },
  {
    title: "7. Нажать кнопку «Вернуться», расположенную в верхней левой стороне",
    img: require("./EmailFaqContent/imgs/mailru/7.png"),
  },
  {
    title: "8. В разделе «Способы входа» выбрать «Пароли для внешних приложений»",
    img: require("./EmailFaqContent/imgs/mailru/8.png"),
  },
  {
    title: "9. На открывшейся странице нажать кнопку «Добавить»",
    img: require("./EmailFaqContent/imgs/mailru/9.png"),
  },
  {
    title: "10. Придумать любое название и нажать кнопку «Продолжить»",
    img: require("./EmailFaqContent/imgs/mailru/10.png"),
  },
  {
    title: "11. Ввести пароль от аккаунта в mail и пройти проверку безопасности",
    img: require("./EmailFaqContent/imgs/mailru/11.png"),
  },
  {
    title: "12. Скопировать и сохранить предоставленный код",
    img: require("./EmailFaqContent/imgs/mailru/12.png"),
  },
];

export const gmailcom = [
  {
    title: "1. В google при нажатии на многоточие выбрать «Аккаунт»",
    img: require("./EmailFaqContent/imgs/gmailcom/1.png"),
  },
  {
    title: "2. Выбрать раздел «Безопасность»",
    img: require("./EmailFaqContent/imgs/gmailcom/2.png"),
  },
  {
    title: "3. Включить двухфакторную аутентификацию, если она еще не активна",
    img: require("./EmailFaqContent/imgs/gmailcom/3.png"),
  },
  {
    title: "4. В строке поиска вписать “Пароли приложений”",
    img: require("./EmailFaqContent/imgs/gmailcom/4.png"),
  },
  {
    title: "5. Придумать любое название и нажать кнопку «Создать»",
    img: require("./EmailFaqContent/imgs/gmailcom/5.png"),
  },
  {
    title: "6. Скопировать и сохранить предоставленный код",
    img: require("./EmailFaqContent/imgs/gmailcom/6.png"),
  },
];
