import { TableProps } from "antd";
import { ShopCardsDataType } from "./ShopsCards";

export const columns: TableProps<ShopCardsDataType>["columns"] = [
  {
    title: "Название",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Город",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "Адрес",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Отделы",
    dataIndex: "departments",
    key: "departments",
  },
  {
    title: "Контакты",
    dataIndex: "contacts",
    key: "contacts",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
  },
];
