import React, { useEffect, useState } from "react";
import styles from "../ShopsCards/ShopsCards.module.scss";
import content from "../../../../settings/content";
import InputCustom from "../../../../components/ui/InputCustom/InputCustom";
import { isTextValid } from "../../../../helpers/validation";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import { useMutation } from "react-query";
import { IAddShop, addShop } from "../../../../services/Shops";
import { message } from "antd";
import { addDepartment } from "../../../../services/Department";
import { ReactComponent as AddIcon } from "../../../../assets/icons/add.svg";
import SelectCustom, { IOptionsProps } from "../../../../components/ui/SelectCustom/SelectCustom";
import { useCities } from "../../../../context/useCities";

interface IProps {
  refetchShopsAndClose: () => void;
}

const initialState = {
  id: "",
  name: "",
  address: "",
  city_id: "",
  gmt_delta: "",
};

const CreateShop: React.FC<IProps> = ({ refetchShopsAndClose }) => {
  const { cities } = useCities();

  const [citiesSelectData, setCitiesSelectData] = useState<IOptionsProps[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [counter, setCounter] = useState(1);
  const [departments, setDepartments] = useState<[string, string][]>();
  const [inputData, setInputData] = useState<{ [key: string]: string }>(initialState);
  const [gmtValue, setGmtValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [inputs, setInputs] = useState(content.organizations.shops.inputs_create);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { mutateAsync: onAddShop } = useMutation({
    mutationFn: addShop,
    onSuccess: () => {
      refetchShopsAndClose();
    },
  });

  const { mutateAsync: onAddDepartment } = useMutation({
    mutationFn: addDepartment,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleCityChange = (value: string) => {
    setInputData({ ...inputData, city_id: value });
  };

  const getDataInput = (index: string) => inputData[index as keyof IAddShop];

  const addShopHandler = async () => {
    const shopData = { name: inputData.name, address: inputData.address, city_id: inputData.city_id };

    onAddShop(shopData).then((res) => {
      if (typeof res?.id !== "undefined") {
        // Если магази по какой-либо причине не был создан
        // Отделы нет смысла создавать, выходим и выводим ошибку
        addDepartmentHandler(res.id).then(() => {
          success();
        });
        setIsButtonLoading(false);
      } else {
        error();
      }
    });
  };

  useEffect(() => {
    if (!cities) {
      return;
    }

    if (Array.isArray(cities)) {
      setCitiesSelectData(cities.map((city) => ({ value: city.id, label: `${city.country}, ${city.name}, ${city.federal_subject}` })));
    }
  }, [cities]);

  useEffect(() => {
    if (inputData.city_id) {
      const findGmt = cities.find((item) => item.id === inputData.city_id)?.gmt_delta;
      if (findGmt) {
        setGmtValue(`GMT + ${findGmt} (${citiesSelectData.find((item) => item.value === inputData.city_id)?.label})`);
      }
    }
  }, [inputData.city_id]);

  // TODO добавить проверку на наличие отделов, когда будет метод для удаления отделов
  useEffect(() => {
    if (!inputData) {
      return;
    }

    const getDepartments = Object.entries(inputData).filter((dep) => dep[0].includes("department"));
    setDepartments(getDepartments);

    if (
      inputData.city_id !== "" &&
      inputData.address !== "" &&
      inputData.address.length > 1 &&
      inputData.name !== "" &&
      inputData.name.length > 1 &&
      getDepartments.length &&
      getDepartments[0][1] !== ""
    ) {
      setDisabled(false);
      return;
    }
    setDisabled(true);
  }, [inputData, inputs]);

  const addDepartmentHandler = async (shop_id: string) => {
    // Пройдемся по каждому значению и запишем данные в базу
    departments?.forEach((i) => {
      if (i[1] !== "") {
        onAddDepartment({
          name: i[1],
          shop_id: shop_id,
        });
      }
    });
  };

  // При добавлении отдела, нужно создать новый инпут объект
  // с уникальным ID для этого используем state - counter
  const addDepartmentInput = (dep: string) => {
    const departments = {
      id: `department_id_${counter}`,
      name: `department_${counter}`,
      placeholder: "Название отдела",
      title: "",
      enabled: true,
      options: false,
    };

    setInputs([...inputs, departments]);
    setCounter((prev) => prev + 1);
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: content.alert_messages.shops.create.success_shop,
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: content.alert_messages.shops.create.error_shop,
    });
  };

  return (
    <div className={styles.shopsCards__inputs_wrapper}>
      {contextHolder}
      {inputs.map((input) => {
        return (
          <div key={input.id} className={styles.shopsCards__input}>
            <div className={styles.shopsCards__lables}>
              <label htmlFor={input.id}>{input.title}</label>
              {input.options && (
                <div className={styles.shopsCards__add} onClick={() => addDepartmentInput(getDataInput(input.name) || "")}>
                  <AddIcon />
                </div>
              )}
            </div>
            {input.name === "department" ? null : input.name === "city_id" ? (
              <SelectCustom
                className={styles.shopsCards__city_select}
                placeholder={input.placeholder}
                options={citiesSelectData}
                name={input.name}
                showSearch
                onChange={(e) => handleCityChange(e)}
                value={getDataInput(input.name)}
              />
            ) : (
              <InputCustom
                className={styles.custom_input}
                placeholder={input.placeholder}
                name={input.name}
                onChange={(e) => handleChange(e)}
                value={input.name !== "gmt_delta" ? getDataInput(input.name) : gmtValue}
                disabled={input.name === "gmt_delta" ? true : input.enabled ? false : true}
                isError={input.name === "name" && !!getDataInput(input.name) && !isTextValid(getDataInput(input.name))}
              />
            )}
          </div>
        );
      })}
      <ButtonCustom
        onClick={() => {
          setIsButtonLoading(true);
          addShopHandler();
        }}
        className={styles.shopsCards__save}
        disabled={disabled || isButtonLoading}
        isLoading={isButtonLoading}
      >
        <span>{content.organizations.shops.save}</span>
      </ButtonCustom>
    </div>
  );
};

export default CreateShop;
