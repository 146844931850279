import { ReactNode, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./ControlPanel.module.scss";

interface IProps {
  title: string;
  name: string;
  notificationCount: number;
  handleOpenCard: (name: string, title: string, url?: string) => void;
  type: string; // "url" || "default"
  path?: string;
  icon: ReactNode;
}

const ControlCard = ({ title, name, notificationCount, handleOpenCard, type, path, icon }: IProps) => {
  const history = useHistory();
  const location = useLocation();

  const handleRedirect = (url: string) => {
    history.push(url);
  };

  const isSelected = useMemo(() => {
    return `${location.pathname}${location.search}` === path;
  }, [location.pathname, location.search, path]);

  const onClickHandler = () => {
    if (path) {
      handleRedirect(path);
      return;
    }

    if (type === "default") {
      handleOpenCard(name, title);
      return;
    }

    handleRedirect(`/${name}`);
  };

  return (
    <button className={`${styles.controlCard__wrapper} ${isSelected ? styles.controlCard__current_route : ""}`} onClick={onClickHandler}>
      {icon}
      <span className={styles.controlCard__title}>{title}</span>
      {notificationCount > 0 && <span className={styles.controlCard__count}>+ {notificationCount}</span>}
    </button>
  );
};

export default ControlCard;
