import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl } from "../env";

export interface IRequestAllStuff {
  search_query?: string | null;
  shop_ids?: string[] | null;
  department_ids?: string[] | null;
  deactivated?: boolean | null;
}

interface IResponseUserEdit {
  id?: string;
  name: string;
}

export interface IRequestStuff {
  id?: string;
  shop_id?: string | null;
  department_id?: string | null;
  email?: string | null;
  phone?: string | null;
  name?: string;
  role?: string;
  login?: string;
  password?: string;
  deactivated_at?: null | string;
}

export interface IRequestStuffEdit {
  userInfo: IRequestStuff;
  id: string;
}

export interface IRequestStuffPasswordEdit {
  password: string;
  id: string;
}

export interface IUserData {
  department_id: string;
  id: string;
  login: string;
  email: string;
  phone: string;
  name: string;
}

export interface IResponseRecentUsers {
  status: string;
  data: {
    sessions: IRecentUsers[];
  };
}

export interface IRecentUsers {
  ended_at: string;
  user_email: string;
  user_name: string;
  user_phone: string;
  user_login: string;
}

export type IPasswordRequest = {
  old_password: string;
  new_password: string;
};

export interface IStuffUser {
  id: string;
  role: string;
  shop_id: string;
  department_id: string;
  login: string;
  email: string;
  phone: string;
  name: string;
  deactivated_at: null | string;
}

export interface IGetAllStuffResponse {
  data: IStuffUser[];
  status: number;
}

export interface IGetStuffResponse {
  data: IStuffUser;
  status: number;
}

export interface IEditStuffResponse {
  data: IRequestStuff;
  status: number;
}

export interface ICreateStuffRequest {
  role: string;
  shop_id?: string | null;
  department_id?: string | null;
  login: string;
  password: string;
  email?: string | null;
  phone?: string | null;
  name: string;
}

export interface ICreateStuffResponse {
  data: IRequestStuff;
  status: number;
}

export interface IUserRole {
  id: string;
  name: string;
}

export interface IGetUserRolesResponse {
  data: IUserRole[];
  status: number;
}

export interface IDeactivateUser {
  status: number;
}

// Метод для получения всех доступных ролей
export async function getUserRoles(): Promise<IGetUserRolesResponse> {
  return axios.get(baseUrl + `user-role`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } }).catch((err) => {
    console.log("Ошибка при получении пользователя в методе ==> getUserRoles:", err.response);
    return err.response;
  });
}

// Метод для получения всех сотрудников
export async function getAllStuff(data?: IRequestAllStuff): Promise<IGetAllStuffResponse> {
  return axios
    .put(
      `${baseUrl}user`,
      {
        search_query: data?.search_query ?? null,
        shop_ids: data?.shop_ids ?? null,
        department_ids: data?.department_ids ?? null,
        deactivated: data?.deactivated ?? null,
      } as IRequestAllStuff,
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .catch((err) => {
      console.log("Ошибка при получении пользователя в методе ==> getAllStuff:", err.response);
      return err.response;
    });
}

// Метод для получения сотрудника по ID
export async function getStuffById(id: string): Promise<IGetStuffResponse> {
  return axios.get(baseUrl + `user/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } }).catch((err) => {
    console.log("Ошибка при получении пользователя в методе ==> getStuffById:", err.response);
    return err.response;
  });
}

// Метод для редактирования сотрудника
export async function editStuff({ id, userInfo }: IRequestStuffEdit): Promise<IEditStuffResponse> {
  return axios
    .put(`${baseUrl}user/${id}`, { ...userInfo }, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((error) => {
      console.log("Ошибка в методе ==> editStuff:", error.response);
      return error.response;
    });
}

// Метод для редактирования пароля сотрудника
export async function editStuffPassword({ id, password }: IRequestStuffPasswordEdit): Promise<any> {
  /// any - потому что пока в ответе ничего нет, планирую попросить добавить
  return axios
    .put(`${baseUrl}user/${id}/password`, { password }, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((error) => {
      console.log("Ошибка в методе ==> editStuffPassword:", error.response);
      return error.response;
    });
}

// Метод для создания сотрудника
export async function createStuff(stuffData: ICreateStuffRequest): Promise<IEditStuffResponse> {
  return axios
    .post(`${baseUrl}user`, { ...stuffData }, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((error) => {
      console.log("Ошибка в методе ==> createStuff:", error.response);
      return error.response;
    });
}

// Метод для получения информации о текущем пользователе
export async function getCurrentUserInfo() {
  return axios
    .get(baseUrl + `user/me`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Ошибка при получении пользователя в методе ==> getCurrentUserInfo:", err.response);
      return err.response;
    });
}

// Метод для получения информация о недавних пользователях
export async function getRecentUsers(key_id?: string): Promise<IResponseRecentUsers> {
  const query = !key_id ? "" : `?license_key=${key_id}`;
  return axios
    .get(baseUrl + `user-session/recent-users${query}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении пользователя в методе ==> getRecentUsers:", err.response);
      return err.response;
    });
}

export async function getSelectedUserInfo(id: string): Promise<IUserData> {
  return axios
    .get(baseUrl + `user/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Ошибка при получении пользователя в методе ==> getSelectedUserInfo:", err.response);
      return err.response;
    });
}

// Метод для изменения ФИО текущего пользователя
export async function updateCurrentUserInfo(userRequest: IResponseUserEdit) {
  const id = userRequest.id;
  delete userRequest.id;

  return axios
    .put(baseUrl + `user/${id}`, userRequest, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при изменении пользователя в методе ==> updateCurrentUserInfo:", err.response);
      return err.response;
    });
}

// Метод для смены пароля
export async function resetPassword(passwordRequest: IPasswordRequest) {
  return axios
    .put(`${baseUrl}user/me/password`, passwordRequest, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((error: any) => {
      console.log("Ошибка в методе ==> resetPassword:", error.response);
      return error.response;
    });
}

// Метод для диактивации пользователя
// TODO сейчас нет апишки. Когда будет нужно будет подставить корректный URL
export async function deactivateUser(id: string): Promise<IDeactivateUser> {
  return axios
    .delete(`${baseUrl}user/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((error: any) => {
      console.log("Ошибка в методе ==> deactivateUser:", error.response);
      return error.response;
    });
}

export async function activateUser(id: string): Promise<IDeactivateUser> {
  return axios
    .post(`${baseUrl}user/${id}/activate`, {}, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((error: any) => {
      console.log("Ошибка в методе ==> activateUser:", error.response);
      return error.response;
    });
}
