import axios from "axios";
import Cookies from "js-cookie";

export const headers = {
  Authorization: "Bearer " + Cookies.get("access_token"),
};
export const isDev = false;

const apiUrl = process.env.REACT_APP_API_URL;

export const baseUrl = apiUrl + "/auth/";
export const baseUrl5050 = apiUrl + "/presales/";
export const baseUrl5052 = apiUrl + "/blanks/";
export const baseUrl5053 = apiUrl + "/organization-phone/";
export const baseUrl5054 = apiUrl + "/lessons/";
export const baseUrl5055 = apiUrl + "/telemetry/";
export const baseUrl5056 = apiUrl + "/organization-email/";

const instance = axios.create({
  baseURL: baseUrl,
  headers: { Authorization: "Bearer " + Cookies.get("access_token") },
  withCredentials: true,
});

export default instance;

export const standName: string = "prod";

export const yandexCloudUrl =
  standName === "prod"
    ? "https://presales.storage.yandexcloud.net/"
    : standName === "demo"
      ? "https://presales-demo.storage.yandexcloud.net/"
      : "https://presales-preprod.storage.yandexcloud.net/";
