import React, { ReactElement, useContext, useState } from "react";
import { useQuery } from "react-query";
import { checkAuthenticated } from "../helpers/checkAuth";
import { IRoomType, getRoomTypes } from "../services/Source";

interface ISourcesProps {
  children: ReactElement;
}

interface ISourcesContext {
  room_types: IRoomType[];
}

const initialContext: ISourcesContext = {
  room_types: [],
};

const SourcesContext = React.createContext<ISourcesContext>(initialContext);

export const useSources = () => {
  return useContext(SourcesContext);
};

export const SourcesProvider: React.FC<ISourcesProps> = ({ children }) => {
  const authenticated = checkAuthenticated();

  const [roomTypes, setRoomTypes] = useState<IRoomType[]>([]);

  const { data: roomTypesData } = useQuery({
    queryFn: () => {
      const currentPathName = window.location.pathname;

      if (!authenticated && !currentPathName.startsWith("/public-gallery")) {
        return;
      }

      return getRoomTypes();
    },
    // enabled: authenticated,
    onSuccess: (data) => {
      if (!data?.data) {
        return;
      }
      if (!Array.isArray(data.data.items)) {
        return;
      }
      setRoomTypes(data.data.items);
    },
    queryKey: ["room_types", authenticated],
  });

  return <SourcesContext.Provider value={{ room_types: roomTypes }}>{children}</SourcesContext.Provider>;
};
