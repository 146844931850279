import moment from "moment";
import { SortOptionsList } from "../DealsPage/sortOptions";
import { ILead } from "../../services/Leads";

export const sortLeadsByCurrentOption = ({
  currentOption,
  leads,
  isSeeReminders,
}: {
  leads: ILead[];
  currentOption: SortOptionsList | undefined;
  isSeeReminders: boolean;
}): ILead[] => {
  if (!currentOption) {
    return leads;
  }
  if (currentOption === SortOptionsList.newFirst) {
    const result = leads.sort(function (a, b) {
      const aReminders = a.reminders_count ? a.reminders_count : 0;
      const bBReminders = b.reminders_count ? b.reminders_count : 0;
      if (!!aReminders && !bBReminders && isSeeReminders) {
        return -1; // a перед b
      } else if (!aReminders && bBReminders && isSeeReminders) {
        return 1; // b перед a
      } else {
        return moment(b.created_at).diff(moment(a.created_at));
      }
    });
    return result;
  }
  if (currentOption === SortOptionsList.oldFirst) {
    const result = leads.sort(function (a, b) {
      const aReminders = a.reminders_count ? a.reminders_count : 0;
      const bBReminders = b.reminders_count ? b.reminders_count : 0;
      if (!!aReminders && !bBReminders && isSeeReminders) {
        return -1; // a перед b
      } else if (!aReminders && bBReminders && isSeeReminders) {
        return 1; // b перед a
      } else {
        return moment(a.created_at).diff(moment(b.created_at));
      }
    });
    return result;
  }
  if (currentOption === SortOptionsList.byClientName) {
    const result = leads.sort((a, b) => {
      const aClientName = a.client_fullname ?? "";
      const bClientName = b.client_fullname ?? "";
      const isANumber = /^\d/.test(aClientName);
      const isBNumber = /^\d/.test(bClientName);
      const aReminders = a.reminders_count ? a.reminders_count : 0;
      const bBReminders = b.reminders_count ? b.reminders_count : 0;
      if (!!aReminders && !bBReminders && isSeeReminders) {
        return -1; // a перед b
      } else if (!aReminders && bBReminders && isSeeReminders) {
        return 1; // b перед a
      } else if (isANumber && !isBNumber) {
        return -1; // a перед b
      } else if (!isANumber && isBNumber) {
        return 1; // b перед a
      } else {
        return aClientName.localeCompare(bClientName); // обычная алфавитная сортировка
      }
    });
    return result;
  }
  if (currentOption === SortOptionsList.byTitle) {
    const result = leads.sort((a, b) => {
      const aClientTitle = a.title ?? "";
      const bClientTitle = b.title ?? "";
      const isANumber = /^\d/.test(aClientTitle);
      const isBNumber = /^\d/.test(bClientTitle);
      const aReminders = a.reminders_count ? a.reminders_count : 0;
      const bBReminders = b.reminders_count ? b.reminders_count : 0;
      if (!!aReminders && !bBReminders && isSeeReminders) {
        return -1; // a перед b
      } else if (!aReminders && bBReminders && isSeeReminders) {
        return 1; // b перед a
      } else if (isANumber && !isBNumber) {
        return -1; // a перед b
      } else if (!isANumber && isBNumber) {
        return 1; // b перед a
      } else {
        return aClientTitle.localeCompare(bClientTitle); // обычная алфавитная сортировка
      }
    });
    return result;
  }
  if (currentOption === SortOptionsList.updatedFirst) {
    return leads;
  }
  return leads;
};
