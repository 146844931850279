import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl5050 } from "../env";

/**
 * Данные метод отвечает за получение списка справочников из БД
 */

export interface ISource {
  id: string;
  value: string;
}

export interface IGetSource {
  status: number;
  data: ISource[];
}

export interface IRoomType {
  value: string;
  name: string;
}

export interface IGetRoomTypes {
  status: number;
  data: { items: IRoomType[] };
}

export interface IGetProjectFilters {
  status: number;
  data: { count: number; items: ISource[] };
}

export async function getSource(source_id: string): Promise<IGetSource> {
  return axios
    .get(baseUrl5050 + `source/${source_id}/value`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getSource:", err.response);
      return err.response;
    });
}

export async function getRoomTypes(): Promise<IGetRoomTypes> {
  return axios
    .get(baseUrl5050 + `reference/room-type`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getSource:", err.response);
      return err.response;
    });
}

export async function getProjectFilters(source_id: string): Promise<IGetSource> {
  try {
    const request = await axios.get(baseUrl5050 + `gallery-project/${source_id}`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    });
    return {
      data: request.data.items,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка в методе ==> getProjectFilters:");
    throw new Error(err.request.status);
  }
}

export async function getPublicSource(source_id: string, organization_id: string): Promise<IGetSource> {
  return axios
    .get(baseUrl5050 + `public/${organization_id}/project/filter/${source_id}`)
    .then((response) => {
      return {
        data: response.data.items,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getPublicSource:", err.response);
      return err.response;
    });
}
