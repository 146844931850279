import Layout from "../../components/Layout/Layout";
import Gallery from "../Gallery/Gallery";
import ControlPanel from "../../components/ControlPanel/ControlPanel";
import styles from "./MainPage.module.scss";

const MainPage = () => {
  return (
    <div className={styles.MainPage__container}>
      <Layout>
        <ControlPanel />
        <Gallery />
      </Layout>
    </div>
  );
};

export default MainPage;
