import { DatePicker } from "antd";
import { useState } from "react";
import MyTrainingTable from "./MyTrainingTable";
import DepartmentTrainingTable from "./DepartmentTrainingTable";
import styles from "./TrainingStatisticWithTrainer.module.scss";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/ru_RU";
import ButtonCustom from "../../ui/ButtonCustom/ButtonCustom";
import content, { onlyEmployee, onlyLPR, Role } from "../../../settings/content";
import { useUser } from "../../../context/useUser";
import ValidateComponentByRole from "../../ValidateComponentByRole/ValidateComponentByRole";

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

type Props = {};

const TrainingStatisticWithCoach = (props: Props) => {
  const { user } = useUser();

  const [isOnlyMySelected, setIsOnlyMySelected] = useState(!onlyLPR.includes(user.role as Role));
  const [dateFrom, setDateFrom] = useState(dayjs().subtract(30, "day").format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(dayjs().format("YYYY-MM-DD"));
  return (
    <div className={styles.trainingStatisticWithTrainer}>
      <div className={styles.trainingStatisticWithTrainer__block}>
        <div className={styles.trainingStatisticWithTrainer__block_date}>
          <DatePicker
            defaultValue={dayjs().subtract(30, "day")}
            className={styles.trainingStatisticWithTrainer__block_picker}
            locale={locale}
            onChange={(dateString) => setDateFrom(dayjs(dateString).format("YYYY-MM-DD"))}
          />
          <DatePicker
            defaultValue={dayjs()}
            className={styles.trainingStatisticWithTrainer__block_picker}
            locale={locale}
            onChange={(dateString) => setDateTo(dayjs(dateString).format("YYYY-MM-DD"))}
          />
          <ButtonCustom className={styles.trainingStatisticWithTrainer__block_btn} maxWidth="60px" onClick={() => null} disabled={false}>
            <span>{content.control.projects.search_btn}</span>
          </ButtonCustom>
        </div>
        <ValidateComponentByRole requiredRoles={onlyEmployee}>
          <ButtonCustom maxWidth="140px" className={styles.clients__btn} onClick={() => setIsOnlyMySelected((prev) => !prev)}>
            <span>{isOnlyMySelected ? "Уроки отдела " : "Только мои уроки"}</span>
          </ButtonCustom>
        </ValidateComponentByRole>
      </div>
      <div className={styles.trainingStatisticWithTrainer__table_wrapper}>
        {isOnlyMySelected ? (
          <MyTrainingTable selectedInterval={{ dateFrom, dateTo }} />
        ) : (
          <DepartmentTrainingTable selectedInterval={{ dateFrom, dateTo }} />
        )}
      </div>
    </div>
  );
};

export default TrainingStatisticWithCoach;
