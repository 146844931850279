import { useState } from "react";
import styles from "./AddPhoneModal.module.scss";
import SelectCustom from "../../../../components/ui/SelectCustom/SelectCustom";
import InputCustom from "../../../../components/ui/InputCustom/InputCustom";
import { useMutation, useQuery } from "react-query";
import { getPhoneConnections, postPhoneConnectionGreenApi } from "../../../../services/Shops";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";

type Props = {
  closeModal: () => void;
  mailingModalId: string;
};

const AddPhoneModal = ({ closeModal, mailingModalId }: Props) => {
  const [instanceId, setInstanceId] = useState("");
  const [token, setToken] = useState("");

  const { mutateAsync: onCreateGreenApiPhoneConnection } = useMutation({
    mutationFn: postPhoneConnectionGreenApi,
  });

  const { refetch } = useQuery({
    queryFn: () => getPhoneConnections(),
    queryKey: [`phone_connections${mailingModalId}`],
  });

  const onSubmit = async () => {
    const request = await onCreateGreenApiPhoneConnection({ auth_token: token, instance_id: instanceId });
    if (request.status === 200) {
      closeModal();
      refetch();
    }
  };

  return (
    <div className={styles.AddPhoneModal}>
      <div className={styles.AddPhoneModal__select_block}>
        <span>Выберите сервис рассылки</span>
        <SelectCustom
          placeholder="Выберите сервис рассылки"
          width="100%"
          value={"GreenAPI"}
          onChange={(value) => {}}
          options={[{ value: "GreenAPI", label: "GreenAPI" }]}
          disabled
        />
      </div>
      <div className={styles.AddPhoneModal__input_block}>
        <span>Введите Instance ID</span>
        <InputCustom onChange={(e) => setInstanceId(e.target.value)} value={instanceId} />
      </div>
      <div className={styles.AddPhoneModal__input_block}>
        <span>Введите Token</span>
        <InputCustom onChange={(e) => setToken(e.target.value)} value={token} />
      </div>
      {(!token.length || !instanceId.length) && <span className={styles.AddPhoneModal__errortext}>Все поля должны быть заполнены</span>}
      <div className={styles.AddPhoneModal__footer_btns}>
        <ButtonCustom className={styles.AddPhoneModal__footer_btn} onClick={closeModal} maxWidth="100px" bgColor="gray">
          <span>Отмена</span>
        </ButtonCustom>
        <ButtonCustom
          disabled={!token.length || !instanceId.length}
          className={styles.AddPhoneModal__footer_btn}
          onClick={onSubmit}
          maxWidth="100px"
          bgColor="blue"
        >
          <span>Подключить</span>
        </ButtonCustom>
      </div>
    </div>
  );
};

export default AddPhoneModal;
